import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from 'app/reducers';
import * as fromAuth from 'app/auth/reducers';
import { LayoutActions } from 'app/core/actions';
import { UsersActions } from 'app/auth/actions';

@Component({
	selector: 'qrt-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
	users$: Observable<Array<any>>;

	constructor(private store$: Store<fromAuth.State>) {
		this.users$ = store$.pipe(select(fromAuth.selectUsersList));
	}

	ngOnInit(): void {
		this.store$.dispatch(UsersActions.loadUsers());
	}

	openCreateNotification(user: any) {
		this.store$.dispatch(LayoutActions.openNotificationCreatorModal());
		this.store$.dispatch(UsersActions.selectUser({ user }));
	}

	iconShapeByDisabledStatus(disabled: boolean) {
		return disabled ? 'check' : 'ban';
	}

	openChangeDisabledStatusModal(user: any) {
		this.store$.dispatch(LayoutActions.openChangeDisabledStatusModal());
		this.store$.dispatch(UsersActions.selectUser({ user }));
	}
}
