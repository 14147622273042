import {
	Component,
	OnInit,
	Input,
	HostListener,
	ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromAuth from 'app/auth/reducers';
import { NotificationActions } from 'app/auth/actions';

@Component({
	selector: 'qrt-notifications-container',
	templateUrl: './notifications-container.component.html',
	styleUrls: ['./notifications-container.component.scss'],
})
export class NotificationsContainerComponent implements OnInit {
	@Input() open: boolean;
	@Input() unreadNotifications: Array<any>;

	constructor(
		private _elementRef: ElementRef,
		private store$: Store<fromAuth.State>
	) {}

	@HostListener('document:click', ['$event']) onClick(event) {
		if (this.open && !event.target.matches('button.qrt_notifications__btn')) {
			const clickedInsideContainer = this._elementRef.nativeElement.contains(
				event.target
			);

			if (!clickedInsideContainer) {
				this.open = false;
			}
		}
	}

	ngOnInit(): void {}

	markAsRead(notificationId: string): void {
		this.store$.dispatch(NotificationActions.markAsRead({ notificationId }));
	}
}
