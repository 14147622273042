<div class="qrt_alertlist">
	<div class="qrt_alertlist__top">
		<h1 class="qrt_alertlist__title">Gerir Alertas</h1>
		<qrt-create-alert></qrt-create-alert>
	</div>
	<qrt-callout
		[icon]="'pin'"
		[heading]="'Novos alertas de dispositivo'"
		[content]="
			'Uma nova forma de monitorizar dispositivos, medições e sensores. '
		"
	></qrt-callout>
	<div class="qrt_alertlist__datagrid">
		<div
			*ngIf="!(boxesLoaded$ | async)"
			class="qrt_alertlist__progress progress top loop qrt_progress"
		>
			<progress max="100" value="0" data-displayval="0%"></progress>
		</div>
		<clr-datagrid>
			<clr-dg-column>Etiqueta</clr-dg-column>
			<clr-dg-column>LUI</clr-dg-column>
			<clr-dg-column>Sensor</clr-dg-column>
			<clr-dg-column>Condição</clr-dg-column>
			<clr-dg-column>Valor</clr-dg-column>
			<clr-dg-column>Período</clr-dg-column>
			<clr-dg-column>Activo</clr-dg-column>
			<clr-dg-column>Modificar</clr-dg-column>

			<clr-dg-placeholder>Nenhum alerta encontrado. <br /> </clr-dg-placeholder>

			<clr-dg-row *clrDgItems="let alert of deviceAlerts$ | async">
				<ng-container *ngIf="getDeviceByDui(alert.DUI) | async as device">
					<clr-dg-cell>{{ alert.label }}</clr-dg-cell>
					<clr-dg-cell>{{ device.currentLUI }}</clr-dg-cell>
					<clr-dg-cell>{{ getSensorPTName(alert.sensor) }}</clr-dg-cell>
					<clr-dg-cell>{{ operators[alert.operator] }}</clr-dg-cell>
					<clr-dg-cell>{{ alert.value }} {{ alert.unit }}</clr-dg-cell>
					<clr-dg-cell>{{ periods[alert.period] }}</clr-dg-cell>
					<clr-dg-cell
						><clr-icon
							[attr.shape]="getStatusIconShape(alert.enabled)"
						></clr-icon
					></clr-dg-cell>
					<clr-dg-cell
						><qrt-edit-alert
							[alertId]="alert._id"
							[alertDUI]="alert.DUI"
						></qrt-edit-alert>
						<qrt-delete-alert [alertId]="alert._id"></qrt-delete-alert>
					</clr-dg-cell>
				</ng-container>
			</clr-dg-row>
		</clr-datagrid>
	</div>
</div>
