import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'environments/environment';

import { PreferencesComponent } from 'app/auth/components/preferences/preferences.component';
import { ManageUsersComponent } from 'app/auth/components/manage-users/manage-users.component';
import { ResetPasswordComponent } from 'app/auth/components/reset-password/reset-password.component';
import { ResetPasswordGuard } from 'app/auth/reset-password.guard';

import { AuthGuard } from 'app/auth/services/auth-guard.service';
import { RoleGuard } from 'app/auth/services/role-guard.service';

const authRoutes: Routes = [
	{ path: 'login', component: environment.loginComponent },
	{
		path: 'preferences',
		component: PreferencesComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'users',
		component: ManageUsersComponent,
		canActivate: [AuthGuard, RoleGuard],
	},
	{
		path: 'reset-password',
		canActivate: [ResetPasswordGuard],
		component: ResetPasswordComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(authRoutes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
