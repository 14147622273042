import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { SensorsInfo } from 'app/devices/models/sensors-info';

@Injectable({ providedIn: 'root' })
export class SensorsInfoService {
	constructor(private http: HttpClient) {}

	getAll$(): Observable<SensorsInfo> {
		return this.http.get<SensorsInfo>(`${environment.api}/sensors-info`);
	}
}