import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { ClrLoadingState } from '@clr/angular';

import * as LayoutActions from 'app/core/actions/layout.actions';
import * as LayoutSelectors from 'app/reducers';
import * as DeviceSelectors from 'app/devices/selectors/box.selectors';
import * as LocationActions from 'app/devices/actions/location.actions';
import * as LocationSelectors from 'app/devices/selectors/location.selectors';
import { NewIssueForm } from 'app/devices/reducers/box.reducer';

@Component({
	selector: 'qrt-new-issue',
	templateUrl: './new-issue.component.html',
	styleUrls: ['./new-issue.component.scss'],
})
export class NewIssueComponent implements OnInit {
	showModal$: Observable<boolean>;
	form$: Observable<FormGroupState<NewIssueForm>>;
	locations$: Observable<any[]>;
	submitBtnState$: Observable<ClrLoadingState>;

	constructor(private store$: Store<any>) {
		this.showModal$ = this.store$.pipe(
			select(LayoutSelectors.selectShowNewIssueModal)
		);

		this.form$ = this.store$.pipe(select(DeviceSelectors.selectNewIssueForm));

		this.locations$ = this.store$.pipe(
			select(LocationSelectors.selectAllLocations),
			/*
			 * Dispatch action to load locations if empty
			 * (normally if the user goes direct to the issues page)
			 */
			tap(locations =>
				locations.length
					? null
					: this.store$.dispatch(LocationActions.loadLocations())
			)
		);

		this.submitBtnState$ = this.store$.pipe(
			select(LayoutSelectors.selectSubmitCreateNewIssueButtonState)
		);
	}

	ngOnInit(): void {}

	openModal() {
		this.store$.dispatch(LayoutActions.openNewIssueModal());
	}

	closeModal() {
		this.store$.dispatch(LayoutActions.closeNewIssueModal());
	}

	public createIssue(formData: NewIssueForm) {
		this.store$.dispatch(
			LocationActions.createNewIssue({
				data: { ...formData, priority: Number(formData.priority) },
			})
		);
	}
}
