import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';
import {
	FormGroupState,
	createFormGroupState,
	formGroupReducer,
	onNgrxForms,
} from 'ngrx-forms';
import { Box, AvgPeriod, SelectedBox } from 'app/devices/models/box.model';
import { SensorsInfo } from 'app/devices/models/sensors-info';
import { BoxActions, BoxPreferencesActions } from 'app/devices/actions';

export const boxesFeatureKey = 'boxes';
export const newIssueFormKey = 'new-issue-form';

export interface NewIssueForm {
	location: string;
	incident: string;
	priority: number;
	description: string;
}

const initialNewIssueFormState = createFormGroupState<NewIssueForm>(
	newIssueFormKey,
	{
		location: '',
		incident: '',
		priority: 0,
		description: '',
	}
);

export interface State extends EntityState<Box> {
	loading: boolean;
	loaded: boolean;
	selectedBox: SelectedBox | null;
	sensorsInfo: SensorsInfo | null;
	selectedBoxId: string | null;
	selectedBoxLui: number | null;
	selectedChartType: string;
	selectedSensor: string;
	selectedMeteoSensor: string;
	selectedDatesToReport: any;
	selectedSensorsToReport: any;
	selectedAvgPeriod: AvgPeriod;
	diagnosisDUI: string | null;
	newIssueForm: FormGroupState<NewIssueForm>;
}

export const adapter: EntityAdapter<Box> = createEntityAdapter<Box>({
	selectId: (box: Box) => box.DUI,
	sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
	loading: true,
	loaded: false,
	selectedBox: {
		Diagnosis: {
			loading: false,
			loaded: false,
			requesting: false,
			DUI: '',
			Data: {
				TimeStamp: '',
				Raw: {
					TimeStamp: '',
					Values: '',
				},
				Status: 'INIT',
			},
		},
		Preferences: {
			chartType: 'bar',
			selectedAvgPeriod: {
				name: 'Horária',
				avgType: '1h',
				chartType: 'bar',
				allowedWindowPeriods: [
					{ value: 1, unit: 'day', order: 0 },
					{ value: 1, unit: 'week', order: 1 },
					{ value: 1, unit: 'month', order: 2 },
				],
			},
			selectedDate: '',
		},
	},
	sensorsInfo: null,
	selectedBoxId: null,
	selectedBoxLui: null,
	selectedChartType: 'bar',
	selectedSensor: 'CO',
	selectedMeteoSensor: 'METEOWINDSPEED',
	selectedDatesToReport: { month: null, year: null },
	selectedSensorsToReport: { loaded: false, Values: [] },
	selectedAvgPeriod: {
		name: 'Horária',
		avgType: '1h',
		chartType: 'bar',
		allowedWindowPeriods: [
			{ value: 1, unit: 'day', order: 0 },
			{ value: 1, unit: 'week', order: 1 },
			{ value: 1, unit: 'month', order: 2 },
		],
	},
	diagnosisDUI: null,
	newIssueForm: initialNewIssueFormState,
});

const boxReducer = createReducer(
	initialState,
	onNgrxForms(),
	on(BoxActions.selectDuiToDiagnosis, (state, { dui }) =>
		produce(state, (draft) => {
			draft.diagnosisDUI = dui;
		})
	),
	on(BoxActions.requestDiagnosis, (state, { dui }) =>
		produce(state, (draft) => {
			const diagnosisState = draft.selectedBox.Diagnosis;
			diagnosisState.DUI = dui;
			diagnosisState.loading = true;
			diagnosisState.loaded = false;
			diagnosisState.requesting = true;
		})
	),
	on(BoxActions.requestDiagnosisSuccess, (state, { diagnosis }) =>
		produce(state, (draft) => {
			const diagnosisState = draft.selectedBox.Diagnosis;
			diagnosisState.Data = diagnosis;
			diagnosisState.loading = false;
			diagnosisState.loaded = true;
		})
	),
	on(BoxActions.stopRequestingDiagnosis, (state) =>
		produce(state, (draft) => {
			draft.selectedBox.Diagnosis.requesting = false;
		})
	),
	on(BoxPreferencesActions.selectAvgPeriod, (state, { avgPeriod }) =>
		produce(state, (draft) => {
			draft.selectedBox.Preferences.selectedAvgPeriod = avgPeriod;
		})
	),
	on(BoxPreferencesActions.selectDate, (state, { date }) =>
		produce(state, (draft) => {
			draft.selectedBox.Preferences.selectedDate = date;
		})
	),
	on(BoxActions.selectBox, (state, { id }) =>
		produce(state, (draft) => {
			draft.selectedBoxId = id;
		})
	),
	on(BoxActions.selectBoxLui, (state, { lui }) =>
		produce(state, (draft) => {
			draft.selectedBoxLui = lui;
		})
	),
	on(BoxActions.selectSensor, (state, { sensorfield }) =>
		produce(state, (draft) => {
			draft.selectedSensor = sensorfield;
		})
	),
	on(BoxActions.selectMeteoSensor, (state, { sensorfield }) =>
		produce(state, (draft) => {
			draft.selectedMeteoSensor = sensorfield;
		})
	),
	on(BoxActions.selectSensorsToReport, (state, { sensors }) =>
		produce(state, (draft) => {
			draft.selectedSensorsToReport.Values = sensors;
		})
	),
	on(BoxActions.selectYearToReport, (state, { year }) =>
		produce(state, (draft) => {
			draft.selectedDatesToReport.year = year;
		})
	),
	on(BoxActions.selectMonthToReport, (state, { month }) =>
		produce(state, (draft) => {
			draft.selectedDatesToReport.month = month;
		})
	),
	on(BoxActions.selectSensorsToReportLoaded, (state, { loaded }) =>
		produce(state, (draft) => {
			draft.selectedSensorsToReport.loaded = loaded;
		})
	),
	on(BoxActions.selectAvgPeriod, (state, { avgPeriod }) =>
		produce(state, (draft) => {
			draft.selectedAvgPeriod = avgPeriod;
		})
	),
	on(BoxActions.selectChartType, (state, { chartType }) =>
		produce(state, (draft) => {
			draft.selectedChartType = chartType;
		})
	),
	on(BoxActions.loadSensorsInfoSuccess, (state, { sensorsInfo }) =>
		produce(state, (draft) => {
			draft.sensorsInfo = sensorsInfo;
		})
	),
	on(BoxActions.addBox, (state, action) => adapter.addOne(action.box, state)),
	on(BoxActions.upsertBox, (state, action) =>
		adapter.upsertOne(action.box, state)
	),
	on(BoxActions.addBoxesSuccess, (state, action) =>
		adapter.addMany(action.boxes, state)
	),
	on(BoxActions.upsertBoxes, (state, action) =>
		adapter.upsertMany(action.boxes, state)
	),
	on(BoxActions.updateBox, (state, action) =>
		adapter.updateOne(action.box, state)
	),
	on(BoxActions.updateBoxes, (state, action) =>
		adapter.updateMany(action.boxes, state)
	),
	on(BoxActions.deleteBox, (state, action) =>
		adapter.removeOne(action.id, state)
	),
	on(BoxActions.deleteBoxes, (state, action) =>
		adapter.removeMany(action.ids, state)
	),
	on(BoxActions.loadBoxesSuccess, (state, action) =>
		adapter.setAll(
			action.boxes,
			produce(state, (draft) => {
				draft.loading = false;
				draft.loaded = true;
			})
		)
	),
	on(BoxActions.clearBoxes, (state) => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
	return boxReducer(state, action);
}

export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();

export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const selectId = (state: State) => state.selectedBoxId;
export const selectLui = (state: State) => state.selectedBoxLui;
export const getSelectedSensor = (state: State) => state.selectedSensor;
export const getSelectMeteoSensor = (state: State) => state.selectedMeteoSensor;
export const selectAvgPeriod = (state: State) =>
	state.selectedBox.Preferences.selectedAvgPeriod;
export const selectSensorsInfo = (state: State) => state.sensorsInfo;
export const getDatesToReport = (state: State) => state.selectedDatesToReport;
export const getSensorsToReport = (state: State) =>
	state.selectedSensorsToReport.Values;
export const getSensorsToReportLoaded = (state: State) =>
	state.selectedSensorsToReport.loaded;
export const getDiagnosis = (state: State) => state.selectedBox.Diagnosis;
export const getNewIssueForm = (state: State) => state.newIssueForm;
