import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSensor from 'app/devices/reducers/sensor.reducer';
import * as SensorActions from 'app/devices/actions/sensor.actions';
import { Sensor } from 'app/devices/models/sensor.model';

export const selectSensorsState = createFeatureSelector(
	fromSensor.sensorsFeatureKey
);

export const selectSensors = createSelector(
	selectSensorsState,
	fromSensor.selectAll
);

export const selectSensorsEntities = createSelector(
	selectSensorsState,
	fromSensor.selectEntities
);

export const selectSensorsLoading = createSelector(
	selectSensorsState,
	fromSensor.selectSensorsLoading
);

export const selectSensorsLoaded = createSelector(
	selectSensorsState,
	fromSensor.selectSensorsLoaded
);

export const selectSensorUnitByConfiguration = createSelector(
	selectSensorsEntities,
	(entities, props) => {
		const sensor = entities[props.name];

		const configurations = sensor.configurations;

		if (!configurations) {
			return sensor.unit;
		}

		const configuration = configurations[props.configuration];

		if (!configuration) {
			return sensor.unit;
		}

		if (!configuration.conversionFactor) {
			return configuration.unit;
		}

		return configuration.conversionFactor.unit;
	}
);

export const selectSensorConversionFactors = createSelector(
	selectSensors,
	(sensors, props) => {
		const conversionFactorsBySensors = {};

		sensors
			.filter(
				sensor =>
					sensor?.configurations &&
					sensor.configurations[props.configuration]?.conversionFactor
			)
			.forEach(sensor => {
				conversionFactorsBySensors[sensor.name] =
					sensor.configurations[props.configuration].conversionFactor;
			});

		return conversionFactorsBySensors;
	}
);
