<div
	*ngIf="!(measurementsLoaded$ | async)"
	class="progress top loop qrt_progress"
>
	<progress max="100" value="0" data-displayval="0%"></progress>
</div>
<ng-container *ngIf="loaded$ | async">
	<div *ngIf="measurementsLoaded$ | async" class="qrt_boxlist">
		<div class="qrt_tools-bar">
			<qrt-device-diagnosis *ngIf="isAdmin$ | async"></qrt-device-diagnosis>
			<qrt-export-measurements></qrt-export-measurements>
		</div>
		<form class="qrt_boxlist__search" [formGroup]="searchForm">
			<div class="qrt_boxlist__input-container">
				<label class="screen-reader-only">Pesquisar</label>
				<input
					class="clr-input qrt_boxlist__input"
					placeholder="Pesquisar"
					formControlName="search"
				/>
			</div>

			<button class="btn btn-sm" (click)="clearSearch()">Todas</button>
		</form>
		<div class="qrt_boxlist__list" data-testid="devices-list">
			<div
				class="qrt_boxlist__box card"
				*ngFor="let location of filteredLocations$ | async"
			>
				<div class="card-header qrt_boxcard__header">
					<h2>{{ location.Description }}</h2>
					<div>
						<img
							class="qrt_boxcard__devicetype"
							src="assets/img/boxtype/{{ location.deviceType }}.png"
							alt="{{ location.deviceType }}"
							title="{{ location.deviceType }}"
						/>
					</div>
				</div>
				<div class="card-block qrt_location__block">
					<ul class="qrt_location__info-list">
						<li class="qrt_location__item">
							<span
								class="qrt_location__item-title"
								title="Identificador único de dispositivo"
								aria-label="Identificador único de dispositivo"
								>DUI:
							</span>
							<span class="qrt_location__item-info">{{
								getDUI$(location.LUI) | async
							}}</span>
						</li>
						<li class="qrt_location__item">
							<span
								class="qrt_location__item-title"
								title="Identificador único de localização"
								aria-label="Identificador único de localização"
								>LUI:
							</span>
							<span class="qrt_location__item-info">{{ location.LUI }}</span>
						</li>
						<li class="qrt_location__item">
							<div title="Morada" class="qrt_location__item-title">
								<clr-icon shape="map-marker" class="is-solid"></clr-icon>
							</div>
							<div class="qrt_location__item-info">
								<span>{{ location.Address }}</span>
								<span>{{ location.PostalCode }} {{ location.Locality }}</span>
								<span>{{ location.Country }}</span>
							</div>
						</li>
						<li class="qrt_location__item">
							<div
								title="Latitude e longitude"
								class="qrt_location__item-title"
							>
								<clr-icon shape="compass" class="is-solid"></clr-icon>
							</div>
							<span class="qrt_location__item-info"
								>{{ location.LatLong.lat }}, {{ location.LatLong.lng }}</span
							>
						</li>
					</ul>
				</div>
				<div class="card-footer">
					<a
						[routerLink]="['details', location.LUI]"
						class="btn btn-sm btn-link"
						>Detalhes</a
					>
					<button
						class="btn btn-sm btn-link"
						(click)="selectBoxLui(location.LUI)"
					>
						Relatório
					</button>
					<ng-container *ngIf="isAdmin$ | async">
						<button
							class="btn btn-sm btn-link qrt_boxcard__diagnosis-btn"
							*ngIf="getDUI$(location.LUI) | async as DUI"
							(click)="selectDuiToDiagnosis(DUI)"
						>
							Diagnóstico
						</button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<clr-modal
	*ngIf="isModalVisible"
	[(clrModalOpen)]="isModalVisible"
	class="qrt_report-modal"
>
	<h3 class="modal-title">Configurar relatório</h3>
	<div *ngIf="selectedBox$ | async as selectedBox" class="modal-body">
		<h5 class="qrt_report-modal__box-title">
			Dispositivo:
			<strong>{{ selectedBox.Description }} - {{ selectedBox.DUI }}</strong>
		</h5>
		<form [formGroup]="reportForm" class="qrt_report-modal__reportform">
			<div class="qrt_report-modal__dates">
				<div>
					<label for="select-year" class="clr-control-label">Ano</label>
					<div class="clr-select-wrapper">
						<select formControlName="year" id="select-year" class="clr-select">
							<option
								*ngFor="let allowedYear of allowedYearsReport$ | async"
								[value]="allowedYear"
							>
								{{ allowedYear }}
							</option>
						</select>
					</div>
				</div>
				<div>
					<label for="select-month" class="clr-control-label">Mẽs</label>
					<div class="clr-select-wrapper">
						<select
							formControlName="month"
							id="select-month"
							class="clr-select"
						>
							<option
								*ngFor="let allowedMonth of allowedMonthsReport$ | async"
								[value]="allowedMonth"
							>
								{{ allowedMonth }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div>
				<label for="select-average" class="clr-control-label">Média</label>
				<div class="clr-select-wrapper">
					<select
						formControlName="average"
						id="select-average"
						class="clr-select"
					>
						<option value="8h">8 horas</option>
						<option value="1d">Diário</option>
						<option value="1w">Semanal</option>
					</select>
				</div>
			</div>
			<div
				*ngIf="sensors$ | async as sensors"
				class="qrt_report-modal__sensors"
			>
				<h6>Escolher sensores</h6>
				<clr-checkbox-wrapper
					formArrayName="sensors"
					*ngFor="
						let sensor of reportForm.controls['sensors']['controls'];
						let i = index
					"
				>
					<input [formControlName]="i" type="checkbox" clrCheckbox />
					<label>{{ sensors[i]?.fullName }}</label>
				</clr-checkbox-wrapper>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-outline"
			(click)="isModalVisible = false"
		>
			Cancelar
		</button>
		<a
			[routerLink]="[
				'/report',
				selectedBoxLui$ | async,
				reportForm.value['month'],
				reportForm.value['year']
			]"
			class="btn btn-primary"
			>Ok</a
		>
	</div>
</clr-modal>
