<div class="qrt_preferences">
  <div class="qrt_preferences__card card">
    <nav class="qrt_preferences__nav">
      <a class="qrt_preferences__nav-link"
        (click)="toggleView('personalView')"
        [ngClass]="{ qrt_active: personalView }"
        routerLinkActive="active"
      >
        <clr-icon shape="user"></clr-icon>
        <span>{{ 'Dados Pessoais' | t }}</span>
      </a>
      <a
        class="qrt_preferences__nav-link"
        (click)="toggleView('passwordView')"
        [ngClass]="{ qrt_active: passwordView }"
        routerLinkActive="active"
      >
        <clr-icon shape="key"></clr-icon>
        <span>{{ 'Palavra passe' | t }}</span>
      </a>
      <a
        class="qrt_preferences__nav-link"
        (click)="toggleView('alertsView')"
        [ngClass]="{ qrt_active: alertsView }"
        routerLinkActive="active"
      >
        <clr-icon shape="bell"></clr-icon>
        <span>{{ 'Notificações' | t }}</span>
      </a>
    </nav>
    <div class="qrt_preferences__item">
      <!--Preferences Content View-->
      <ng-container *ngIf="personalView">
        <qrt-personal-data></qrt-personal-data>
      </ng-container>
      <ng-container *ngIf="passwordView">
        <qrt-change-password></qrt-change-password>
      </ng-container>
      <ng-container *ngIf="alertsView">
        <qrt-notification-preferences></qrt-notification-preferences>
      </ng-container>
    </div>
  </div>
</div>
