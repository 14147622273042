import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoxListComponent } from './components/boxlist/boxlist.component';
import { BoxDetailsComponent } from './components/boxdetails/boxdetails.component';
import { ReportComponent } from 'app/devices/components/report/report.component';
import { AlertListComponent } from 'app/devices/components/alertlist/alertlist.component';
import { OverviewComponent } from 'app/devices/components/overview/overview.component';
import { BoxDetailsResolver } from './components/boxdetails/boxdetails.resolver';
import { AuthGuard } from 'app/auth/services/auth-guard.service';

const mapRoutes: Routes = [
	{
		path: 'devices',
		component: BoxListComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'devices/details/:lui',
		component: BoxDetailsComponent,
		resolve: { data: BoxDetailsResolver },
		canActivate: [AuthGuard],
	},
	{
		path: 'report/:lui/:month/:year',
		component: ReportComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'alerts',
		component: AlertListComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'overview',
		component: OverviewComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(mapRoutes)],
	exports: [RouterModule],
})
export class DevicesRoutingModule {}
