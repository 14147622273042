import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClrLoadingState } from '@clr/angular';

import { LocationActions } from 'app/devices/actions';
import * as LayoutActions from 'app/core/actions/layout.actions';
import { LocationService } from 'app/devices/services/location.service';
import { AlertService } from 'app/core/services/alert.service';

@Injectable()
export class LocationEffects {
	loadLocations$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LocationActions.loadLocations),
			switchMap(() =>
				this.locationService.getAll$().pipe(
					map(locations => LocationActions.loadLocationsSuccess({ locations })),
					catchError(error =>
						of(LocationActions.loadLocationsFailure({ error }))
					)
				)
			)
		);
	});

	loadIssues$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LocationActions.loadIssues),
			switchMap(() =>
				this.locationService.getIssues$().pipe(
					map(issues => LocationActions.loadIssuesSuccess({ issues })),
					catchError(error => of(LocationActions.loadIssuesFailure({ error })))
				)
			)
		)
	);

	createNewIssue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LocationActions.createNewIssue),
			switchMap(action =>
				this.locationService.createIssue$(action.data).pipe(
					map(() => LocationActions.createNewIssueSuccess()),
					catchError(error =>
						of(LocationActions.createNewIssueFailure({ error }))
					)
				)
			)
		)
	);

	updateBtnStateOnCreateNewIssue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LocationActions.createNewIssue),
			map(() =>
				LayoutActions.updateChangeDisabledStatusSubmitButtonState({
					loadingState: ClrLoadingState.LOADING,
				})
			)
		)
	);

	loadIssuesOnCreateNewIssueSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LocationActions.createNewIssueSuccess),
			map(() => LocationActions.loadIssues())
		)
	);

	updateBtnStateOnCreateNewIssueSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LocationActions.createNewIssueSuccess),
			map(() =>
				LayoutActions.updateSubmitCreateNewIssueButtonState({
					loadingState: ClrLoadingState.SUCCESS,
				})
			)
		)
	);

	closeModalOnCreateNewIssue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				LocationActions.createNewIssueSuccess,
				LocationActions.createNewIssueFailure
			),
			map(() => LayoutActions.closeNewIssueModal())
		)
	);

	fireSuccessAlertOnNewIssueSucess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LocationActions.createNewIssueSuccess),
				tap(() => this.alertService.success('Anomalia registada com successo.'))
			),
		{ dispatch: false }
	);

	fireErrorAlertOnNewIssueFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(LocationActions.createNewIssueFailure),
				tap(() =>
					this.alertService.error(
						'Desculpe. Houve um problema ao registar anomalia. Tente novamente mais tarde.'
					)
				)
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private locationService: LocationService,
		private alertService: AlertService
	) {}
}
