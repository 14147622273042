import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
	catchError,
	map,
	exhaustMap,
	tap,
	mergeMap,
	switchMap,
	withLatestFrom,
	take,
} from 'rxjs/operators';
import { produce } from 'immer';
import { AuthService } from 'app/auth/services/auth.service';
import { PreferencesService } from 'app/auth/services/preferences.service';
import { AuthStorageService } from 'app/auth/services/auth-storage.service';
import { StatusActions, PreferencesActions } from 'app/auth/actions';
import { Credentials, UserData, User } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/reducers';

import { AlertService } from 'app/core/services/alert.service';
import { NotificationsService } from 'app/core/services/notifications.service';

import { Notification } from 'app/core/models/notification';

@Injectable()
export class AuthEffects {
	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.login),
			map(action => action.credentials),
			exhaustMap((auth: Credentials) =>
				this.authService.login(auth).pipe(
					map(userData => StatusActions.loginSuccess({ userData })),
					catchError(error => of(StatusActions.loginFailure({ error })))
				)
			)
		)
	);

	loginSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.loginSuccess),
				tap(() => this.router.navigate(['/map']))
			),
		{ dispatch: false }
	);

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.logout, StatusActions.deleteUserData),
			mergeMap(() =>
				this.authStorageService.deleteUserData().pipe(
					map(() => StatusActions.deleteUserDataSuccess()),
					catchError(error =>
						of(StatusActions.deleteUserDataFailure({ error }))
					)
				)
			)
		)
	);

	loginRedirect$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.loginRedirect, StatusActions.logout),
				tap(authenticated => {
					this.router.navigate(['/login']);
				})
			),
		{ dispatch: false }
	);

	addUserDataToStorage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.loginSuccess),
			mergeMap(({ userData }) =>
				this.authStorageService.addUserDataToStorage(userData).pipe(
					map(() => StatusActions.addUserDataToStorageSuccess()),
					catchError(error =>
						of(StatusActions.addUserDataToStorageFailure({ error }))
					)
				)
			)
		)
	);

	updateUserDataInStorage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				StatusActions.updateUserSuccess,
				PreferencesActions.updateNotificationPreferencesSuccess
			),
			withLatestFrom(this.store$.select(fromAuth.selectUserData)),
			mergeMap(([action, data]) => {
				const userData = produce(data, draft => {
					if (
						PreferencesActions.updateNotificationPreferencesSuccess.type ===
						action.type
					) {
						draft.user.preferences.notification =
							action.notificationPreferences;
					}
				});

				return this.authStorageService.addUserDataToStorage(userData).pipe(
					map(() => StatusActions.addUserDataToStorageSuccess()),
					catchError(error =>
						of(StatusActions.addUserDataToStorageFailure({ error }))
					)
				);
			})
		)
	);

	loadUserData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.loadUserData),
			switchMap(() =>
				this.authStorageService.getUserData().pipe(
					map((userData: UserData) =>
						StatusActions.loadUserDataSuccess({ userData })
					),
					catchError(error => of(StatusActions.loadUserDataFailure({ error })))
				)
			)
		)
	);

	updateUser$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(StatusActions.updateUser),
			map(action => action.data),
			withLatestFrom(this.store$.select(fromAuth.selectUserData)),
			exhaustMap(([data, userData]) =>
				this.ṕreferencesService.updateUser(data).pipe(
					map((user: User) => StatusActions.updateUserSuccess({ user })),
					catchError(error => of(StatusActions.updateUserFailure({ error })))
				)
			),
			take(1)
		);
	});

	updateUserSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.updateUserSuccess),
				tap(() => this.alertService.success('Dados alterados com sucesso.'))
			),
		{ dispatch: false }
	);

	updateUserFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.updateUserFailure),
				tap(() => this.alertService.error('Não foi possível alterar dados.'))
			),
		{ dispatch: false }
	);

	changePassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.changePassword),
			exhaustMap(action =>
				this.ṕreferencesService
					.changePassword(action.oldPassword, action.newPassword)
					.pipe(
						map(() => StatusActions.changePasswordSuccess()),
						catchError(error =>
							of(StatusActions.changePasswordFailure({ error }))
						)
					)
			)
		)
	);

	changePasswordSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.changePasswordSuccess),
				tap(() =>
					this.alertService.success('Palavra passe alterada com sucesso.')
				)
			),
		{ dispatch: false }
	);

	changePasswordFailure$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.changePasswordFailure),
				tap(() =>
					this.alertService.error('Não foi possível alterar palavra passe.')
				)
			),
		{ dispatch: false }
	);

	loadNotifications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.loadNotifications),
			switchMap(() =>
				this.notificationsService.getAllNotifications().pipe(
					map((notifications: Notification[]) =>
						StatusActions.loadNotificationsSuccess({ notifications })
					),
					catchError(error =>
						of(StatusActions.loadNotificationsFailure({ error }))
					)
				)
			)
		)
	);

	submitResetPassword = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.submitResetPassword),
			switchMap(action =>
				this.authService.resetPassword(action.token, action.newPassword).pipe(
					map(() => StatusActions.resetPasswordSuccess()),
					catchError(error => of(StatusActions.resetPasswordFailure({ error })))
				)
			)
		)
	);

	redirectOnSuccessResetPassword = createEffect(
		() =>
			this.actions$.pipe(
				ofType(StatusActions.resetPasswordSuccess),
				tap(() => this.router.navigate(['/login']))
			),
		{ dispatch: false }
	);

	submitRequestPasswordReset = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.submitRequestPasswordReset),
			switchMap(action =>
				this.authService.requestPasswordReset(action.username).pipe(
					map(() => StatusActions.requestPasswordResetSuccess()),
					catchError(error =>
						of(StatusActions.requestPasswordResetFailure({ error }))
					)
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private authService: AuthService,
		private ṕreferencesService: PreferencesService,
		private authStorageService: AuthStorageService,
		private router: Router,
		private store$: Store<any>,
		private alertService: AlertService,
		private notificationsService: NotificationsService
	) {}
}
