import {
	createSelector,
	createFeatureSelector,
	Action,
	combineReducers,
} from '@ngrx/store';

import * as fromRoot from 'app/reducers';

export const mapFeatureKey = 'map';

export interface MapState {
}

export interface State extends fromRoot.State {
	[mapFeatureKey]: MapState;
}

export function reducers(state: MapState | undefined, action: Action) {
	return combineReducers({
	})(state, action);
}
