import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	mergeMap,
	map,
	catchError,
	withLatestFrom,
	exhaustMap,
	switchMap,
	tap,
	take,
} from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import moment from 'moment';
import * as XLSX from 'xlsx';

import { MeasurementActions } from 'app/devices/actions';
import * as fromAuth from 'app/auth/reducers';
import * as fromDevices from 'app/devices/reducers';
import {
	Measurement,
	MeasurementsByTimeStamp,
} from 'app/devices/models/measurement';
import * as measurementHelper from 'app/shared/helpers/measurement';

import { AlertService } from 'app/core/services/alert.service';
import * as MeasurementSelectors from 'app/devices/selectors/measurement.selectors';
import { MeasurementsService } from '../services/measurements.service';
import { isEmpty } from 'app/shared/helpers/lang';
import { degreesToCardinal } from 'app/shared/utils/meteorology';

@Injectable()
export class MeasurementEffects {
	/**
	 * pass an cfg object with a time range
	 */
	loadMeasurements$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MeasurementActions.loadMeasurements),
			map(action => action.cfg),
			withLatestFrom(
				this.store$.select(fromDevices.selectSelectedBoxId),
				this.store$.select(fromDevices.selectSelectedAvgPeriodType)
			),
			switchMap(([cfg, selectedBoxId, span]) =>
				this.measurementsService
					.getMeasurementByTimeStamp(cfg, selectedBoxId, span)
					.pipe(
						map(measurements =>
							MeasurementActions.loadMeasurementsSuccess({ measurements })
						),
						catchError(error =>
							of(MeasurementActions.loadMeasurementsFailure({ error }))
						)
					)
			)
		);
	});

	loadMultipleMeasurements$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MeasurementActions.loadMultipleMeasurements),
			withLatestFrom(this.store$.select(fromDevices.selectSelectedSensor)),
			switchMap(([{ startDate, endDate, devices }, selectedSensor]) => {
				return forkJoin(
					devices.map(dui =>
						this.measurementsService
							.getMeasurementByTimeStamp(
								{
									startDate,
									endDate,
								},
								dui,
								'1h'
							)
							.pipe(take(1))
					)
				).pipe(
					map(measurements => {
						const combinedData = measurements.reduce(
							(acc, curr) => {
								const sampleMeasurement = curr[0];

								if (!sampleMeasurement) {
									return acc;
								}

								acc.devices.push({
									name: sampleMeasurement['source'],
									field: `LUI${sampleMeasurement['currentLUI']}`,
								});

								for (const sensor of Object.keys(measurementHelper.sensors)) {
									if (!acc.data[sensor]) {
										acc.data[sensor] = {};
									}

									curr
										.map(measurement => ({
											dateObserved: measurement.dateObserved,
											...measurement[sensor],
										}))
										.filter(v => !!v)
										.forEach(val => {
											const dateData = acc.data[sensor][val.dateObserved];

											if (!dateData) {
												acc.data[sensor][val.dateObserved] = {};
											}

											acc.data[sensor][val.dateObserved][
												`LUI${sampleMeasurement.currentLUI}`
											] = val.value;
										});
								}

								return acc;
							},
							{
								devices: [],
								data: {},
							}
						);

						const selectedSensorName = selectedSensor.apiFieldName;
						const final = {
							sensor: selectedSensorName,
							devices: combinedData.devices,
							data: [],
						};

						Object.keys(combinedData.data[selectedSensorName]).forEach(
							dateObserved => {
								final.data.push({
									dateObserved,
									...combinedData.data[selectedSensorName][dateObserved],
								});
							}
						);

						return final;
					}),
					map(data =>
						MeasurementActions.loadMultipleMeasurementsSuccess({ data })
					),
					catchError(error =>
						of(MeasurementActions.loadMultipleMeasurementsFailure({ error }))
					)
				);
			})
		);
	});

	alertMissingDataOnMultipleMeasurementsSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(MeasurementActions.loadMultipleMeasurementsSuccess),
				map((action) => action.data),
				withLatestFrom(
					this.store$.select(MeasurementSelectors.selectSelectedDUIsToCompare),
					this.store$.select(fromDevices.selectAllBoxes)
				),
				tap(([data, selectedDUIs, devices]) => {
					const dataDUIs = data.devices.map((device) => device.name);

					const missingFromSelected = selectedDUIs.filter(
						(dui) => !dataDUIs.find((dDUI) => dDUI === dui)
					).map(dui => {
						const device = devices.find(d => d.DUI === dui);

						return device ? device.Description : dui;
					});

					if (missingFromSelected.length) {
						// Text with plural if there's more than one missing device from selected DUIs
						const alertText =
							missingFromSelected.length > 1
								? `Os dispositivos ${missingFromSelected} não possuem dados para o sensor selecionado.`
								: `O dispositivo ${missingFromSelected} não possue dados para o sensor selecionado.`;

						this.alertService.warning(alertText);
					}
				})
			),
		{ dispatch: false }
	);

	loadWindRoseDataOnMeasurementsSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(MeasurementActions.loadMeasurementsSuccess),
				map(() => MeasurementActions.loadWindRoseData())
			),
	);

	downloadMeasurements$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(MeasurementActions.downloadMeasurements),
				withLatestFrom(this.store$.select(fromDevices.selectAllMeasurements)),
				tap(([{ extension, fileName, sensor, avg }, allMeasurements]) => {
					const measurements = Object.keys(allMeasurements).map(measurement => {
						const { name, unit, value } = allMeasurements[measurement][sensor];

						return {
							Date: allMeasurements[measurement].dateObserved,
							[`${name} - ${unit}`]: value,
						};
					});

					if (!measurements) {
						return;
					}

					/* generate worksheet */
					const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(measurements);
					const wb: XLSX.WorkBook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(wb, ws, fileName);
					XLSX.writeFile(wb, `${fileName} - ${avg}.xlsx`);
				})
			),
		{ dispatch: false }
	);

	downloadAllMeasurements$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(MeasurementActions.downloadAllMeasurements),
				withLatestFrom(
					this.store$.select(fromDevices.selectAllMeasurements),
					this.store$
						.select(fromDevices.selectAllSensorsOfSelectedBox)
						.pipe(map(sensors => sensors.map(sensor => sensor.apiFieldName)))
				),
				tap(([{ extension, fileName, avg }, allMeasurements, sensors]) => {
					const measurements = allMeasurements
						.map(measurement => {
							const newMeasurement = [];

							sensors.forEach(sensor => {
								if (measurement[sensor]) {
									const { name, unit, value } = measurement[sensor];

									newMeasurement.push({
										Date: moment(measurement.dateObserved)
											.seconds(0)
											.milliseconds(0)
											.toISOString(),
										name,
										unit,
										value,
									});
								}
							});

							return newMeasurement;
						})
						.reduce((accumulator, currentValue) => {
							currentValue.forEach(measurement => {
								const dateObserved = measurement.Date;
								const { name: sensorName, unit, value } = measurement;

								if (!accumulator[dateObserved]) {
									accumulator[dateObserved] = {};
								}

								accumulator[dateObserved][sensorName] = {
									unit,
									value,
								};
							});

							return accumulator;
						}, {});

					const measurementsToSheet = Object.keys(measurements)
						.map(date => {
							const measurement = {
								Date: date,
							};

							const initialMeasurement = measurements[date];

							Object.keys(initialMeasurement).forEach(sensor => {
								measurement[`${sensor} - ${initialMeasurement[sensor].unit}`] =
									initialMeasurement[sensor].value;
							});

							return measurement;
						})
						.sort((a, b) => (a.Date < b.Date ? -1 : a.Date > b.Date ? 1 : 0));

					/* generate worksheet */
					const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
						measurementsToSheet
					);
					const wb: XLSX.WorkBook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(wb, ws, fileName);
					XLSX.writeFile(wb, `${fileName} - ${avg}.xlsx`);
				}),
				catchError(error =>
					of(MeasurementActions.downloadAllMeasurementsFailure)
				)
			),
		{ dispatch: false }
	);

	loadWindRoseData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MeasurementActions.loadWindRoseData),
			withLatestFrom(
				this.store$.select(fromDevices.selectAllMeasurements),
				this.store$.select(MeasurementSelectors.selectWindRose)
			),
			map(([action, measurementsData, { cardinalDirections, windSources }]) => {
				const measurements = measurementsData
					.filter(
						measurement =>
							measurement.METEOWINDSPEED &&
							!isEmpty(measurement.METEOWINDSPEED) &&
							measurement.METEOWINDDIRECTION &&
							!isEmpty(measurement.METEOWINDDIRECTION)
					)
					.map(measurement => ({
						windSpeed: measurement.METEOWINDSPEED.value,
						cardinal: degreesToCardinal(measurement.METEOWINDDIRECTION.value),
					}));

				function calcTotalSpeedOfField(
					occurrences: {
						valueField: string;
						cardinal: string;
						windSpeed: number;
					}[],
					grandTotal: number,
					cardinal: string,
					field: string
				): number {
					const totalOfField = occurrences.reduce(
						(accumulator, currentValue) =>
							accumulator +
							(cardinal === currentValue.cardinal &&
							currentValue.valueField === field
								? currentValue.windSpeed
								: 0),
						0
					);

					return (totalOfField / grandTotal) * 100;
				}

				const cardinalOccurrences = measurements.map(
					({ windSpeed, cardinal }) => {
						const source = windSources.find(
							({ min, max }) => windSpeed > min && windSpeed < max
						);

						return { valueField: source?.valueField, cardinal, windSpeed };
					}
				);

				const totalWindSpeed = cardinalOccurrences.reduce(
					(accumulator, { windSpeed }) => accumulator + windSpeed,
					0
				);

				const data = cardinalDirections.map(cardinal => {
					const cardinalGroup = {
						arg: cardinal,
					};

					windSources.forEach(({ valueField }) => {
						cardinalGroup[valueField] = calcTotalSpeedOfField(
							cardinalOccurrences,
							totalWindSpeed,
							cardinal,
							valueField
						);
					});

					return cardinalGroup;
				});

				return MeasurementActions.loadWindRoseDataSuccess({ data });
			}),
			catchError(error =>
				of(MeasurementActions.loadWindRoseDataFailure({ error }))
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private measurementsService: MeasurementsService,
		private alertService: AlertService
	) {}
}
