import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { UserData, User } from 'app/auth/models/user';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	constructor(private http: HttpClient) {}

	getAll$(): Observable<any> {
		return this.http.get(`${environment.api}/users`);
	}

	changeDisabledStatus$(userId: string, disabled: boolean): Observable<any> {
		return this.http.post(`${environment.api}/users/disable`, {
			userId,
			disabled,
		});
	}
}
