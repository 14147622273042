import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { DeviceAlert } from 'app/devices/models/device-alert.model';

export const loadDeviceAlerts = createAction(
	'[DeviceAlert/API] Load Device Alerts'
);

export const loadDeviceAlertsSuccess = createAction(
	'[DeviceAlert/API] Load Device Alerts Success',
	props<{ alerts: DeviceAlert[] }>()
);

export const loadDeviceAlertsFailure = createAction(
	'[DeviceAlert/API] Load Device Alerts Failure',
	props<{ error: any }>()
);

export const createDeviceAlert = createAction(
	'[DeviceAlert/API] Create Device Alert',
	props<DeviceAlert>()
);

export const createDeviceAlertSuccess = createAction(
	'[DeviceAlert/API] Create Device Alert Success',
	props<DeviceAlert>()
);

export const createDeviceAlertFailure = createAction(
	'[DeviceAlert/API] Create Device Alert Failure',
	props<{ error: any }>()
);

export const addDeviceAlert = createAction(
	'[DeviceAlert] Add Device Alert',
	props<{ alert: DeviceAlert }>()
);

export const upsertDeviceAlert = createAction(
	'[DeviceAlert/API] Upsert Device Alert',
	props<{ alert: DeviceAlert }>()
);

export const addDeviceAlerts = createAction(
	'[DeviceAlert/API] Add Device Alerts',
	props<{ alerts: DeviceAlert[] }>()
);

export const upsertDeviceAlerts = createAction(
	'[DeviceAlert/API] Upsert Device Alerts',
	props<{ alerts: DeviceAlert[] }>()
);

export const updateDeviceAlert = createAction(
	'[DeviceAlert/API] Update Device Alert',
	props<{ alert: Update<DeviceAlert> }>()
);

export const updateDeviceAlertSuccess = createAction(
	'[DeviceAlert/API] Update Device Alert Success'
);

export const updateDeviceAlertFailure = createAction(
	'[DeviceAlert/API] Update Device Alert',
	props<{ error: any }>()
);

export const updateDeviceAlerts = createAction(
	'[DeviceAlert/API] Update Device Alerts',
	props<{ alerts: Update<DeviceAlert>[] }>()
);

export const deleteDeviceAlert = createAction(
	'[DeviceAlert/API] Delete Device Alert',
	props<{ id: string }>()
);

export const deleteDeviceAlertSuccess = createAction(
	'[DeviceAlert/API] Delete Device Alert Sucess'
);

export const deleteDeviceAlertFailure = createAction(
	'[DeviceAlert/API] Delete Device Alert Failure',
	props<{ error: any }>()
);

export const deleteDeviceAlerts = createAction(
	'[DeviceAlert/API] Delete Device Alerts',
	props<{ ids: string[] }>()
);

export const clearDeviceAlerts = createAction(
	'[DeviceAlert/API] Clear Device Alerts'
);
