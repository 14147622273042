import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PreferencesActions } from 'app/auth/actions';

import * as fromAuth from 'app/auth/reducers';

@Component({
	selector: 'qrt-subnav-lisboa',
	templateUrl: './subnavlisboa.component.html',
	styleUrls: ['./subnavlisboa.component.scss'],
})
export class SubnavLisboaComponent implements OnInit {
	meteoFormSubNav: FormGroup;

	isAdmin$: Observable<boolean>;

	constructor(private formBuilder: FormBuilder, private store$: Store<any>) {
		this.isAdmin$ = store$.pipe(select(fromAuth.selectIsAdmin));
	}

	ngOnInit(): void {
		this.meteoFormSubNav = this.formBuilder.group({
			meteoToggleSubNav: [''],
		});
	}

	setMeteoPreference() {
		const activated = this.meteoFormSubNav.controls.meteoToggleSubNav.value;

		this.store$.dispatch(PreferencesActions.meteoPreference({ activated }));
	}
}
