<div class="qrt_password">
	<form
		class="qrt_password__form"
		clrForm
		clrLayout="vertical"
		[formGroup]="changePasswordForm"
		(ngSubmit)="changePassword()"
	>
		<div class="qrt_password__inputs">
			<clr-password-container>
				<label>{{ 'Palavra passe actual' | t }}</label>
				<input
					class="qrt_password__input"
					clrPassword
					name="oldpassword"
					formControlName="oldPassword"
				/>
				<clr-control-error
					>{{ 'Campo de preenchimento obrigatório' | t }}
				</clr-control-error>
			</clr-password-container>
			<clr-password-container>
				<label>{{ 'Nova palavra passe' | t }}</label>
				<input
					class="qrt_password__input"
					clrPassword
					name="newpassword"
					formControlName="newPassword"
				/>
				<clr-control-error
					>{{ 'Campo de preenchimento obrigatório' | t }}
				</clr-control-error>
			</clr-password-container>
			<clr-password-container>
				<label>{{ 'Confirmar nova palavra passe' | t }}</label>
				<input
					class="qrt_password__input"
					clrPassword
					name="newpasswordconfirmation"
					formControlName="newPasswordConfirmation"
				/>
				<clr-control-error
					>{{ 'Campo de preenchimento obrigatório' | t }}
				</clr-control-error>
			</clr-password-container>
		</div>
		<clr-alert
			class="qrt_password__confirmation-warning"
			[clrAlertType]="'warning'"
			*ngIf="!(sameNewPassword$ | async)"
		>
			<clr-alert-item>
				<span class="alert-text"
					>A confirmação deve coincidir com a nova palavra passe.</span
				>
			</clr-alert-item>
		</clr-alert>
		<div class="qrt_password__btns">
			<button class="btn btn-primary" [disabled]="!(formIsValid$ | async)">
				{{ 'Alterar' | t }}
			</button>
			<button class="btn" routerLink="/map">{{ 'Cancelar' | t }}</button>
		</div>
	</form>
</div>
