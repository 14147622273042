import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ForecastActions from '../actions/forecast.actions';
import { ForecastService } from 'app/core/services/forecast.service';

@Injectable()
export class ForecastEffects {
	loadLatestWindForecast$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ForecastActions.loadLatestWindForecast),
			switchMap(() =>
				this.forecastService.getLatest$().pipe(
					map(data => ForecastActions.loadLatestWindForecastSuccess({ data })),
					catchError(error =>
						of(ForecastActions.loadLatestWindForecastFailure({ error }))
					)
				)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private forecastService: ForecastService
	) {}
}
