<div
	*ngIf="(maintenance$ | async)?.Visible"
	class="alert alert-app-level alert-warning"
	role="alert"
>
	<div class="alert-items">
		<div class="alert-item static">
			<div class="alert-icon-wrapper">
				<clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
			</div>
			<div class="alert-text">
				{{ (maintenance$ | async)?.message }}
			</div>
		</div>
	</div>
	<button type="button" class="close" aria-label="Close">
		<clr-icon aria-hidden="true" shape="close"></clr-icon>
	</button>
</div>