import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, take, switchMap, catchError, map } from 'rxjs/operators';

import { AlertService } from 'app/core/services/alert.service';
import { DeviceAlertService } from 'app/devices/services/device-alert.service';
import { DeviceAlertActions } from 'app/devices/actions';

import { DeviceAlert } from 'app/devices/models/device-alert.model';

@Injectable()
export class DeviceAlertEffects {
	loadDeviceAlerts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeviceAlertActions.loadDeviceAlerts),
			switchMap(() =>
				this.deviceAlertService.getAllDeviceAlerts().pipe(
					map(alerts => DeviceAlertActions.loadDeviceAlertsSuccess({ alerts })),
					catchError(error =>
						of(DeviceAlertActions.loadDeviceAlertsFailure({ error }))
					)
				)
			)
		)
	);

	createDeviceAlert$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeviceAlertActions.createDeviceAlert),
			switchMap((alert: DeviceAlert) =>
				this.deviceAlertService.createDeviceAlert(alert).pipe(
					map(response =>
						DeviceAlertActions.createDeviceAlertSuccess(response.alert)
					),
					catchError(error =>
						of(DeviceAlertActions.createDeviceAlertFailure({ error }))
					)
				)
			)
		)
	);

	addDeviceAlert$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeviceAlertActions.createDeviceAlertSuccess),
			map(alert => DeviceAlertActions.addDeviceAlert({ alert }))
		)
	);

	createDeviceAlertSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(DeviceAlertActions.createDeviceAlertSuccess),
				tap(() => this.alertService.success('Alerta criado com successo.'))
			),
		{ dispatch: false }
	);

	updateDeviceAlert$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeviceAlertActions.updateDeviceAlert),
			switchMap(({ alert }) =>
				this.deviceAlertService
					.updateDeviceAlert$(alert.id, alert.changes)
					.pipe(
						map(() => DeviceAlertActions.updateDeviceAlertSuccess()),
						catchError(error =>
							of(DeviceAlertActions.updateDeviceAlertFailure({ error }))
						)
					)
			)
		)
	);

	updateDeviceAlertSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(DeviceAlertActions.updateDeviceAlertSuccess),
				tap(() => this.alertService.success('Alerta actualizado com successo.'))
			),
		{ dispatch: false }
	);

	deleteDeviceAlert$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DeviceAlertActions.deleteDeviceAlert),
			switchMap(({ id }) =>
				this.deviceAlertService.deleteDeviceAlert(id).pipe(
					map(() => DeviceAlertActions.deleteDeviceAlertSuccess()),
					catchError(error =>
						of(DeviceAlertActions.deleteDeviceAlertFailure({ error }))
					)
				)
			)
		)
	);

	deleteDeviceAlertSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(DeviceAlertActions.deleteDeviceAlertSuccess),
				tap(() => this.alertService.success('Alerta removido com successo.'))
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private alertService: AlertService,
		private deviceAlertService: DeviceAlertService
	) {}
}
