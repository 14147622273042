import { Pipe, PipeTransform } from '@angular/core';
import { formatMessage } from 'devextreme/localization';

@Pipe({
	name: 't',
	pure: false,
})
export class TranslatePipe implements PipeTransform {
	constructor() {}

	transform(value: any, args?: any): any {
		let result = formatMessage(value, '') || value;
		return result;
	}
}