import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlertService } from 'app/core/services/alert.service';
import { Alert } from 'app/core/models/alert';

@Component({
	selector: 'qrt-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
	alert$: Observable<Alert>;

	constructor(protected alertService: AlertService) {
		this.alert$ = this.alertService.onAlert().pipe(
			tap((alert) => {
				setTimeout(() => {
					this.alertService.clear();
				}, 5000);
			})
		);
	}

	ngOnInit(): void {}

	ngOnDestroy() {}
}
