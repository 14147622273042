const getMaxDaysFromAvgType = (avgType: string) => {
	switch (avgType) {
		case '1mn':
			return 2;
		case '10mn':
			return 3;
		case '15mn':
			return 3;
		case '1h':
			return Infinity;
		case '8h':
			return Infinity;
		case '1d':
			return Infinity;
		case '1w':
			return Infinity;
		default:
			return Infinity;
	}
};

export { getMaxDaysFromAvgType };
