<div class="qrt_issues">
	<div class="qrt_issues__top">
		<h1 class="qrt_issues__title">Anomalias</h1>
		<qrt-new-issue></qrt-new-issue>
	</div>

	<clr-datagrid>
		<clr-dg-column>LUI</clr-dg-column>
		<clr-dg-column>Descrição</clr-dg-column>
		<clr-dg-column>Incidente</clr-dg-column>
		<clr-dg-column>Estado</clr-dg-column>

		<clr-dg-row *ngFor="let issue of issues$ | async">
			<clr-dg-cell>{{ issue.location }}</clr-dg-cell>
			<clr-dg-cell>{{ issue.description }}</clr-dg-cell>
			<clr-dg-cell>{{ incidentMapping[issue.incident] }}</clr-dg-cell>
			<clr-dg-cell>{{ statusMapping[issue.status] }}</clr-dg-cell>
		</clr-dg-row>
	</clr-datagrid>
</div>
