import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as LayoutActions from 'app/core/actions/layout.actions';
import { chartType } from 'app/core/reducers/layout.reducer';

@Component({
	selector: 'qrt-chart-options',
	templateUrl: './chart-options.component.html',
	styleUrls: ['./chart-options.component.scss'],
})
export class ChartOptionsComponent implements OnInit {
	@Input() sensorConfig: any;

	constructor(private store$: Store<any>) {}

	ngOnInit(): void {}

	public changeChartType(type: chartType): void {
		this.store$.dispatch(
			LayoutActions.changeDeviceDetailsChartType({ chartType: type })
		);
	}

	public toggleLimitVisible(index: number) {
		this.store$.dispatch(LayoutActions.toggleLimitVisible({ index }));

		this.changeChartMaxValueMarginBasedOnLimits();
	}

	changeChartMaxValueMarginBasedOnLimits() {
		this.store$.dispatch(LayoutActions.changeChartMaxValueMarginBasedOnLimits());
	}
}
