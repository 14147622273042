<button
	type="button"
	class="btn btn-icon"
	aria-label="settings"
	(click)="openModal()"
>
	Selecionar dispositivos	
	<clr-icon shape="add-text"></clr-icon>
</button>

<clr-modal [clrModalOpen]="showModal$ | async" [clrModalClosable]="false">
	<h3 class="modal-title">Selecionar dispositivos a comparar</h3>
	<div class="modal-body" *ngIf="devices$ | async as devices">
		<form
			novalidate
			ngNoForm
			*ngIf="form$ | async as formState"
			[ngrxFormState]="formState"
			id="comparison-form"
		>
			<clr-checkbox-wrapper
				*ngFor="let control of formState.controls; trackBy: trackByIndex; let i = index"
			>
				<input
					type="checkbox"
					clrCheckbox
					name="options"
					required
					[value]="control.value"
					[ngrxFormControlState]="control"
				/>
				<label>{{ devices[i].description }}</label>
			</clr-checkbox-wrapper>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="closeModal()">
			Cancelar
		</button>
		<button
			type="submit"
			form="comparison-form"
			class="btn btn-primary"
			(click)="onSubmit()"
		>
			Ok
		</button>
	</div>
</clr-modal>
