import { Action, createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

import * as ForecastActions from '../actions/forecast.actions';

export const forecastFeatureKey = 'forecast';

export interface State {
	loading: boolean;
	loaded: boolean;
	data: any;
}

export const initialState: State = {
	loading: true,
	loaded: false,
	data: null,
};

export const reducer = createReducer(
	initialState,
	on(ForecastActions.loadLatestWindForecast, state => state),
	on(ForecastActions.loadLatestWindForecastSuccess, (state, { data }) =>
		produce(state, draft => {
			draft.loading = false;
			draft.loaded = true;
			draft.data = data;
		})
	)
);

export const getForecastData = (state: State) => state.data;
