import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Credentials, UserData } from 'app/auth/models/user';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private http: HttpClient;

	constructor(handler: HttpBackend) {
		this.http = new HttpClient(handler);
	}

	public login({ username, password }: Credentials): Observable<UserData> {
		return this.http.post<UserData>(
			`${environment.api}/auth/login?include=user`,
			{
				username,
				password,
			}
		);
	}

	public resetPassword(token, newPassword): Observable<any> {
		return this.http.post<any>(
			`https://portalapi.qart.pt/auth/change-password?u=${token}`,
			{
				newPassword,
			}
		);
	}

	public requestPasswordReset(username): Observable<any> {
		return this.http.post<any>(
			`https://portalapi.qart.pt/auth/reset-password`,
			{
				username,
			}
		);
	}
}
