<button
	data-testid="diag-btn"
	class="btn btn-icon"
	aria-label="Análise de dispositivo"
	(click)="openModal()"
>
	<clr-icon shape="shield-check"></clr-icon>
	Diagnóstico
</button>

<clr-modal
	clrModalSize="lg"
	[clrModalOpen]="showModal$ | async"
	[clrModalClosable]="false"
>
	<h3 class="modal-title">Diagnóstico de dispositivo</h3>
	<div class="modal-body">
		<button
			aria-label="Fechar"
			class="close qrt_diagnosis__close"
			type="button"
			(click)="closeModal()"
		>
			<clr-icon aria-hidden="true" shape="close"></clr-icon>
		</button>
		<form
			[formGroup]="diagnosisForm"
			(ngSubmit)="requestDiagnosis()"
			class="qrt_diagnosis__form"
			clrForm
		>
			<clr-input-container>
				<label>DUI</label>
				<input
					formControlName="dui"
					clrInput
					data-testid="dui-input"
					type="text"
					placeholder="XXXXXXXXXXXX"
			/></clr-input-container>
			<div>
				<button
					[clrLoading]="requestDiagnosisBtnState"
					class="btn btn-primary btn-sm"
					[disabled]="dui.invalid"
				>
					Buscar
				</button>
				<button
					type="button"
					class="btn btn-warning btn-sm"
					(click)="stopRequestingDiagnosis()"
					[disabled]="!(requestingDiagnosis$ | async)"
				>
					<clr-icon shape="ban"></clr-icon>
					Parar
				</button>
			</div>
		</form>

		<div
			*ngIf="diagnosisLoading$ | async"
			class="progress top loop qrt_progress"
		>
			<progress max="100" value="0" data-displayval="0%"></progress>
		</div>

		<clr-datagrid
			*ngIf="diagnosis$ | async as diagnosis"
			class="qrt_response-datagrid"
		>
			<clr-dg-column class="qrt_response-datagrid__status"
				>Status</clr-dg-column
			>
			<clr-dg-column class="qrt_response-datagrid__date">Data</clr-dg-column>
			<clr-dg-column>Resposta</clr-dg-column>

			<clr-dg-row>
				<clr-dg-cell class="qrt_response-datagrid__status">{{
					diagnosis.Status
				}}</clr-dg-cell>
				<clr-dg-cell class="qrt_response-datagrid__date">{{
					diagnosis.TimeStamp
				}}</clr-dg-cell>
				<clr-dg-cell
					><code>{{ diagnosis.Raw | json }}</code></clr-dg-cell
				>
			</clr-dg-row>
		</clr-datagrid>
	</div>
</clr-modal>
