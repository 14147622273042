import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgrxFormsModule } from 'ngrx-forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClarityModule } from '@clr/angular';
import {
	DxChartModule,
	DxSelectBoxModule,
	DxDateBoxModule,
	DxRangeSelectorModule,
	DxPolarChartModule,
} from 'devextreme-angular';

import * as fromDevices from './reducers';
import {
	BoxEffects,
	MeasurementEffects,
	LastMeasurementEffects,
	DetailedLastMeasurementEffects,
	OldestMeasurementEffects,
	LocationEffects,
	DeviceAlertEffects,
} from 'app/devices/effects';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import { BoxListComponent } from 'app/devices/components/boxlist/boxlist.component';
import { BoxDetailsComponent } from 'app/devices/components/boxdetails/boxdetails.component';
import { ReportComponent } from 'app/devices/components/report/report.component';
import { DevicesRoutingModule } from './devices-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { CoreModule } from 'app/core/core.module';
import { ExportMeasurementsComponent } from './components/export-measurements/export-measurements.component';
import { DeviceDiagnosisComponent } from './components/device-diagnosis/device-diagnosis.component';
import { AlertListComponent } from './components/alertlist/alertlist.component';
import { CreateAlertComponent } from './components/create-alert/create-alert.component';
import { EditAlertComponent } from './components/edit-alert/edit-alert.component';
import { DeleteAlertComponent } from './components/delete-alert/delete-alert.component';
import { MeasurementsTableComponent } from './components/measurements-table/measurements-table.component';
import { MeasurementsControllerComponent } from './components/measurements-controller/measurements-controller.component';
import { ChartOptionsComponent } from './components/chart-options/chart-options.component';
import * as fromSensor from './reducers/sensor.reducer';
import { SensorEffects } from './effects/sensor.effects';
import { PresentationModeControllerComponent } from './components/presentation-mode-controller/presentation-mode-controller.component';
import { MeasurementsWindRoseComponent } from './components/measurements-wind-rose/measurements-wind-rose.component';
import { ReportHeaderComponent } from './components/report-header/report-header.component';
import { SideBySideBarChartComponent } from './components/side-by-side-bar-chart/side-by-side-bar-chart.component';
import { ConfigComparisonComponent } from './components/config-comparison/config-comparison.component';
import { IssuesComponent } from './components/issues/issues.component';
import { NewIssueComponent } from './components/new-issue/new-issue.component';
import { OverviewComponent } from './components/overview/overview.component';
import * as fromDetailedLastMeasurement from './reducers/detailed-last-measurement.reducer';

@NgModule({
	declarations: [
		BoxListComponent,
		BoxDetailsComponent,
		ReportComponent,
		ExportMeasurementsComponent,
		DeviceDiagnosisComponent,
		AlertListComponent,
		CreateAlertComponent,
		EditAlertComponent,
		DeleteAlertComponent,
		MeasurementsTableComponent,
		MeasurementsControllerComponent,
		ChartOptionsComponent,
		PresentationModeControllerComponent,
		MeasurementsWindRoseComponent,
		ReportHeaderComponent,
		SideBySideBarChartComponent,
		ConfigComparisonComponent,
		IssuesComponent,
		NewIssueComponent,
		OverviewComponent,
	],
	imports: [
		CommonModule,
		HighlightModule,
		DevicesRoutingModule,
		SharedModule,
		CoreModule,
		ReactiveFormsModule,
		NgrxFormsModule,
		StoreModule.forFeature(fromDevices.devicesFeatureKey, fromDevices.reducers),
		EffectsModule.forFeature([
			BoxEffects,
			MeasurementEffects,
			LastMeasurementEffects,
			DetailedLastMeasurementEffects,
			OldestMeasurementEffects,
			LocationEffects,
			DeviceAlertEffects,
			SensorEffects,
		]),
		LeafletModule,
		ClarityModule,
		DxChartModule,
		DxSelectBoxModule,
		DxDateBoxModule,
		DxRangeSelectorModule,
		DxPolarChartModule,
		StoreModule.forFeature(fromSensor.sensorsFeatureKey, fromSensor.reducer),
		StoreModule.forFeature(
			fromDetailedLastMeasurement.detailedLastMeasurementsFeatureKey,
			fromDetailedLastMeasurement.reducer
		),
	],
	providers: [
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				fullLibraryLoader: () => import('highlight.js'),
			},
		},
	],
})
export class DevicesModule {}
