<button
	class="btn btn-icon"
	aria-label="Exportar medidas"
	(click)="openExportModal()"
>
	<clr-icon shape="export"></clr-icon>
	Exportar
</button>
<clr-modal
	*ngIf="isModalVisible"
	[(clrModalOpen)]="isModalVisible"
	class="qrt_export-modal"
>
	<h3 class="modal-title">Exportar medidas</h3>
	<div class="modal-body">
		<form
			id="exportform"
			[formGroup]="exportForm"
			class="qrt_export-modal__exportform"
			data-testid="exportForm"
			(ngSubmit)="requestExport()"
		>
			<div class="qrt_export-modal__notify">
				<h6>Notificações</h6>
				<span
					role="tooltip"
					aria-haspopup="true"
					class="tooltip tooltip-lg tooltip-bottom-right"
				>
					<clr-icon shape="info-circle" size="24"></clr-icon>
					<span class="tooltip-content"
						>Requisição de muitos dispositivos e/ou longos períodos de data
						podem resultar numa demora para o envio dos dados, será notificado
						quando pronto.</span
					>
				</span>
			</div>
			<clr-toggle-container>
				<clr-toggle-wrapper>
					<label>Notificar por SMS</label>
					<input type="checkbox" clrToggle formControlName="notifyBySms" />
				</clr-toggle-wrapper>
			</clr-toggle-container>
			<h6>Selecionar período</h6>
			<div class="qrt_export-modal__dates-container">
				<clr-date-container>
					<label>Inicio</label>
					<input
						type="date"
						clrDate
						formControlName="startDate"
						data-testid="startDateControl"
						[max]="maxDate"
					/>
					<clr-control-error
						>É preciso especificar este campo.</clr-control-error
					>
				</clr-date-container>
				<clr-date-container>
					<label>Fim</label>
					<input
						type="date"
						clrDate
						formControlName="endDate"
						data-testid="endDateControl"
						[max]="maxDate"
					/>
					<clr-control-error
						>É preciso especificar este campo.</clr-control-error
					>
				</clr-date-container>
			</div>
			<h6>Selecionar média</h6>
			<clr-select-container>
				<label for="select-average" class="clr-control-label">Média</label>
				<select
					formControlName="average"
					clrSelect
					id="select-average"
					class="clr-select"
					data-testid="averageControl"
				>
					<ng-container *ngIf="isAdmin$ | async">
						<option value="1mn" [disabled]="invalidAvg('1mn')">Minuto</option>
						<option value="10mn" [disabled]="invalidAvg('10mn')">10 minutos</option>
						<option value="15mn" [disabled]="invalidAvg('15mn')">15 minutos</option>
					</ng-container>
					<option value="1h" [disabled]="invalidAvg('1h')">Horária</option>
					<option value="8h" [disabled]="invalidAvg('8h')">8 horas</option>
					<option value="1d" [disabled]="invalidAvg('1d')">Diário</option>
					<option value="1w" [disabled]="invalidAvg('1w')">Semanal</option>
				</select>
				<clr-control-error
					>Média invalida para o período selecionado.</clr-control-error
				>
			</clr-select-container>
			<h6>Selecionar dispositivos</h6>
			<div class="qrt_export-modal__select-btns">
				<button
					type="button"
					class="btn btn-outline btn-sm"
					data-testid="selectall-btn"
					(click)="selectAll(true)"
				>
					Selecionar todos
				</button>
				<button
					type="button"
					class="btn btn-warning-outline btn-sm"
					(click)="selectAll(false)"
				>
					Desseleccionar todos
				</button>
			</div>
			<clr-checkbox-container *ngIf="boxes$ | async as boxes">
				<clr-checkbox-wrapper
					formArrayName="boxes"
					*ngFor="
						let box of exportForm.controls['boxes']['controls'];
						let i = index
					"
				>
					<label>{{ boxes[i].currentLUI }} - {{ boxes[i].Description }}</label>
					<input [formControlName]="i" type="checkbox" clrCheckbox />
				</clr-checkbox-wrapper>
			</clr-checkbox-container>
		</form>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-outline"
			(click)="isModalVisible = false"
		>
			Cancelar
		</button>
		<button
			[clrLoading]="exportBtnState"
			form="exportform"
			class="btn btn-primary"
			data-testid="submitBtn"
   			[disabled]="exportForm.invalid"
		>
			Exportar
		</button>
	</div>
</clr-modal>
