import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { Notification } from 'app/core/models/notification';

import * as fromAuth from 'app/auth/reducers';

@Component({
	selector: 'qrt-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
	open: boolean = false;
	unreadNotifications$: Observable<Notification[]>;
	unreadNotificationsCounter$: Observable<number>;

	constructor(private store$: Store<any>) {
		this.unreadNotifications$ = this.store$.pipe(
			select(fromAuth.selectUnreadNotifications)
		);

		this.unreadNotificationsCounter$ = this.store$.pipe(
			select(fromAuth.selectCounterUnreadNotifications)
		);
	}

	ngOnInit(): void {}

	toggleNotifications() {
		this.open = !this.open;
	}

}
