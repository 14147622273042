import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { ClrLoadingState } from '@clr/angular';

import { LayoutActions } from 'app/core/actions';

export type presentationType =
	| 'chart'
	| 'chart-comparison'
	| 'table'
	| 'wind-rose';
export type chartType = 'bar' | 'line' | 'area';

export const layoutFeatureKey = 'layout';

export interface State {
	showNotificationCreatorModal: boolean;
	showEditAlertModal: boolean;
	currentEditAlertId: string | null;
	showDiagnosisModal: boolean;
	deviceDetailsPresentationType: presentationType;
	deviceDetailsChartType: chartType;
	chartMaxValueMargin: number;
	sensorConfig: {
		name: string;
		limits: any[];
	};
	showChangeDisabledStatusModal: boolean;
	submitChangeDisabledStatusButtonState: ClrLoadingState;
	showRequestPasswordResetModal: boolean;
	showComparisonModal: boolean;
	showNewIssueModal: boolean;
	submitCreateNewIssueButtonState: ClrLoadingState;
}

const initialState: State = {
	showNotificationCreatorModal: false,
	showEditAlertModal: false,
	currentEditAlertId: null,
	showDiagnosisModal: false,
	deviceDetailsPresentationType: 'chart',
	deviceDetailsChartType: 'bar',
	chartMaxValueMargin: 0,
	sensorConfig: null,
	showChangeDisabledStatusModal: false,
	submitChangeDisabledStatusButtonState: ClrLoadingState.DEFAULT,
	showRequestPasswordResetModal: false,
	showComparisonModal: false,
	showNewIssueModal: false,
	submitCreateNewIssueButtonState: ClrLoadingState.DEFAULT,
};

export const reducer = createReducer(
	initialState,
	on(LayoutActions.openNotificationCreatorModal, state =>
		produce(state, draft => {
			draft.showNotificationCreatorModal = true;
		})
	),
	on(LayoutActions.closeNotificationCreatorModal, state =>
		produce(state, draft => {
			draft.showNotificationCreatorModal = false;
		})
	),
	on(LayoutActions.openEditAlertModal, (state, { id }) =>
		produce(state, draft => {
			draft.showEditAlertModal = true;
			draft.currentEditAlertId = id;
		})
	),
	on(LayoutActions.closeEditAlertModal, state =>
		produce(state, draft => {
			draft.showEditAlertModal = false;
		})
	),
	on(LayoutActions.openDeviceDiagnosisModal, state =>
		produce(state, draft => {
			draft.showDiagnosisModal = true;
		})
	),
	on(LayoutActions.closeDeviceDiagnosisModal, state =>
		produce(state, draft => {
			draft.showDiagnosisModal = false;
		})
	),
	on(LayoutActions.changeDeviceDetailsPresentationType, (state, { presType }) =>
		produce(state, draft => {
			draft.deviceDetailsPresentationType = presType;
		})
	),
	on(LayoutActions.changeDeviceDetailsChartType, (state, { chartType }) =>
		produce(state, draft => {
			draft.deviceDetailsChartType = chartType;
		})
	),
	on(LayoutActions.changeSensorConfig, (state, { config }) =>
		produce(state, draft => {
			draft.sensorConfig = config;
		})
	),
	on(LayoutActions.toggleLimitVisible, (state, { index }) =>
		produce(state, draft => {
			const limit = draft.sensorConfig?.limits[index] || {};
			limit.visible = !limit.visible;
		})
	),
	on(LayoutActions.changeChartMaxValueMargin, (state, { value }) =>
		produce(state, draft => {
			draft.chartMaxValueMargin = value;
		})
	),
	on(LayoutActions.openChangeDisabledStatusModal, state =>
		produce(state, draft => {
			draft.showChangeDisabledStatusModal = true;
		})
	),
	on(LayoutActions.closeChangeDisabledStatusModal, state =>
		produce(state, draft => {
			draft.showChangeDisabledStatusModal = false;
		})
	),
	on(
		LayoutActions.updateChangeDisabledStatusSubmitButtonState,
		(state, { loadingState }) =>
			produce(state, draft => {
				draft.submitChangeDisabledStatusButtonState = loadingState;
			})
	),
	on(LayoutActions.openRequestPasswordResetModal, state =>
		produce(state, draft => {
			draft.showRequestPasswordResetModal = true;
		})
	),
	on(LayoutActions.closeRequestPasswordResetModal, state =>
		produce(state, draft => {
			draft.showRequestPasswordResetModal = false;
		})
	),
	on(LayoutActions.openComparisonModal, state =>
		produce(state, draft => {
			draft.showComparisonModal = true;
		})
	),
	on(LayoutActions.closeComparisonModal, state =>
		produce(state, draft => {
			draft.showComparisonModal = false;
		})
	),
	on(LayoutActions.openNewIssueModal, state =>
		produce(state, draft => {
			draft.showNewIssueModal = true;
		})
	),
	on(LayoutActions.closeNewIssueModal, state =>
		produce(state, draft => {
			draft.showNewIssueModal = false;
		})
	),
	on(
		LayoutActions.updateSubmitCreateNewIssueButtonState,
		(state, { loadingState }) =>
			produce(state, draft => {
				draft.submitCreateNewIssueButtonState = loadingState;
			})
	)
);

export const selectShowNotificationCreatorModal = (state: State) =>
	state.showNotificationCreatorModal;

export const selectShowEditAlertModal = (state: State) =>
	state.showEditAlertModal;

export const selectAlertIdEditAlert = (state: State) =>
	state.currentEditAlertId;

export const selectShowDeviceDiagnosisModal = (state: State) =>
	state.showDiagnosisModal;

export const selectDeviceDetailsPresentationType = (state: State) =>
	state.deviceDetailsPresentationType;

export const selectDeviceDetailsChartType = (state: State) =>
	state.deviceDetailsChartType;

export const selectSensorConfig = (state: State) => state.sensorConfig;

export const selectChartMaxValueMargin = (state: State) =>
	state.chartMaxValueMargin;

export const getShowChangeDisabledStatusModal = (state: State) =>
	state.showChangeDisabledStatusModal;

export const getSubmitChangeDisabledStatusButtonState = (state: State) =>
	state.submitChangeDisabledStatusButtonState;

export const getShowRequestPasswordResetModal = (state: State) =>
	state.showRequestPasswordResetModal;

export const getShowComparisonModal = (state: State) =>
	state.showComparisonModal;

export const getShowNewIssueModal = (state: State) => state.showNewIssueModal;

export const getSubmitCreateNewDeviceButtonState = (state: State) =>
	state.submitCreateNewIssueButtonState;
