<clr-alert
	*ngIf="alert$ | async as alert"
	class="qrt_alert"
	[clrAlertType]="alert.type"
	[clrAlertClosable]="false"
>
	<clr-alert-item>
		<span class="alert-text">
			{{ alert.message }}
		</span>
	</clr-alert-item>
</clr-alert>
