import { createAction, union, props } from '@ngrx/store';
import { Credentials, UserData, User } from 'app/auth/models/user';
import { Notification } from 'app/core/models/notification';

export const login = createAction(
	'[Auth] Login',
	props<{ credentials: Credentials }>()
);

export const loginSuccess = createAction(
	'[Auth] Login Success',
	props<{ userData: UserData }>()
);

export const loginRedirect = createAction('[Auth] Login Redirect');

export const loginFailure = createAction(
	'[Auth] Login Failure',
	props<{ error: any }>()
);

export const logout = createAction('[Auth] Logout');

export const loadUserData = createAction('[Auth/Storage] Load User Data');

export const loadUserDataSuccess = createAction(
	'[Auth/Storage] Load User Data Success',
	props<{ userData: UserData }>()
);

export const loadUserDataFailure = createAction(
	'[Auth/Storage] Load User Data Failure',
	props<{ error: any }>()
);

export const addUserDataToStorage = createAction('[Auth/Storage] Add User');

export const addUserDataToStorageSuccess = createAction(
	'[Auth/Storage] Add User Success'
);

export const addUserDataToStorageFailure = createAction(
	'[Auth/Storage] Add user Failure',
	props<{ error: any }>()
);

export const deleteUserData = createAction('[Auth/Storage] Delete User');

export const deleteUserDataSuccess = createAction(
	'[Auth/Storage] Delete User Success'
);

export const deleteUserDataFailure = createAction(
	'[Auth/Storage] Delete User Failure',
	props<{ error: any }>()
);

export const updateUser = createAction(
	'[Auth/API] Update User',
	props<{ data: object }>()
);

export const updateUserSuccess = createAction(
	'[Auth/API] Update User Success',
	props<{ user: User }>()
);

export const updateUserFailure = createAction(
	'[Auth/API] Update User Data Failure',
	props<{ error: any }>()
);

export const changePassword = createAction(
	'[Auth/API] Change Password',
	props<{ oldPassword: string; newPassword: string }>()
);

export const changePasswordSuccess = createAction(
	'[Auth/API] Change Password Success'
);

export const changePasswordFailure = createAction(
	'[Auth/API] Change Password Data Failure',
	props<{ error: any }>()
);

export const loadNotifications = createAction('[Auth/API] Load Notifications');

export const loadNotificationsSuccess = createAction(
	'[Auth/API] Load Notifications Success',
	props<{ notifications: Notification[] }>()
);

export const loadNotificationsFailure = createAction(
	'[Auth/API] Load Notifications Failure',
	props<{ error: any }>()
);

export const submitResetPassword = createAction(
	'[Auth/API] Submit Reset Password Form',
	props<{ token: string; newPassword: string }>()
);

export const resetPasswordSuccess = createAction(
	'[Auth/API] Reset Password Success'
);

export const resetPasswordFailure = createAction(
	'[Auth/API] Reset Password Failure',
	props<{ error: any }>()
);

export const submitRequestPasswordReset = createAction(
	'[Auth/API] Submit Request Password Reset',
	props<{ username: string }>()
);

export const requestPasswordResetSuccess = createAction(
	'[Auth/API] Request Password Reset Success'
);

export const requestPasswordResetFailure = createAction(
	'[Auth/API] Request Password Reset Failure',
	props<{ error: any }>()
);
