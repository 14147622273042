import {
	averageIcon,
	badIcon,
	goodIcon,
	weakIcon,
	greatIcon,
	errorIcon,
	normalIcon,
	offIcon,
} from 'app/shared/icons';

import { degreesToCardinal } from 'app/shared/utils/meteorology';

export const sensors = {
	CO: { name: 'CO', type: 'airQuality' },
	O3: { name: 'O3', type: 'airQuality' },
	NO: { name: 'NO', type: 'airQuality' },
	NO2: { name: 'NO2', type: 'airQuality' },
	SO2: { name: 'SO2', type: 'airQuality' },
	HUMEXT: { name: 'Humidade', type: 'airQuality' },
	HUMINT: { name: 'Humidade Interior', type: 'airQuality' },
	TEMPEXT: { name: 'Temperatura', type: 'airQuality' },
	TEMPINT: { name: 'Temperatura Interior', type: 'airQuality' },
	PARTICULES_MASS: { name: 'PM MASS', type: 'airQuality' },
	PARTICULES_PM05: { name: 'PM 0.5', type: 'airQuality' },
	PARTICULES_PM07: { name: 'PM 0.7', type: 'airQuality' },
	PARTICULES_PM01: { name: 'PM 1', type: 'airQuality' },
	PARTICULES_PM25: { name: 'PM 2.5', type: 'airQuality' },
	PARTICULES_PM10: { name: 'PM 10', type: 'airQuality' },
	NOISE: { name: 'LAeq,T', type: 'airQuality' },
	PRESSURE: { name: 'Pressão', type: 'airQuality' },
	METEOWINDSPEED: { name: 'Velocidade do vento', type: 'meteorology' },
	METEOWINDDIRECTION: { name: 'Direção do vento', type: 'meteorology' },
	METEOTEMPERATURE: { name: 'Temperatura', type: 'meteorology' },
	METEOHUMIDITY: { name: 'Humidade', type: 'meteorology' },
	METEOPRESSURE: { name: 'Pressão', type: 'meteorology' },
	METEOPRECIPITATION: { name: 'Precipitação', type: 'meteorology' },
	CO2: { name: 'CO2', type: 'airQuality' },
	VOC: { name: 'VOC', type: 'airQuality' },
	UVINDEX: { name: 'UV Index', type: 'airQuality' },
	UVPAR: { name: 'UV Radiação Total', type: 'airQuality' },
};

function formatSensorValue(sensor, measurement) {
	const { visiblePreview, conversionFactor, value, unit } = measurement;

	switch (sensor) {
		case 'CO':
			return conversionFactor
				? (value * conversionFactor.unitFactor).toFixed(2)
				: parseFloat(value).toFixed(2);
		case 'NOISE':
			return conversionFactor
				? (value * conversionFactor.unitFactor).toFixed(1)
				: parseFloat(value).toFixed(1);
		default:
			return conversionFactor
				? (value * conversionFactor.unitFactor).toFixed()
				: sensor.name === sensors.METEOWINDDIRECTION.name
				? `${degreesToCardinal(value)} ${value}`
				: parseFloat(value).toFixed();
	}
}

export function formatMeasurement(measurement, hourMeasurement) {
	/**
	 * Object wich will be changed and them merged with the
	 * given measurement
	 */
	const newValues = {
		icon: {},
		airQualityIndexLevel: null,
		sensors: {
			airQuality: [],
			meteorology: [],
		},
	};

	for (const item in measurement) {
		const sensor = sensors[item];
		const { visiblePreview, conversionFactor, value, unit } = measurement[item];

		const hourMeasurementSensor = hourMeasurement?.find(val => val.sensor === item);

		if (visiblePreview && sensor) {
			newValues.sensors[sensor.type].push({
				name: sensor.name,
				field: item,
				unit: conversionFactor ? conversionFactor.unit : unit,
				last: {
					value: formatSensorValue(item, measurement[item]),
				},
				hour: {
					value: hourMeasurementSensor?.value ? formatSensorValue(item, hourMeasurementSensor) : 0,
				},
			});
		}
	}

	/**
	 * Changes the airQualityIndexLevel to the pt-PT description
	 * and the icon based in the given value
	 */
	switch (measurement.airQualityIndexLevel) {
		case 1:
			newValues.icon = badIcon;
			newValues.airQualityIndexLevel = 'Mau';
			break;

		case 2:
			newValues.icon = weakIcon;
			newValues.airQualityIndexLevel = 'Fraco';
			break;

		case 3:
			newValues.icon = averageIcon;
			newValues.airQualityIndexLevel = 'Médio';
			break;

		case 4:
			newValues.icon = goodIcon;
			newValues.airQualityIndexLevel = 'Bom';
			break;

		case 5:
			newValues.icon = greatIcon;
			newValues.airQualityIndexLevel = 'Muito Bom';
			break;

		default:
			newValues.icon = weakIcon;
			newValues.airQualityIndexLevel = 'N/A';
			break;
	}

	return { ...measurement, ...newValues };
}
