<section class="qrt_popup__section" *ngIf="readyToShow">
	<header class="qrt_popup__header">
		<h1 class="qrt_popup__title">{{ location.Description }}</h1>
		<p class="qrt_popup__desc">
			<span class="qrt_popup__address"
				>{{ location.Address }}, <span>{{ location.PostalCode }}</span></span
			>
			<span class="qrt_popup__latlong"
				><span>{{ location.LatLong.lat }}</span>,
				<span>{{ location.LatLong.lng }}</span></span
			>
		</p>
	</header>
	<hr class="qrt_popup__divider" />
	<div class="qrt_popup__boxinfo">
		<h6 class="qrt_popup__list-title">Informações</h6>
		<ul class="qrt_popup__items-list">
			<li
				*ngIf="env.aqiVisible"
				class="qrt_popup__item"
				[ngClass]="{
					qrt_popup__greenish: measurements.airQualityIndexLevel === 'Bom',
					qrt_popup__green: measurements.airQualityIndexLevel === 'Muito Bom',
					qrt_popup__yellow: measurements.airQualityIndexLevel === 'Médio',
					qrt_popup__orange: measurements.airQualityIndexLevel === 'Fraco',
					qrt_popup__red: measurements.airQualityIndexLevel === 'Mau'
				}"
			>
				<ng-container *ngIf="location?.AQI != false">
					<strong>Qualidade do Ar (24h):</strong
					><span>
						{{ measurements.airQualityIndexLevel }} - {{
						measurements.airQualityIndexPollutant }}</span
					></ng-container
				>
			</li>
			<li class="qrt_popup__item">
				<strong>DUI: </strong><span>{{ device.DUI }}</span>
			</li>
			<li class="qrt_popup__item">
				<strong>Instalação: </strong>
				<span>{{ device.SetupDate | date: 'dd/MM/yyyy' }}</span>
			</li>
			<li *ngIf="device.CalibrationDate" class="qrt_popup__item">
				<strong>Calibração: </strong
				><span> {{ device.CalibrationDate | date: 'dd/MM/yyyy' }}</span>
			</li>
			<li *ngIf="isAdmin$ | async" class="qrt_popup__item">
				<strong>Ultima leitura: </strong
				><span> {{ measurements.dateObserved | date: 'short' }}</span>
			</li>			
			<li *ngIf="isAdmin$ | async" class="qrt_popup__item">
				<strong>Bateria: </strong
				><span> {{ measurements.BATTERYVOLTAGE.value }} Volts</span>
			</li>
			<li *ngIf="isAdmin$ | async" class="qrt_popup__item">
				<strong>Sinal GSM: </strong
				><span> {{ measurements.GSMSIGNAL.value }}</span>
			</li>
		</ul>
		<a
			[routerLink]="['/devices/details', location.LUI]"
			class="btn btn-link btn-block"
			>Detalhes</a
		>
	</div>
	<hr class="qrt_popup__divider" />
	<div *ngIf="!(meteoActivated$ | async)">
		<h6 class="qrt_popup__list-title">Sensores</h6>
		<p
			class="qrt_popup__nonmeteo"
			*ngIf="!measurements.sensors.airQuality.length"
		>
			Não dispõe de dados de qualidade do ar
		</p>
		<div
			class="qrt_popup__avg-info"
			*ngIf="measurements.sensors.airQuality.length"
		>
			<span>Última recepção</span>
			<span>Média (1h)</span>
		</div>
		<ul class="qrt_popup__items-list" aria-label="Sensores de qualidade do ar">
			<li
				class="qrt_popup__item-sensor"
				*ngFor="let airQualitySensor of measurements.sensors.airQuality"
			>
				<div
					class="qrt_popup__ind-color"
					[style.background-color]="getIndColor(airQualitySensor.field, airQualitySensor.last.value)"
				></div>

				<strong>{{ airQualitySensor.name }}:</strong>
				<span
					>{{ airQualitySensor.last.value }} {{ airQualitySensor.unit }}</span
				>
				<span
					>{{ airQualitySensor.hour.value }} {{ airQualitySensor.unit }}</span
				>
			</li>
		</ul>
	</div>

	<div *ngIf="meteoActivated$ | async">
		<h6 class="qrt_popup__list-title">Dados meteorológicos</h6>
		<p
			class="qrt_popup__nonmeteo"
			*ngIf="!device.MeteoStation"
			data-testid="no-meteo-measurements"
		>
			Não dispõe de dados meteorológicos
		</p>
		<div class="qrt_popup__avg-info" *ngIf="device.MeteoStation">
			<span>Última recepção</span>
			<span>Média (1h)</span>
		</div>
		<ng-container *ngIf="device.MeteoStation">
			<ul class="qrt_popup__items-list" aria-label="Sensores meteorológicos">
				<li
					class="qrt_popup__item-sensor"
					*ngFor="let meteorologySensor of measurements.sensors.meteorology"
				>
					<strong>{{ meteorologySensor.name }}:</strong>
					<span
						>{{ meteorologySensor.last.value }} {{ meteorologySensor.unit
						}}</span
					>
					<span
						>{{ meteorologySensor.hour.value }} {{ meteorologySensor.unit
						}}</span
					>
				</li>
			</ul>
		</ng-container>
	</div>
	<p *ngIf="location.Note as note" class="qrt_popup__note">{{ note }}</p>
</section>
