import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { UserData, User } from 'app/auth/models/user';
import { NotificationPreferences } from 'app/auth/models/notification';

@Injectable({
	providedIn: 'root',
})
export class PreferencesService {
	constructor(private http: HttpClient) {}

	public updateUser(data: object): Observable<User> {
		return this.http.patch<User>(`${environment.api}/users/me`, data);
	}

	changePassword(oldPassword: string, newPassword) {
		return this.http.post(`${environment.api}/auth/change-password`, {
			oldPassword,
			newPassword,
		});
	}

	updateUserPreferences$(data: any): Observable<User> {
		return this.http.patch<User>(`${environment.api}/users/preferences`, data);
	}

	updateNotificationPreferences$(notificationPreferences): Observable<User> {
		return this.http.patch<User>(`${environment.api}/users/preferences`, {
			notification: notificationPreferences,
		});
	}
}
