import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgrxFormsModule } from 'ngrx-forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ClarityModule, ClrForm } from '@clr/angular';

import {
	AuthEffects,
	UsersEffetcs,
	NotificationEffects,
	PreferencesEffects,
} from 'app/auth/effects';
import * as fromAuth from 'app/auth/reducers';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LoginCascaisComponent } from './components/logincascais/logincascais.component';
import { LoginAlticeComponent } from './components/loginaltice/loginaltice.component';
import { LoginLisboaComponent } from './components/loginlisboa/loginlisboa.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { PersonalDataComponent } from './components/personal-data/personal-data.component';

import { TokenInterceptor } from 'app/interceptors/token.interceptor';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { CreateNotificationComponent } from './components/create-notification/create-notification.component';
import { NotificationPreferencesComponent } from './components/notification-preferences/notification-preferences.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { ChangeDisabledStatusComponent } from './components/change-disabled-status/change-disabled-status.component';

@NgModule({
	declarations: [
		LoginComponent,
		LoginCascaisComponent,
		LoginAlticeComponent,
		LoginLisboaComponent,
		PreferencesComponent,
		PersonalDataComponent,
		ChangePasswordComponent,
		ManageUsersComponent,
		CreateNotificationComponent,
		NotificationPreferencesComponent,
		ResetPasswordComponent,
		RequestPasswordResetComponent,
		ChangeDisabledStatusComponent,
	],
	imports: [
		CommonModule,
		AuthRoutingModule,
		SharedModule,
		ReactiveFormsModule,
		NgrxFormsModule,
		CoreModule,
		StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
		EffectsModule.forFeature([
			AuthEffects,
			UsersEffetcs,
			NotificationEffects,
			PreferencesEffects,
		]),
		ClarityModule,
	],
})
export class AuthModule {}
