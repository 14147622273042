import { createReducer, on } from '@ngrx/store';
import { PreferencesActions } from '../actions';
import { produce } from 'immer';

import { UserPreferences } from 'app/auth/models/user';

export const preferencesFeatureKey = 'preferences';

export interface State {
	meteorology: boolean;
	airQuality: boolean;
	userPreferences: UserPreferences;
}

export const initialState: State = {
	meteorology: false,
	airQuality: true,
	userPreferences: { notification: null },
};

export const reducer = createReducer(
	initialState,
	on(PreferencesActions.meteoPreference, (state, { activated }) => {
		return produce(state, draft => {
			draft.meteorology = activated;
			draft.airQuality = !activated;
		});
	}),
	on(PreferencesActions.loadUserPreferences, (state, userPreferences) =>
		produce(state, draft => {
			draft.userPreferences = userPreferences;
		})
	)
);

export const getMeteo = (state: State) => state.meteorology;
export const getAirQuality = (state: State) => state.airQuality;
export const getNotificationPreferences = (state: State) =>
	state.userPreferences.notification;
