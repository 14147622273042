<button
	type="button"
	class="btn btn-icon btn-warning"
	aria-label="Remover alerta"
	(click)="toggleModal()"
>
	<clr-icon shape="trash"></clr-icon>
</button>

<clr-modal [(clrModalOpen)]="open">
	<h3 class="modal-title">Você tem certeza que deseja remover este alerta?</h3>
	<div class="modal-footer">
		<div class="qrt_delete-alert__btns">
			<button class="btn btn-primary" (click)="deleteAlert()">Confirmar</button>
			<button class="btn" (click)="closeModal()">Cancelar</button>
		</div>
	</div>
</clr-modal>
