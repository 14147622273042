import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as SensorActions from 'app/devices/actions/sensor.actions';
import { SensorService } from 'app/devices/services/sensor.service';

@Injectable()
export class SensorEffects {
	loadSensors$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SensorActions.loadSensors),
			switchMap(() =>
				this.sensorService.getAll$().pipe(
					map(sensors => SensorActions.loadSensorsSuccess({ sensors })),
					catchError(error => of(SensorActions.loadSensorsFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private sensorService: SensorService
	) {}
}
