import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDevices from 'app/devices/reducers';
import * as fromDeviceAlert from 'app/devices/reducers/device-alert.reducer';

export const selectDeviceAlertEntitiesState = createSelector(
	fromDevices.selectDevicesState,
	state => state.deviceAlerts
);

export const selectDeviceAlertsLoaded = createSelector(
	selectDeviceAlertEntitiesState,
	state => state.loaded
);

export const selectAllDeviceAlerts = createSelector(
	selectDeviceAlertEntitiesState,
	fromDeviceAlert.selectAll
);

export const selectDeviceAlerts = createSelector(
	selectAllDeviceAlerts,
	(deviceAlerts, props) =>
		deviceAlerts?.filter(alert => alert.DUI === props.DUI)
);

export const selectDeviceAlert = createSelector(
	selectAllDeviceAlerts,
	(deviceAlerts, props) => deviceAlerts.find(alert => alert._id === props.id)
);
