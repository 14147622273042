import { createReducer, on } from '@ngrx/store';
import {
	FormGroupState,
	createFormGroupState,
	formGroupReducer,
	updateGroup,
	wrapReducerWithFormStateUpdate,
	validate,
	onNgrxForms,
} from 'ngrx-forms';
import { required, minLength, maxLength } from 'ngrx-forms/validation';
import { StatusActions } from 'app/auth/actions';
import { produce } from 'immer';
import { UserData } from 'app/auth/models/user';
import { Notification } from 'app/core/models/notification';
import { NotificationActions } from 'app/auth/actions';

export const statusFeatureKey = 'status';
export const resetPasswordFormId = 'reset_password';
export const requestPasswordResetId = 'request_password_reset';

export interface ResetPasswordForm {
	password: string;
	passwordConfirmation: string;
}

export interface RequestPasswordResetForm {
	username: string;
}

export interface State {
	userData: UserData | null;
	notifications: Notification[] | null;
	hasError: boolean;
	loaded: boolean;
	resetPasswordForm: FormGroupState<ResetPasswordForm>;
	resetPasswordStatus: 'initial' | 'success' | 'failure';
	requestPasswordResetForm: FormGroupState<RequestPasswordResetForm>;
	requestPasswordResetStatus: 'initial' | 'success' | 'failure';
}

const initialResetPasswordFormState = createFormGroupState<ResetPasswordForm>(
	resetPasswordFormId,
	{
		password: '',
		passwordConfirmation: '',
	}
);

const initialRequestPasswordResetFormState = createFormGroupState<
	RequestPasswordResetForm
>(requestPasswordResetId, {
	username: '',
});

export const initialState: State = {
	userData: null,
	notifications: null,
	hasError: false,
	loaded: false,
	resetPasswordForm: initialResetPasswordFormState,
	resetPasswordStatus: 'initial',
	requestPasswordResetForm: initialRequestPasswordResetFormState,
	requestPasswordResetStatus: 'initial',
};

const validateResetPasswordForm = updateGroup<ResetPasswordForm>({
	password: validate(required, minLength(6), maxLength(65)),
	passwordConfirmation: validate(required, minLength(6), maxLength(65)),
});

export const rawReducer = createReducer(
	initialState,
	onNgrxForms(),
	on(StatusActions.loginSuccess, (state, { userData }) => {
		return produce(state, draft => {
			draft.userData = userData;
		});
	}),
	on(StatusActions.loginFailure, state =>
		produce(state, draft => {
			draft.hasError = true;
		})
	),
	on(StatusActions.loadUserDataSuccess, (state, { userData }) =>
		produce(state, draft => {
			draft.userData = userData;
			draft.loaded = true;
		})
	),
	on(StatusActions.updateUserSuccess, (state, { user }) =>
		produce(state, draft => {
			draft.userData.user = user;
		})
	),
	on(StatusActions.loadNotificationsSuccess, (state, { notifications }) =>
		produce(state, draft => {
			draft.notifications = notifications;
		})
	),
	on(NotificationActions.markAsReadSuccess, (state, { unreadNotifications }) =>
		produce(state, draft => {
			draft.notifications = unreadNotifications;
		})
	),
	on(StatusActions.submitResetPassword, state =>
		produce(state, draft => {
			draft.resetPasswordStatus = 'initial';
		})
	),
	on(StatusActions.resetPasswordSuccess, state =>
		produce(state, draft => {
			draft.resetPasswordStatus = 'success';
		})
	),
	on(StatusActions.resetPasswordFailure, state =>
		produce(state, draft => {
			draft.resetPasswordStatus = 'failure';
		})
	),
	on(StatusActions.submitRequestPasswordReset, state =>
		produce(state, draft => {
			draft.requestPasswordResetStatus = 'initial';
		})
	),
	on(StatusActions.requestPasswordResetSuccess, state =>
		produce(state, draft => {
			draft.requestPasswordResetStatus = 'success';
		})
	),
	on(StatusActions.requestPasswordResetFailure, state =>
		produce(state, draft => {
			draft.requestPasswordResetStatus = 'failure';
		})
	),
	on(StatusActions.login, () => initialState),
	on(StatusActions.logout, () => initialState)
);

export const reducer = wrapReducerWithFormStateUpdate(
	rawReducer,
	s => s.resetPasswordForm,
	validateResetPasswordForm
);

export const getError = (state: State) => state.hasError;
export const getUserData = (state: State) => state.userData;
export const getLoaded = (state: State) => state.loaded;
export const getNotifications = (state: State) => state.notifications;
export const getResetPasswordForm = (state: State) => state.resetPasswordForm;
export const getResetPasswordStatus = (state: State) => state.resetPasswordStatus;
export const getRequestPasswordResetForm = (state: State) =>
	state.requestPasswordResetForm;
export const getRequestPasswordResetStatus = (state: State) =>
	state.requestPasswordResetStatus;
