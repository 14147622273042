<div
	class="qrt_login"
	[ngClass]="{ 'qrt_login--has-error': hasError$ | async }"
>
	<form class="qrt_login__form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
		<img class="qrt_login__logo" src="assets/img/big_logo.png" />
		<h1 class="clr-form qrt_login__title">Acesso</h1>

		<div class="qrt_login__group">
			<div class="qrt_login__input-container">
				<label class="screen-reader-only" for="username"
					>Nome de utilizador</label
				>
				<input
					class="clr-input qrt_login__input"
					type="text"
					name="username"
					id="username"
					formControlName="username"
					placeholder="Nome de utilizador"
				/>
			</div>
			<div class="qrt_login__input-container">
				<label class="screen-reader-only" for="password">Palavra-passe</label>
				<input
					class="clr-input qrt_login__input"
					type="password"
					name="password"
					id="password"
					formControlName="password"
					placeholder="Palavra-passe"
				/>
			</div>

			<div
				class="qrt_login__error"
				*ngIf="hasError$ | async"
				aria-live="polite"
			>
				<clr-alert [clrAlertSizeSmall]="true" [clrAlertType]="'warning'">
					<clr-alert-item>
						<span class="alert-text" role="alert">
							Nome de utilizador ou palavra passe incorrectos.
						</span>
					</clr-alert-item>
				</clr-alert>
			</div>

			<clr-alert
				class="qrt_login__pw-success-alert"
				[clrAlertType]="'success'"
				*ngIf="changePwSuccess$ | async"
			>
				<clr-alert-item>
					<span class="alert-text"> Palavra passe alterada com sucesso. </span>
				</clr-alert-item>
			</clr-alert>

			<button
				type="submit"
				class="btn btn-primary qrt_login__submit-btn"
				[disabled]="loginForm.invalid"
			>
				Entrar
			</button>

			<qrt-request-password-reset></qrt-request-password-reset>
		</div>
	</form>
</div>
