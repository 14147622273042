import { Component, OnInit } from '@angular/core';
import { Observable, empty, of } from 'rxjs';
import { map, tap, filter, withLatestFrom, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import dayjs from 'dayjs';

import * as fromDevices from 'app/devices/reducers';
import * as SensorSelectors from 'app/devices/selectors/sensor.selectors';
import * as measurementHelper from 'app/shared/helpers/measurement';

@Component({
	selector: 'qrt-measurements-table',
	templateUrl: './measurements-table.component.html',
	styleUrls: ['./measurements-table.component.scss'],
})
export class MeasurementsTableComponent implements OnInit {
	measurements$: Observable<any>;
	loaded$: Observable<boolean>;

	sensors = measurementHelper.sensors;

	constructor(private store$: Store<any>) {
		this.measurements$ = store$.pipe(
			select(fromDevices.selectAllMeasurements),
			filter((measurements) => !!measurements),
			map((measurements) =>
				this.filterOutNonSensors(this.sensors, measurements)
			),
			map(this.mergeSensorsByDate),
			catchError((error) => of(empty))
		);

		this.loaded$ = store$.pipe(select(fromDevices.selectMeasurementsLoaded));
	}

	ngOnInit(): void {}

	filterOutNonSensors(sensors: any, measurements: any): any {
		const filteredMeasurements = measurements.map((measurement, index) => {
			const newMeasurement = {};

			const filteredSensors = Object.keys(measurement)
				.filter((key) => Object.keys(sensors).some((sensor) => sensor === key))
				.sort();

			filteredSensors.forEach((sensor) => {
				if (measurement[sensor].visibleDetails) {
					newMeasurement[sensor] = measurement[sensor];
				}
			});

			return {
				dateObserved: measurement.dateObserved,
				sensors: newMeasurement,
			};
		});

		const keys = filteredMeasurements.reduce((accumulator, currentValue) => {
			Object.keys(currentValue.sensors).forEach((sensor) => {
				if (!accumulator.some((key) => key === sensor)) {
					accumulator.push(sensor);
				}
			});

			return accumulator;
		}, []);

		return { keys, measurements: filteredMeasurements };
	}

	mergeSensorsByDate({ keys, measurements }): any {
		const newMeasurements = {};

		measurements.forEach((measurement) => {
			newMeasurements[measurement.dateObserved] = measurement.sensors;
		});

		return { sensors: keys, data: newMeasurements };
	}

	applyConversionFactor$(sensor, value, configuration) {
		return this.store$.pipe(
			select(SensorSelectors.selectSensorConversionFactors, {
				configuration,
			}),
			map((conversionFactorsBySensor) => {
				const conversionFactor = conversionFactorsBySensor[sensor.apiFieldName];

				return conversionFactor
					? (value * conversionFactor.unitFactor).toFixed(2)
					: value;
			}),
			catchError((error) => of(0))
		);
	}

	getSensorPortugueseName(key: string): string {
		return this.sensors[key]?.name || key;
	}

	getSensorUnit$(name: string, configuration: string) {
		return this.store$.pipe(
			select(SensorSelectors.selectSensorUnitByConfiguration, {
				name,
				configuration,
			}),
			catchError((error) => of(''))
		);
	}
}
