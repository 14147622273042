<button
	class="btn btn-icon"
	aria-label="Criar novo alerta"
	(click)="openModal()"
>
	<clr-icon shape="plus"></clr-icon>
	Criar
</button>

<clr-modal [(clrModalOpen)]="open" clrModalSize="lg">
	<h3 class="modal-title">Criar novo alerta</h3>
	<div class="modal-body">
		<form clrStepper [formGroup]="createAlertForm" (ngSubmit)="createAlert()">
			<clr-stepper-panel formGroupName="boxinfo" class="qrt_boxinfo-panel">
				<clr-step-title>Dispositivo</clr-step-title>
				<clr-step-description>Informações do dispositivo</clr-step-description>
				<clr-step-content *clrIfExpanded>
					<clr-input-container>
						<label>Etiqueta</label>
						<input
							clrInput
							placeholder="Alerta para o sensor..."
							formControlName="label"
						/>
					</clr-input-container>
					<clr-select-container *ngIf="boxes$ | async as boxes">
						<label for="select-dui" class="clr-control-label"
							>Dispositivo</label
						>
						<select
							formControlName="dui"
							clrSelect
							id="select-dui"
							class="clr-select"
							data-testid="selectDevice"
							(change)="selectBox(selectedDui)"
						>
							<option *ngFor="let box of boxes" [value]="box.DUI">
								{{ box.currentLUI }} - {{ box.Description || box.Address ||
								box.Locality }}
							</option>
						</select>
						<clr-control-error
							>É preciso informar um dispositivo.</clr-control-error
						>
					</clr-select-container>

					<clr-select-container *ngIf="selectedBoxSensors$ | async as sensors">
						<label for="select-sensor" class="clr-control-label">Sensor</label>
						<select
							formControlName="sensor"
							clrSelect
							id="select-sensor"
							class="clr-select"
							data-testid="selectSensor"
						>
							<option
								*ngFor="let sensor of sensors"
								[value]="sensor.apiFieldName"
							>
								{{ getSensorPTName(sensor.apiFieldName) }}
							</option>
						</select>
						<clr-control-error>É preciso informar um sensor.</clr-control-error>
					</clr-select-container>
					<button clrStepButton="next">Próximo</button>
				</clr-step-content>
			</clr-stepper-panel>

			<clr-stepper-panel formGroupName="condition">
				<clr-step-title>Condição</clr-step-title>
				<clr-step-description>Quando ativar alerta</clr-step-description>
				<clr-step-content *clrIfExpanded>
					<div class="qrt_alert-condition">
						<span
							>Quando <strong>{{ getSensorPTName(selectedSensor) }}</strong> for
						</span>
						<div class="qrt_alert-condition__select-operator">
							<clr-select-container *ngIf="operators">
								<label
									for="select-operator"
									class="clr-control-label screen-reader-only"
									>Operação</label
								>
								<select
									formControlName="operator"
									clrSelect
									id="select-operator"
									class="clr-select"
									data-testid="selectOperator"
								>
									<option
										*ngFor="let operator of operators | keyvalue"
										[value]="operator.key"
									>
										{{ operator.value }}
									</option>
								</select>
								<clr-control-error
									>É preciso informar operação.</clr-control-error
								>
							</clr-select-container>
						</div>
						<div class="qrt_alert-condition__unit-block">
							<span>a</span>
							<div class="qrt_alert-condition__value">
								<clr-input-container>
									<label class="screen-reader-only">Valor</label>
									<input
										clrInput
										type="number"
										placeholder="0"
										formControlName="value"
									/>
								</clr-input-container>
							</div>
							<strong class="qrt_alert-condition__sensor-unit"
								>{{ getSelectedSensorUnit$(selectedDui, selectedSensor) | async
								}}</strong
							>
						</div>
						<div class="qrt_alert-condition__period-block">
							<span>no período de</span>
							<div class="qrt_alert-condition__select-period">
								<clr-select-container *ngIf="periods">
									<label
										for="select-period"
										class="clr-control-label screen-reader-only"
										>Período</label
									>
									<select
										formControlName="period"
										clrSelect
										id="select-period"
										class="clr-select"
										data-testid="selectPeriod"
									>
										<option
											*ngFor="let period of periods | keyvalue"
											[value]="period.key"
										>
											{{ period.value }}
										</option>
									</select>
									<clr-control-error
										>É preciso informar período.</clr-control-error
									>
								</clr-select-container>
							</div>
						</div>
					</div>
					<button clrStepButton="next">Próximo</button>
				</clr-step-content>
			</clr-stepper-panel>

			<clr-stepper-panel formGroupName="action">
				<clr-step-title>Ação</clr-step-title>
				<clr-step-description>Ação a ser tomada</clr-step-description>
				<clr-step-content *clrIfExpanded>
					<clr-toggle-container>
						<clr-toggle-wrapper>
							<label>Receber alerta por SMS</label>
							<input type="checkbox" clrToggle formControlName="alertBySms" />
						</clr-toggle-wrapper>
					</clr-toggle-container>

					<clr-toggle-container>
						<clr-toggle-wrapper>
							<label>Receber alerta por E-mail</label>
							<input type="checkbox" clrToggle formControlName="alertByEmail" />
						</clr-toggle-wrapper>
					</clr-toggle-container>

					<div class="webhooks_container">
						<clr-toggle-container>
							<clr-toggle-wrapper>
								<label>Webhook URL</label>
								<input type="checkbox" clrToggle formControlName="callback" />
							</clr-toggle-wrapper>
						</clr-toggle-container>

						<clr-signpost>
							<clr-signpost-content *clrIfOpen>
								<h3>Exemplo</h3>
								<p>Corpo da requisição</p>
								<code> {{ exampleReqBody | json }} </code>
							</clr-signpost-content>
						</clr-signpost>
					</div>

					<ng-container *ngIf="callbackAction$ | async">
						<form [formGroup]="callbackForm">
							<clr-input-container>
								<label>URL</label>
								<input
									clrInput
									placeholder="https://api.example.com"
									name="url"
									formControlName="url"
								/>
							</clr-input-container>

							<clr-select-container>
								<label>Método</label>
								<select clrSelect name="method" formControlName="method">
									<option value="post">POST</option>
									<option value="get">GET</option>
									<option value="patch">PATCH</option>
								</select></clr-select-container
							>
						</form>
					</ng-container>

					<button
						[clrLoading]="createBtnState"
						clrStepButton="submit"
						[disabled]="(callbackAction$ | async) && callbackForm.invalid"
						aria-label="Concluir"
					>
						Criar
					</button>
				</clr-step-content>
			</clr-stepper-panel>
		</form>
	</div>
</clr-modal>
