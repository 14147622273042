import {
	createSelector,
	createFeatureSelector,
	Action,
	combineReducers,
} from '@ngrx/store';

import * as fromRoot from 'app/reducers';
import * as fromStatus from 'app/auth/reducers/status.reducer';
import * as fromPreferences from 'app/auth/reducers/preferences.reducer';
import * as fromUsers from 'app/auth/reducers/users.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
	[fromStatus.statusFeatureKey]: fromStatus.State;
	[fromPreferences.preferencesFeatureKey]: fromPreferences.State;
	[fromUsers.usersFeatureKey]: fromUsers.State;
}

export interface State extends fromRoot.State {
	[authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
	return combineReducers({
		[fromStatus.statusFeatureKey]: fromStatus.reducer,
		[fromPreferences.preferencesFeatureKey]: fromPreferences.reducer,
		[fromUsers.usersFeatureKey]: fromUsers.reducer,
	})(state, action);
}

export const selectAuthState = createFeatureSelector<State, AuthState>(
	authFeatureKey
);

export const selectUsers = createSelector(
	selectAuthState,
	state => state.users
);

export const selectUsersList = createSelector(
	selectUsers,
	fromUsers.getUsersList
);

export const selectSelectedUser = createSelector(
	selectUsers,
	fromUsers.getSelectedUser
);

export const selectAuthStatusState = createSelector(
	selectAuthState,
	state => state.status
);

export const selectResetPasswordForm = createSelector(
	selectAuthStatusState,
	fromStatus.getResetPasswordForm
);

export const selectResetPasswordStatus = createSelector(
	selectAuthStatusState,
	fromStatus.getResetPasswordStatus
);

export const selectRequestPasswordResetForm = createSelector(
	selectAuthStatusState,
	fromStatus.getRequestPasswordResetForm
);

export const selectRequestPasswordResetStatus = createSelector(
	selectAuthStatusState,
	fromStatus.getRequestPasswordResetStatus
);

export const selectUserData = createSelector(
	selectAuthStatusState,
	fromStatus.getUserData
);

export const selectUserInfo = createSelector(
	selectUserData,
	userData => userData?.user
);

export const selectIsAdmin = createSelector(
	selectUserInfo,
	userInfo => userInfo?.isAdmin
);

export const selectHasError = createSelector(
	selectAuthStatusState,
	fromStatus.getError
);

export const selectUserLoaded = createSelector(
	selectAuthStatusState,
	fromStatus.getLoaded
);

export const selectNotifications = createSelector(
	selectAuthStatusState,
	fromStatus.getNotifications
);

export const selectUnreadNotifications = createSelector(
	selectNotifications,
	notifications => notifications?.filter(notification => !notification.read)
);

export const selectCounterUnreadNotifications = createSelector(
	selectUnreadNotifications,
	notifications => notifications?.length
);

export const selectLoggedIn = createSelector(
	selectUserData,
	userData => !!userData && !!userData.id
);

export const selectToken = createSelector(
	selectUserData,
	userData => userData.id
);

// Preferences

export const selectAuthPreferencesState = createSelector(
	selectAuthState,
	state => state.preferences
);

export const selectMeteorology = createSelector(
	selectAuthPreferencesState,
	fromPreferences.getMeteo
);

export const selectAirQuality = createSelector(
	selectAuthPreferencesState,
	fromPreferences.getAirQuality
);

export const selectNotificationPreferences = createSelector(
	selectAuthPreferencesState,
	fromPreferences.getNotificationPreferences
);
