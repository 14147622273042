import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import * as fromAuth from 'app/auth/reducers';
import { StatusActions } from 'app/auth/actions';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private store: Store<fromAuth.State>, private router: Router) {}

	canActivate(): Observable<boolean> {
		return this.store.pipe(
			select(fromAuth.selectLoggedIn),
			map(authenticated => {
				if (!authenticated) {
					this.store.dispatch(StatusActions.loginRedirect());
					return false;
				}

				return true;
			}),
			take(1)
		);
	}
}