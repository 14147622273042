import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qrt-dust',
  templateUrl: './dust.component.html',
  styleUrls: ['./dust.component.css']
})
export class DustComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
