import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
	map,
	take,
	takeUntil,
	filter,
	reduce,
	withLatestFrom,
	tap,
	switchMap,
} from 'rxjs/operators';
import { produce } from 'immer';
import moment from 'moment';
import * as L from 'leaflet';

import * as fromDevices from 'app/devices/reducers';
import {
	BoxActions,
	MeasurementActions,
	OldestMeasurementActions,
	BoxPreferencesActions,
} from 'app/devices/actions';
import { Box, Sensor } from 'app/devices/models/box.model';
import { Measurement } from 'app/devices/models/measurement';
import { SensorsInfo } from 'app/devices/models/sensors-info';

import { isEmpty } from 'app/shared/helpers/lang';

@Component({
	selector: 'qrt-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
	boxesLoaded$: Observable<boolean>;
	selectedBox$: Observable<Box>;
	sensors$: Observable<Sensor[]>;
	sensors$ub: Subscription;
	allowedSensors: Sensor[];
	selectedSensorsToReport$: Observable<Array<string>>;
	filteredSensors$: Observable<Sensor[]>;
	sensorsInfo$: Observable<SensorsInfo>;
	measurements$: Observable<Array<any>>;
	measurements$ub: Subscription;
	selectedAvgType$: Observable<string>;
	measurementsData: object = {};
	measurementsStartDate: string;
	measurementsEndDate: string;

	public mapOptions = {
		layers: [
			L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: 'Powered by QART',
			}),
		],
		zoom: 12,
		zoomControl: false,
		scrollWheelZoom: false,
		dragging: false,
		center: L.latLng(39.399872, -8.224454),
	};

	public mapLayers = [];

	markerIcon = L.icon({
		iconSize: [25, 40],
		iconAnchor: [14, 40],
		iconUrl: 'assets/icons/marker-icon.png',
	});

	constructor(
		private route: ActivatedRoute,
		private store$: Store<fromDevices.State>
	) {
		this.boxesLoaded$ = store$.pipe(select(fromDevices.selectBoxesLoaded));
		this.selectedBox$ = store$.pipe(
			select(fromDevices.selectBoxByLui),
			filter(box => !!box)
		);
		this.sensors$ = store$.pipe(
			select(fromDevices.selectSelectedBoxSensorsDetailsAllowed),
			filter(sensors => !!sensors)
		);

		this.selectedSensorsToReport$ = store$.pipe(
			select(fromDevices.selectSensorsToReport)
		);

		this.sensorsInfo$ = store$.pipe(select(fromDevices.selectSensorsInfo));

		this.selectedAvgType$ = store$.pipe(
			select(fromDevices.selectSelectedAvgPeriodType)
		);

		this.measurements$ = this.store$.pipe(
			select(fromDevices.selectAllMeasurements),
			withLatestFrom(this.selectedBox$, this.sensors$),
			filter(([measurements]) => !isEmpty(measurements)),
			map(([measurements, box, sensors]: [Measurement[], Box, Sensor[]]) =>
				measurements.map(measurement =>
					sensors.map(sensor => ({
						...produce(measurement[sensor.apiFieldName], draft => {
							const conversionFactor = sensor.conversionFactor;

							if (conversionFactor) {
								draft.value =
									Math.round(
										draft.value * conversionFactor[0].unitFactor * 100
									) / 100;
								draft.unit = conversionFactor[0].unit;
							}
						}),
						dateObserved: measurement.dateObserved,
					}))
				)
			)
		);

		this.filteredSensors$ = this.sensors$.pipe(
			withLatestFrom(this.selectedSensorsToReport$),
			map(([allSensors, sensorsToReport]) => {
				if (isEmpty(sensorsToReport)) {
					return allSensors;
				} else {
					const filteredSensors = sensorsToReport.map(sensor =>
						allSensors.filter(
							commonSensor => commonSensor.apiFieldName === sensor
						)
					);

					return [].concat(...filteredSensors);
				}
			})
		);
	}

	ngOnInit() {
		this.store$.dispatch(BoxActions.loadSensorsInfo());

		this.boxesLoaded$
			.pipe(
				filter(loaded => !loaded),
				take(1)
			)
			.subscribe(notLoaded => {
				this.store$.dispatch(BoxActions.loadBoxes());
			});

		this.route.params.pipe(take(1)).subscribe(params => {
			const { lui, year } = params;
			/**
			 * This way we can accept the month as 3 or 03
			 */
			const month =
				params.month < 10 && !params.month.startsWith('0')
					? `0${params.month}`
					: params.month;

			this.store$.dispatch(BoxActions.selectBoxLui({ lui: Number(lui) }));

			const startDate = moment(
				`${year}-${month}-01T00:00:00.000Z`
			).toISOString();
			const endDate = moment(startDate).endOf('month').toISOString();

			this.measurementsStartDate = startDate;
			this.measurementsEndDate = endDate;
		});

		this.selectedBox$
			.pipe(
				withLatestFrom(this.store$.select(fromDevices.selectAllLocations)),
				map(([device, locations]) => {
					const loc = locations.find(
						location => location.LUI === device.currentLUI
					);

					return [device, loc];
				}),
				take(1)
			)
			.subscribe(([box, loc]: [any, any]) => {
				this.store$.dispatch(BoxActions.selectBox({ id: box.DUI }));

				const { LatLong } = loc;

				this.mapLayers.push(
					L.marker([LatLong.lat, LatLong.lng], { icon: this.markerIcon })
				);

				this.mapOptions.center = L.latLng(LatLong.lat, LatLong.lng);

				this.selectedAvgType$.pipe(take(1)).subscribe(avgType => {
					if (!(avgType === '1d' || avgType === '8h' || avgType === '1w')) {
						this.store$.dispatch(
							BoxPreferencesActions.selectAvgPeriod({
								avgPeriod: { name: 'Diário', avgType: '1d', chartType: 'bar' },
							})
						);
					}
				});

				this.store$.dispatch(
					MeasurementActions.loadMeasurements({
						cfg: {
							startDate: this.measurementsStartDate,
							endDate: this.measurementsEndDate,
						},
					})
				);
			});

		this.sensors$ub = this.sensors$.subscribe(
			sensors => (this.allowedSensors = sensors)
		);

		this.measurements$ub = this.measurements$.subscribe(measurements => {
			this.allowedSensors.forEach(sensor => {
				const nestedSensor = measurements.map(measurement =>
					measurement.filter(
						measurement => measurement.apiFieldName === sensor.apiFieldName
					)
				);

				const currentSensorValues = [].concat(...nestedSensor);

				const currentSensorAverage =
					currentSensorValues
						.map(val => val.value)
						.reduce((acc, curr) => acc + curr) / currentSensorValues.length;

				this.measurementsData = produce(this.measurementsData, draft => {
					draft[sensor.apiFieldName] = {
						values: currentSensorValues,
						average: Math.round(currentSensorAverage * 100) / 100,
						unit: currentSensorValues[0].unit,
					};
				});
			});
		});
	}

	ngOnDestroy() {
		this.measurements$ub.unsubscribe();
		this.sensors$ub.unsubscribe();
	}
}
