import {
	createSelector,
	createFeatureSelector,
	Action,
	combineReducers,
} from '@ngrx/store';

import { isEmpty } from 'app/shared/helpers/lang';

import * as fromRoot from 'app/reducers';
import * as fromBoxes from 'app/devices/reducers/box.reducer';
import * as fromMeasurements from 'app/devices/reducers/measurement.reducer';
import * as fromLastMeasurements from 'app/devices/reducers/lastmeasurement.reducer';
import * as fromOldestMeasurements from 'app/devices/reducers/oldestmeasurement.reducer';
import * as fromLocation from 'app/devices/reducers/location.reducer';
import * as fromDeviceAlert from 'app/devices/reducers/device-alert.reducer';
import { Measurement } from 'app/devices/models/measurement';

export const devicesFeatureKey = 'devices';

export interface DevicesState {
	[fromBoxes.boxesFeatureKey]: fromBoxes.State;
	[fromMeasurements.measurementsFeatureKey]: fromMeasurements.State;
	[fromLastMeasurements.lastMeasurementsFeatureKey]: fromLastMeasurements.State;
	[fromOldestMeasurements.oldestMeasurementsFeatureKey]: fromOldestMeasurements.State;
	[fromLocation.locationFeatureKey]: fromLocation.State;
	[fromDeviceAlert.deviceAlertsFeatureKey]: fromDeviceAlert.State;
}

export interface State extends fromRoot.State {
	[devicesFeatureKey]: DevicesState;
}

export function reducers(state: DevicesState | undefined, action: Action) {
	return combineReducers({
		[fromBoxes.boxesFeatureKey]: fromBoxes.reducer,
		[fromMeasurements.measurementsFeatureKey]: fromMeasurements.reducer,
		[fromLastMeasurements.lastMeasurementsFeatureKey]:
			fromLastMeasurements.reducer,
		[fromOldestMeasurements.oldestMeasurementsFeatureKey]:
			fromOldestMeasurements.reducer,
		[fromLocation.locationFeatureKey]: fromLocation.reducer,
		[fromDeviceAlert.deviceAlertsFeatureKey]: fromDeviceAlert.reducer,
	})(state, action);
}

export const selectDevicesState = createFeatureSelector<State, DevicesState>(
	devicesFeatureKey
);

//
// Box Entity
//
export const selectBoxEntitiesState = createSelector(
	selectDevicesState,
	(state) => state.boxes
);

export const selectBoxesLoaded = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getLoaded
);

export const selectBoxesLoading = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getLoading
);

export const selectDatesToReport = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getDatesToReport
);

export const selectBoxDiagnosis = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getDiagnosis
);

export const selectRequestingBoxDiagnosis = createSelector(
	selectBoxDiagnosis,
	(diagnosis) => diagnosis.requesting
);

export const selectBoxDiagnosisLoading = createSelector(
	selectBoxDiagnosis,
	(diagnosis) => diagnosis.loading
);

export const selectBoxDiagnosisData = createSelector(
	selectBoxDiagnosis,
	(diagnosis) => diagnosis.Data
);

export const selectYearToReport = createSelector(
	selectDatesToReport,
	(dates) => dates.year
);

export const selectMonthToReport = createSelector(
	selectDatesToReport,
	(dates) => dates.month
);

export const selectSensorsToReport = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getSensorsToReport
);

export const selectSensorsToReportLoaded = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getSensorsToReportLoaded
);

export const selectAllBoxesEntities = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectEntities
);

export const selectAllBoxes = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectAll
);

export const selectSelectedBoxId = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectId
);

export const selectSelectedBoxLui = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectLui
);

export const selectSensor = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getSelectedSensor
);

export const selectMeteoSensor = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getSelectMeteoSensor
);

export const selectSelectedAvgPeriod = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectAvgPeriod
);

export const selectSensorsInfo = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectSensorsInfo
);

export const selectSensorsInfoDisclaimer = createSelector(
	selectSensorsInfo,
	(infos) => infos?.DISCLAIMER
);

export const selectBoxByLui = createSelector(
	selectAllBoxes,
	selectSelectedBoxLui,
	(boxes, selectedLUI) =>
		!isEmpty(boxes)
			? boxes.filter((box) => box.currentLUI === selectedLUI)[0]
			: null
);

export const selectDUIByLUI = createSelector(
	selectAllBoxes,
	(devices, props) =>
		devices.find((device) => device.currentLUI === props.LUI)?.DUI
);

export const selectSelectedAvgPeriodType = createSelector(
	selectSelectedAvgPeriod,
	(avgPeriod) => avgPeriod?.avgType
);

export const selectSelectedBoxById = createSelector(
	selectAllBoxesEntities,
	selectSelectedBoxId,
	(entities, selectedId) => entities[selectedId]
);

export const selectSelectedBoxDataOpts = createSelector(
	selectSelectedBoxById,
	(selectedBox) => selectedBox?.viewDataOpts
);

export const selectSelectedBoxAvgPeriods = createSelector(
	selectSelectedBoxDataOpts,
	(dataOpts) => dataOpts?.avgPeriods
);

export const selectSelectedBoxConfig = createSelector(
	selectSelectedBoxById,
	(selectedBox) => selectedBox?.Config
);

export const selectSelectedBoxSensors = createSelector(
	selectSelectedBoxConfig,
	(boxConfig) => boxConfig?.Sensors || []
);

export const selectSelectedBoxMeteoSensors = createSelector(
	selectSelectedBoxConfig,
	(boxConfig) => boxConfig?.MeteoSensors || []
);

// Return only the sensors with truthy visibleDetails on DB
export const selectSelectedBoxSensorsDetailsAllowed = createSelector(
	selectSelectedBoxSensors,
	(sensors) => sensors?.filter((sensor) => sensor.visibleDetails)
);

export const selectSelectedBoxMeteoSensorsDetailsAllowed = createSelector(
	selectSelectedBoxMeteoSensors,
	(meteoSensors) => meteoSensors?.filter((sensor) => sensor.visibleDetails)
);

export const selectAllSensorsOfSelectedBox = createSelector(
	selectSelectedBoxSensorsDetailsAllowed,
	selectSelectedBoxMeteoSensorsDetailsAllowed,
	(sensors, meteoSensors) =>
		!sensors || !meteoSensors ? [] : [...sensors, ...meteoSensors]
);

export const selectSelectedSensor = createSelector(
	selectSelectedBoxSensors,
	selectSensor,
	(sensors, sensorfield) =>
		sensors?.find((sensor) => sensor.apiFieldName === sensorfield)
);

export const selectSelectedMeteoSensor = createSelector(
	selectSelectedBoxMeteoSensors,
	selectMeteoSensor,
	(meteoSensors, sensorfield) =>
		meteoSensors?.find((sensor) => sensor.apiFieldName === sensorfield)
);

export const selectSelectedSensorLimits = createSelector(
	selectSelectedSensor,
	(selectedSensor) => selectedSensor?.limits
);

export const selectSelectedSensorInfo = createSelector(
	selectSelectedSensor,
	selectSensorsInfo,
	(selectedSensor, sensorsInfo) => sensorsInfo[selectedSensor?.apiFieldName]
);

export const selectSelectedMeteoSensorInfo = createSelector(
	selectSelectedMeteoSensor,
	selectSensorsInfo,
	(selectedMeteoSensor, sensorsInfo) =>
		sensorsInfo[selectedMeteoSensor?.apiFieldName]
);

//
// Last Measurement Entity
//
export const selectLastMeasurementEntitiesState = createSelector(
	selectDevicesState,
	(state) => state.lastmeasurements
);

export const selectLastMeasurementsLoaded = createSelector(
	selectLastMeasurementEntitiesState,
	fromLastMeasurements.getLoaded
);

export const selectAllLastMeasurementsEntities = createSelector(
	selectLastMeasurementEntitiesState,
	fromLastMeasurements.selectEntities
);

export const selectAllLastMeasurements = createSelector(
	selectLastMeasurementEntitiesState,
	fromLastMeasurements.selectAll
);

export const selectSelectedBoxLastMeasurementDate = createSelector(
	selectAllLastMeasurementsEntities,
	selectSelectedBoxId,
	(lastMeasurements, boxId) => lastMeasurements[boxId]?.dateObserved
);

export const selectAvgIndexLevel = createSelector(
	selectAllLastMeasurements,
	(measurements): object | null => {
		if (measurements.length <= 0) {
			return null;
		}

		const devicesQty = measurements.length;

		const indexLevelList = measurements.map(
			(m) => m.airQualityIndexLevel
		);

		const reducer = (accumulator, currentValue) => accumulator + currentValue;

		const reducedAirQualityIndexLevel = indexLevelList.reduce(reducer);

		const finalValue = Math.round(reducedAirQualityIndexLevel / devicesQty);

		switch (finalValue) {
			case 1:
				return { desc: 'Mau', number: 1 };
			case 2:
				return { desc: 'Fraco', number: 2 };
			case 3:
				return { desc: 'Médio', number: 3 };
			case 4:
				return { desc: 'Bom', number: 4 };
			case 5:
				return { desc: 'Muito Bom', number: 5 };
			default:
				return { desc: 'N/A', number: 0 };
		}
	}
);

// Oldest Measurement Entity

export const selectOldestMeasurementEntitiesState = createSelector(
	selectDevicesState,
	(state) => state.oldestmeasurements
);

export const selectOldestMeasurementsLoaded = createSelector(
	selectOldestMeasurementEntitiesState,
	fromOldestMeasurements.getLoaded
);

export const selectAllOldMeasurementsEntities = createSelector(
	selectOldestMeasurementEntitiesState,
	fromOldestMeasurements.selectEntities
);

export const selectSelectedBoxOldestMeasurementDate = createSelector(
	selectAllOldMeasurementsEntities,
	selectSelectedBoxId,
	(oldestMeasurements, boxId) => oldestMeasurements[boxId]?.dateObserved
);

//
// Measurement Entity
//
export const selectMeasurementEntitiesState = createSelector(
	selectDevicesState,
	(state) => state.measurements
);

export const selectMeasurementsLoaded = createSelector(
	selectMeasurementEntitiesState,
	fromMeasurements.getLoaded
);

export const selectAllMeasurements = createSelector(
	selectMeasurementEntitiesState,
	(measurements) => measurements.data
);

export const selectMeasurementsLoading = createSelector(
	selectMeasurementEntitiesState,
	(measurements) => measurements.loading
);

// Main device feature selectors
export const isAllDeviceDataLoaded = createSelector(
	selectBoxesLoaded,
	selectLastMeasurementsLoaded,
	selectMeasurementsLoaded,
	(BoxesLoaded, LastMeasurementsLoaded, MeasurementsLoaded) => {
		return BoxesLoaded && LastMeasurementsLoaded && MeasurementsLoaded;
	}
);

export const isBoxesAndLastMeasurementsDataLoaded = createSelector(
	selectBoxesLoaded,
	selectLastMeasurementsLoaded,
	(BoxesLoaded, LastMeasurementsLoaded) => {
		return BoxesLoaded && LastMeasurementsLoaded;
	}
);

export const isLastAndOldestMeasurementsLoaded = createSelector(
	selectLastMeasurementsLoaded,
	selectOldestMeasurementsLoaded,
	(lastMeasurementsLoaded, oldestMeasurementsLoaded) =>
		lastMeasurementsLoaded && oldestMeasurementsLoaded
);

// Location feature
export const selectLocationEntitiesState = createSelector(
	selectDevicesState,
	(state) => state.locations
);

export const selectLocationLoading = createSelector(
	selectLocationEntitiesState,
	fromLocation.selectLoading
);

export const selectLocationLoaded = createSelector(
	selectLocationEntitiesState,
	fromLocation.selectLoaded
);

export const selectAllLocationEntities = createSelector(
	selectLocationEntitiesState,
	fromLocation.selectEntities
);

export const selectAllLocations = createSelector(
	selectLocationEntitiesState,
	fromLocation.selectAll
);
