<div
	class="qrt_measurements-controller"
	[ngClass]="{
		'qrt_measurements-controller--table-mode':
			showOnlyOnPresentationType$('table') | async,
		'qrt_measurements-controller--wind-rose-mode':
			showOnlyOnPresentationType$('wind-rose') | async,
		'qrt_measurements-controller--comparison-mode':
			showOnlyOnPresentationType$('chart-comparison') | async
	}"
>
	<div *ngIf="showOnlyOnPresentationType$('chart') | async">
		<ng-container *ngIf="airQualityActivated$ | async">
			<p>{{ (selectedSensor$ | async)?.fullName }}</p>
		</ng-container>
		<ng-container *ngIf="meteoActivated$ | async">
			<p>{{ (selectedMeteoSensor$ | async)?.name }}</p>
		</ng-container>
	</div>
	<div class="qrt_measurements-controller__chartoptions">
		<div>
			<ng-container *ngIf="showSelectSensor$ | async">
				<clr-dropdown *ngIf="meteoActivated$ | async; else selectSensor">
					<button class="btn btn-link" clrDropdownTrigger>
						{{ (selectedMeteoSensor$ | async)?.name }}
						<clr-icon shape="caret down"></clr-icon>
					</button>
					<clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
						<h4 class="dropdown-header">Selecionar dados meteorológicos</h4>
						<a
							*ngFor="let meteo of meteoSensors$ | async"
							clrDropdownItem
							(click)="changeSelectedMeteoSensor(meteo.apiFieldName)"
						>
							{{ meteo.name }}
						</a>
					</clr-dropdown-menu>
				</clr-dropdown>

				<ng-template #selectSensor>
					<clr-dropdown class="qrt_measurements-controller__sensors">
						<button class="btn btn-link" clrDropdownTrigger>
							{{ (selectedSensor$ | async)?.name }}
							<clr-icon shape="caret down"></clr-icon>
						</button>
						<clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
							<h4 class="dropdown-header">Selecionar sensor</h4>
							<a
								*ngFor="let sensor of sensors$ | async"
								clrDropdownItem
								(click)="changeSelectedSensor(sensor.apiFieldName)"
							>
								{{ sensor.name }}
							</a>
						</clr-dropdown-menu>
					</clr-dropdown>
				</ng-template>
			</ng-container>

			<clr-dropdown
				class="qrt_measurements-controller__avgperiods"
				*ngIf="datePickerFormValue$ | async as datePickerFormValue"
			>
				<button
					class="btn btn-link"
					clrDropdownTrigger
					aria-label="Selecionar período"
				>
					{{ (selectedAvgPeriod$ | async)?.name }}
					<clr-icon shape="caret down"></clr-icon>
				</button>
				<clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
					<h4 class="dropdown-header">Selecionar período</h4>
					<button
						*ngFor="let period of avgPeriods$ | async"
						clrDropdownItem
						(click)="changeSelectedAvgPeriodType(period)"
						[disabled]="invalidPeriod(period, datePickerFormValue)"
					>
						{{ period.name }}
					</button>
				</clr-dropdown-menu>
			</clr-dropdown>
		</div>

		<form
			class="qrt_measurements-controller__datepickers"
			novalidate
			ngNoForm
			clrForm
			[ngrxFormState]="datePickerForm$ | async"
		>
			<clr-date-container>
				<label>Início</label>
				<input
					type="date"
					clrDate
					[ngrxFormControlState]="(datePickerForm$ | async).controls.startDate"
					(clrDateChange)="startDateChange($event)"
				/>
			</clr-date-container>
			<clr-date-container>
				<label>Fim</label>
				<input
					type="date"
					clrDate
					[ngrxFormControlState]="(datePickerForm$ | async).controls.endDate"
					(clrDateChange)="endDateChange($event)"
				/>
			</clr-date-container>
		</form>
		<div class="btn-group">
			<button class="btn qrt_measurements-controller__refresh-btn"
			(click)="datePickerHours(24)">24h</button>
			<button class="btn qrt_measurements-controller__refresh-btn" 
			(click)="datePickerHours(48)">48h</button>
		</div>
		<div
			class="tooltip tooltip-md qrt_measurements-controller__tooltip-refresh"
			role="tooltip"
			aria-haspopup="true"
		>
			<button
				*ngIf="datePickerFormValue$ | async as datePickerFormValue"
				[clrLoading]="refreshBtnState$ | async"
				class="btn qrt_measurements-controller__refresh-btn"
				[ngClass]="{
					'qrt_measurements-controller__refresh-btn--shake':
						avgOrDateChanged$ | async
				}"
				(click)="onClickRefreshData(datePickerFormValue)"
				[disabled]="invalidDateRange$ | async"
				data-testid="measurements-refresh-btn"
			>
				Actualizar
			</button>
			<span *ngIf="invalidDateRange$ | async" class="tooltip-content"
				>O período com a média selecionada não é valido.</span
			>
		</div>
	</div>

	<div *ngIf="showOnlyOnPresentationType$('chart') | async">
		<button
			class="qrt_measurements-controller__download-btn"
			title="Descarregar em formato XLSX"
			(click)="downloadXLSX()"
		>
			<clr-icon shape="download-cloud" size="18"></clr-icon>
		</button>

		<qrt-chart-options
			[sensorConfig]="sensorConfig$ | async"
		></qrt-chart-options>
	</div>

	<div *ngIf="showOnlyOnPresentationType$('table') | async">
		<button
			class="qrt_measurements-controller__download-btn"
			title="Descarregar em formato XLSX"
			(click)="downloadAllInXLSX()"
		>
			<clr-icon shape="download-cloud" size="18"></clr-icon>
		</button>
	</div>
</div>
