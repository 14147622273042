<form
	[formGroup]="notificationForm"
	class="qrt_notification-preferences"
	(ngSubmit)="updateNotificationPreferences()"
>
	<div>
		<h2 class="qrt_notification-preferences__title">
			Alertas de actualizações
		</h2>
		<clr-checkbox-container>
			<clr-checkbox-wrapper>
				<input type="checkbox" clrCheckbox formControlName="updatesByEmail" />
				<label>E-mail</label>
			</clr-checkbox-wrapper>
			<clr-checkbox-wrapper>
				<input type="checkbox" clrCheckbox formControlName="updatesBySms" />
				<label>SMS</label>
			</clr-checkbox-wrapper>
		</clr-checkbox-container>
	</div>
	<div class="qrt_notification-preferences__btns">
		<button class="btn btn-primary">
			Alterar
		</button>
		<button type="button" class="btn">Cancelar</button>
	</div>
</form>
