<div class="qrt_map">
	<ng-container *ngIf="env.aqiVisible">
		<div
			class="qrt_map__globalindex"
			*ngIf="avgIndexLevel$ | async as avgIndexLevel"
			[ngClass]="{
				'qrt_map__globalindex--green': avgIndexLevel.number === 5,
				'qrt_map__globalindex--greenish': avgIndexLevel.number === 4,
				'qrt_map__globalindex--yellow': avgIndexLevel.number === 3,
				'qrt_map__globalindex--orange': avgIndexLevel.number === 2,
				'qrt_map__globalindex--red': avgIndexLevel.number === 1
			}"
		>
			<img
				src="{{ env.iconMarkersPath }}/aqi_{{ avgIndexLevel.number }}.png"
				class="qrt_map__quality"
				aria-hidden="true"
			/>
			<p class="qrt_map__gi-text">Índice Global: {{ avgIndexLevel.desc }}</p>
		</div>
	</ng-container>
	<div
		class="qrt_map__instance"
		leaflet
		[leafletOptions]="leafletOptions$ | async"
		[leafletLayersControl]="layersControl"
		[leafletMarkerCluster]="markerClusterData"
		[leafletMarkerClusterOptions]="markerClusterOptions"
		(leafletMapReady)="onMapReady($event)"
	></div>
</div>
