<div class="qrt_boxdetails">
	<div class="progress top loop qrt_progress" *ngIf="loading$ | async">
		<progress max="100" value="0" data-displayval="0%"></progress>
	</div>

	<ng-container *ngIf="meteoActivated$ | async; else chartData">
		<p *ngIf="!(meteoStation$ | async); else chartData">
			Não dispõe de dados meteorológicos
		</p>
	</ng-container>
	<ng-template #chartData>
		<ng-container *ngIf="showChartOptions$ | async">
			<div class="qrt_boxdetails__top">
				<qrt-presentation-mode-controller></qrt-presentation-mode-controller>
			</div>
			<div class="card qrt_boxdetails__card">
				<div
					class="qrt_boxdetails__header card-header"
					*ngIf="selectedBox$ | async as selectedBox"
				>
					<div
						class="qrt_boxdetails__drop-sign"
						*ngIf="!(showOnlyOnPresentationType$('chart-comparison') | async)"
					>
						<form clrForm [formGroup]="comboboxForm">
							<clr-combobox-container>
								<clr-combobox
									formControlName="deviceName"
									name="device"
									placeholder="Selecionar dispositivo"
									(clrOpenChange)="comboboxOpen($event)"
								>
									<clr-options>
										<clr-option
											*clrOptionItems="let box of boxesCombo$ | async"
											[clrValue]="box.Description"
											(click)="selectBox(box.DUI, box.currentLUI)"
											>{{ box.Description }}</clr-option
										>
									</clr-options>
								</clr-combobox>
							</clr-combobox-container>
						</form>
						<clr-signpost
							*ngIf="getLocationByLui$(selectedBox.currentLUI) | async as location"
						>
							<clr-signpost-content *clrIfOpen>
								<div>
									<p>
										{{ location.Locality }}, {{ location.PostalCode }} - {{
										location.Country }}
									</p>
									<p>DUI: {{ selectedBox.DUI }}</p>
									<p>LUI: {{ location.LUI }}</p>
								</div>
							</clr-signpost-content>
						</clr-signpost>
					</div>

					<div *ngIf="showOnlyOnPresentationType$('chart-comparison') | async">
						<qrt-config-comparison></qrt-config-comparison>
					</div>

					<div class="qrt_boxdetails__menuopts">
						<button
							class="qrt_boxdetails__scroll-btn"
							title="Ir para o fim da página"
							(click)="scroll(sensorsInfo)"
						>
							<clr-icon shape="angle-double"></clr-icon>
						</button>
						<a routerLink="/map" class="qrt_boxdetails__close">
							<span>Fechar</span>
							<clr-icon shape="times"></clr-icon>
						</a>
					</div>
				</div>

				<qrt-measurements-controller></qrt-measurements-controller>

				<ng-container *ngIf="loaded$ | async">
					<div
						class="qrt_boxdetails__datagrid"
						*ngIf="showOnlyOnPresentationType$('table') | async"
					>
						<qrt-measurements-table></qrt-measurements-table>
					</div>

					<ng-container
						*ngIf="showOnlyOnPresentationType$('wind-rose') | async"
					>
						<qrt-measurements-wind-rose></qrt-measurements-wind-rose>
					</ng-container>

					<ng-container
						*ngIf="showOnlyOnPresentationType$('chart-comparison') | async"
					>
						<qrt-side-by-side-bar-chart></qrt-side-by-side-bar-chart>
					</ng-container>

					<ng-container *ngIf="showOnlyOnPresentationType$('chart') | async">
						<div
							*ngIf="measurements$ | async as measurements"
							class="qrt_chart"
						>
							<dx-chart
								class="qrt_chart__instance"
								#Chart
								[palette]="['#6497b1']"
								[dataSource]="measurements"
								[customizePoint]="customizePoint"
							>
								<dxi-series
									argumentField="dateObserved"
									valueField="value"
									[type]="chartType$ | async"
								></dxi-series>

								<dxo-zoom-and-pan argumentAxis="both" valueAxis="both">
								</dxo-zoom-and-pan>

								<dxo-crosshair [enabled]="true">
									<dxo-label [visible]="true"></dxo-label>
								</dxo-crosshair>

								<dxo-argument-axis argumentType="datetime" type="discrete">
									<dxo-label format="dd-MM-yyyy HH:mm"></dxo-label>
								</dxo-argument-axis>

								<dxi-value-axis
									[maxValueMargin]="chartMaxValueMargin$ | async"
									[visualRange]="
					  measurements[0].unit === 'mbar' ? [800, 1150] : []
					"
								>
									<ng-container
										*ngFor="let limit of visibleSensorLimits$ | async"
									>
										<dxi-constant-line
											[width]="1"
											[value]="limit.value"
											[color]="limit.color"
										>
											<dxo-label [text]="limit.name"></dxo-label>
										</dxi-constant-line>
									</ng-container>

									<dxo-grid [visible]="true"></dxo-grid>
									<-- Gets the value of the first measurement's unit -->
									<dxo-title [text]="measurements[0].unit"></dxo-title>
								</dxi-value-axis>

								<dxo-legend [visible]="false"></dxo-legend>
							</dx-chart>

							<dx-range-selector
								class="qrt_chart__range-selector"
								[dataSource]="measurements"
								(onValueChanged)="onRangeValueChanged($event)"
								*ngIf="showRangeSelector$ | async"
							>
								<dxo-behavior callValueChanged="onMoving"></dxo-behavior>
								<dxo-scale minorTickInterval="day" valueType="datetime">
									<dxo-tick-interval [hours]="1"></dxo-tick-interval>
								</dxo-scale>
								<dxo-chart [palette]="['#6497b1']">
									<dxi-series
										argumentField="dateObserved"
										valueField="value"
									></dxi-series>
									<dxo-common-series-settings
										[type]="chartType$ | async"
										[barPadding]="0"
									>
										<dxo-point [size]="1"></dxo-point>
									</dxo-common-series-settings>
									<dxo-argument-axis argumentType="datetime">
										<dxo-label format="dd-MM-yyyy HH:mm"></dxo-label>
									</dxo-argument-axis>
								</dxo-chart>
							</dx-range-selector>
						</div>
					</ng-container>
				</ng-container>

				<div #sensorsInfo>
					<ng-container *ngIf="showOnlyOnPresentationType$('chart') | async">
						<ng-container *ngIf="!(meteoActivated$ | async)">
							<span
								*ngIf="selectedSensorInfo$ | async as sensorInfo"
								class="qrt_boxdetails__sensors-info"
							>
								<div>
									<clr-icon
										class="alert-icon"
										shape="info-circle"
										size="22"
									></clr-icon>
								</div>
								<p class="qrt_boxdetails__sensors-info-text">
									{{ sensorInfo }}
								</p>
							</span>
						</ng-container>

						<ng-container *ngIf="meteoActivated$ | async">
							<span
								*ngIf="selectedMeteoSensorInfo$ | async as meteoSensorInfo"
								class="qrt_boxdetails__sensors-info"
							>
								<div>
									<clr-icon
										class="alert-icon"
										shape="info-circle"
										size="22"
									></clr-icon>
								</div>
								<p class="qrt_boxdetails__sensors-info-text">
									{{ meteoSensorInfo }}
								</p>
							</span>
						</ng-container>
					</ng-container>

					<qrt-disclaimer></qrt-disclaimer>
				</div>
			</div>
		</ng-container>
	</ng-template>
</div>
