import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { environment } from 'environments/environment';

import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import pt from 'app/locale/pt.json';
import en from 'app/locale/en.json';

import * as fromAuth from 'app/auth/reducers';
import { StatusActions } from 'app/auth/actions';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	client: string = environment.client;

	loggedIn$: Observable<boolean>;

	constructor(private store$: Store<fromAuth.State>) {
		this.loggedIn$ = this.store$.select(fromAuth.selectLoggedIn);

		loadMessages(pt);
		loadMessages(en);
		locale(navigator.language);
	}

	ngOnInit() {
		this.store$.dispatch(StatusActions.loadUserData());
		this.store$.dispatch(StatusActions.loadNotifications());
	}
}
