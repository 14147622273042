import { Injectable } from '@angular/core';
import {
	Resolve,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, merge, EMPTY } from 'rxjs';
import {
	take,
	filter,
	tap,
	map,
	withLatestFrom,
	switchMap,
	takeUntil,
} from 'rxjs/operators';

import {
	BoxActions,
	LastMeasurementActions,
	OldestMeasurementActions,
	LocationActions,
	SensorActions,
} from 'app/devices/actions';
import * as fromDevices from 'app/devices/reducers';
import * as fromAuth from 'app/auth/reducers';
import * as SensorSelectors from 'app/devices/selectors/sensor.selectors';

import { BoxService } from 'app/devices/services/box.service';

import { Box } from 'app/devices/models/box.model';

@Injectable({ providedIn: 'root' })
export class BoxDetailsResolver implements Resolve<any> {
	boxes$: Observable<any>;
	lastMeasurements$: Observable<any>;
	selectedBox$: Observable<any>;
	boxesLoaded$: Observable<any>;
	meteoActivated$: Observable<boolean>;
	lastMeasurementsLoaded$: Observable<boolean>;
	oldestMeasurementsLoaded$: Observable<boolean>;
	sensorsLoaded$: Observable<boolean>;

	constructor(
		private store$: Store<any>,
		private actions$: Actions,
		private boxService: BoxService
	) {
		this.boxes$ = store$.pipe(select(fromDevices.selectAllBoxesEntities));
		this.lastMeasurements$ = store$.pipe(
			select(fromDevices.selectAllLastMeasurements)
		);
		this.selectedBox$ = store$.pipe(select(fromDevices.selectSelectedBoxById));

		this.boxesLoaded$ = store$.pipe(select(fromDevices.selectBoxesLoaded));

		this.lastMeasurementsLoaded$ = store$.pipe(
			select(fromDevices.selectLastMeasurementsLoaded)
		);

		this.oldestMeasurementsLoaded$ = store$.pipe(
			select(fromDevices.selectOldestMeasurementsLoaded)
		);

		this.sensorsLoaded$ = store$.pipe(
			select(SensorSelectors.selectSensorsLoaded)
		);

		this.meteoActivated$ = this.store$.pipe(select(fromAuth.selectMeteorology));
	}

	resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		const lui = Number(route.params.lui);

		this.store$.dispatch(BoxActions.loadSensorsInfo());
		this.store$.dispatch(LocationActions.loadLocations());
		this.store$.dispatch(BoxActions.selectBoxLui({ lui }));

		this.initBoxData();

		/**
		 * gets the box from the lui  and dispatch actions to
		 * select the DUI on BoxActions and LastMeasurementsActions
		 */
		return this.boxService.getByLui(lui).pipe(
			tap(box => {
				this.store$.dispatch(BoxActions.selectBox({ id: box.DUI }));

				this.meteoActivated$
					.pipe(
						tap(activated => {
							activated
								? this.store$.dispatch(
										BoxActions.selectMeteoSensor({
											sensorfield:
												box?.Config?.MeteoSensors?.filter(
													sensor => sensor.visibleDetails
												)[0]?.apiFieldName || 'METEOWINDSPEED',
										})
								  )
								: this.store$.dispatch(
										BoxActions.selectSensor({
											sensorfield:
												box?.Config?.Sensors?.filter(
													sensor => sensor.visibleDetails
												)[0]?.apiFieldName || 'CO',
										})
								  );
						}),
						take(1)
					)
					.subscribe();
			}),
			take(1)
		);
	}

	initBoxData(): void {
		this.boxesLoaded$.pipe(take(1)).subscribe(loaded => {
			if (!loaded) {
				this.store$.dispatch(BoxActions.loadBoxes());
			}
		});

		this.lastMeasurementsLoaded$.pipe(take(1)).subscribe(loaded => {
			if (!loaded) {
				this.store$.dispatch(LastMeasurementActions.loadLastMeasurements());
			}
		});

		this.oldestMeasurementsLoaded$.pipe(take(1)).subscribe(loaded => {
			if (!loaded) {
				this.store$.dispatch(OldestMeasurementActions.loadOldestMeasurements());
			}
		});

		this.sensorsLoaded$
			.pipe(
				take(1),
				filter(loaded => !loaded),
				tap(() => {
					this.store$.dispatch(SensorActions.loadSensors());
				})
			)
			.subscribe();
	}
}
