import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';

import * as fromAuth from 'app/auth/reducers';
import * as fromLayout from 'app/reducers';
import { RequestPasswordResetForm } from 'app/auth/reducers/status.reducer';
import { StatusActions } from 'app/auth/actions';
import * as LayoutActions from 'app/core/actions/layout.actions';

@Component({
	selector: 'qrt-request-password-reset',
	templateUrl: './request-password-reset.component.html',
	styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent implements OnInit {
	showModal$: Observable<boolean>;
	formState$: Observable<FormGroupState<RequestPasswordResetForm>>;
	httpSuccess$: Observable<boolean>;
	httpFailure$: Observable<boolean>;

	constructor(private store$: Store<any>) {
		this.showModal$ = store$.pipe(
			select(fromLayout.selectShowRequestPasswordResetModal)
		);
		this.formState$ = store$.pipe(
			select(fromAuth.selectRequestPasswordResetForm)
		);
		this.httpSuccess$ = store$.pipe(
			select(fromAuth.selectRequestPasswordResetStatus),
			filter(s => s === 'success'),
			map(s => !!s)
		);
		this.httpFailure$ = store$.pipe(
			select(fromAuth.selectRequestPasswordResetStatus),
			filter(s => s === 'failure'),
			map(s => !!s)
		);
	}

	ngOnInit(): void {}

	async submitForm(): Promise<void> {
		const username = await this.formState$
			.pipe(
				map(form => form.controls.username.value),
				take(1)
			)
			.toPromise();

		this.store$.dispatch(
			StatusActions.submitRequestPasswordReset({ username })
		);
	}

	openModal(): void {
		this.store$.dispatch(LayoutActions.openRequestPasswordResetModal());
	}

	closeModal(): void {
		this.store$.dispatch(LayoutActions.closeRequestPasswordResetModal());
	}
}
