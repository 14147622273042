import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
	constructor() {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return of(route.queryParams).pipe(map(params => !!params.u));
	}
}
