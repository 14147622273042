import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ClarityModule } from '@clr/angular';

import { SharedModule } from 'app/shared/shared.module';

import { HeaderComponent } from './components/header/header.component';
import { HeaderCascaisComponent } from './components/headercascais/headercascais.component';
import { HeaderAlticeComponent } from './components/headeraltice/headeraltice.component';
import { HeaderLisboaComponent } from './components/headerlisboa/headerlisboa.component';

import { SubnavComponent } from 'app/core/components/subnav/subnav.component';
import { SubnavLisboaComponent } from 'app/core/components/subnavlisboa/subnavlisboa.component';
import { AlertComponent } from 'app/core/components/alert/alert.component';
import { MaintenanceComponent } from 'app/core/components/maintenance/maintenance.component';
import { NotificationsComponent } from 'app/core/components/notifications/notifications.component';
import { NotificationsContainerComponent } from 'app/core/components/notifications-container/notifications-container.component';
import { DisclaimerComponent } from 'app/core/components/disclaimer/disclaimer.component';
import { CalloutComponent } from 'app/core/components/callout/callout.component';
import { TechSupportComponent } from 'app/core/components/tech-support/tech-support.component';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects } from './effects/layout.effects';

export const COMPONENTS = [
	HeaderComponent,
	HeaderCascaisComponent,
	HeaderAlticeComponent,
	HeaderLisboaComponent,
	SubnavComponent,
	SubnavLisboaComponent,
	AlertComponent,
	MaintenanceComponent,
	NotificationsComponent,
	NotificationsContainerComponent,
	DisclaimerComponent,
	CalloutComponent,
	TechSupportComponent,
];

@NgModule({
	declarations: COMPONENTS,
	exports: COMPONENTS,
	imports: [
		CommonModule,
		SharedModule,
		RouterModule,
		ClarityModule,
		ReactiveFormsModule,
		EffectsModule.forFeature([LayoutEffects]),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
