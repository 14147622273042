import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDevices from 'app/devices/reducers';
import * as fromMeasurement from 'app/devices/reducers/measurement.reducer';

export const selectMaxMeasurementsValue = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getMaxMeasurementsValue
);

export const selectWindRose = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getWindRose
);

export const selectWindRoseData = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getWindRoseData
);

export const selectWindSources = createSelector(
	selectWindRose,
	windRose => windRose.windSources
);

export const selectCardinalDirections = createSelector(
	selectWindRose,
	windRose => windRose.cardinalDirections
);

export const selectDatePickerFormState = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getDatePickerFormState
);

export const selectDatePickerFormValue = createSelector(
	selectDatePickerFormState,
	form => form.value
);

export const selectMeasurementsComparison = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getMeasurementsComparison
);

export const selectSelectedDUIsToCompare = createSelector(
	selectMeasurementsComparison,
	comparison => comparison.selectedDUIs
);

export const selectMeasurementComparisonFormState = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getMeasurementComparisonFormState
);

export const selectMeasurementComparisonFormValue = createSelector(
	selectMeasurementComparisonFormState,
	form => form.value
);

export const selectMeasurementRefreshBtnState = createSelector(
	fromDevices.selectMeasurementEntitiesState,
	fromMeasurement.getMeasurementRefreshBtnState
);
