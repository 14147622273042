<qrt-create-notification></qrt-create-notification>
<qrt-change-disabled-status></qrt-change-disabled-status>
<div class="qrt_manage-users">
	<h1 class="qrt_manage-users__title">Gerir utilizadores</h1>

	<clr-datagrid
		*ngIf="users$ | async as users"
		class="qrt_manage-users__datagrid"
	>
		<clr-dg-column [clrDgField]="'email'">E-mail</clr-dg-column>
		<clr-dg-column [clrDgField]="'firstName'">Nome</clr-dg-column>
		<clr-dg-column>Ações</clr-dg-column>

		<clr-dg-row *clrDgItems="let user of users">
			<clr-dg-cell>{{ user.email }}</clr-dg-cell>
			<clr-dg-cell>{{ user.firstName }} {{ user.lastName }}</clr-dg-cell>
			<clr-dg-cell>
				<button
					data-testid="notification-btn"
					type="button"
					role="tooltip"
					aria-haspopup="true"
					class="tooltip tooltip-md tooltip-top-left qrt_manage-users__action-btn"
					(click)="openCreateNotification(user)"
				>
					<clr-icon shape="bell" class="is-solid"></clr-icon>
					<span class="tooltip-content">Criar notificação</span>
				</button>

				<button
					type="button"
					role="tooltip"
					aria-haspopup="true"
					aria-labelledby="disabled-status-text"
					class="tooltip tooltip-md tooltip-top-left qrt_manage-users__action-btn"
					(click)="openChangeDisabledStatusModal(user)"
				>
					<clr-icon
						[attr.shape]="iconShapeByDisabledStatus(user.disabled)"
					></clr-icon>
					<span id="disabled-status-text" class="tooltip-content"
						>{{ user.disabled ? 'Activar utilizador' : 'Desactivar utilizador'
						}}</span
					>
				</button>
			</clr-dg-cell>
		</clr-dg-row>

		<clr-dg-footer>{{ users.length }} utilizadores</clr-dg-footer>
	</clr-datagrid>
</div>
