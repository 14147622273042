import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, withLatestFrom } from 'rxjs/operators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as LayoutSelectors from 'app/reducers';
import * as MeasurementSelectors from 'app/devices/selectors/measurement.selectors';
import * as DevicesSelectors from 'app/devices/reducers';
import * as MeasurementActions from 'app/devices/actions/measurement.actions';
import * as LayoutActions from 'app/core/actions/layout.actions';
import { comparisonFormKey } from 'app/devices/reducers/measurement.reducer';
import { FormGroupState, SetValueAction } from 'ngrx-forms';

dayjs.extend(customParseFormat);

@Component({
	selector: 'qrt-config-comparison',
	templateUrl: './config-comparison.component.html',
	styleUrls: ['./config-comparison.component.scss'],
})
export class ConfigComparisonComponent implements OnInit {
	showModal$: Observable<boolean>;
	form$: Observable<FormGroupState<any>>;
	formValue$: Observable<any>;
	devices$: Observable<Array<any>>;

	constructor(private store$: Store<any>) {
		this.showModal$ = store$.pipe(
			select(LayoutSelectors.selectShowComparisonModal)
		);
		this.form$ = store$.pipe(
			select(MeasurementSelectors.selectMeasurementComparisonFormState)
		);
		this.formValue$ = store$.pipe(
			select(MeasurementSelectors.selectMeasurementComparisonFormValue)
		);
		this.devices$ = store$.pipe(
			select(DevicesSelectors.selectAllBoxes),
			map((devices) =>
				devices.map((device) => ({
					description: `${device.currentLUI} - ${device.Description}`,
					DUI: device.DUI,
				}))
			)
		);
	}

	ngOnInit(): void {
		this.devices$.pipe(take(1)).subscribe((devices) => {
			this.store$.dispatch(
				new SetValueAction(
					comparisonFormKey,
					devices.map((d) => false)
				)
			);
		});
	}

	openModal() {
		this.store$.dispatch(LayoutActions.openComparisonModal());
	}

	closeModal() {
		this.store$.dispatch(LayoutActions.closeComparisonModal());
	}

	trackByIndex(index: number) {
		return index;
	}

	async onSubmit() {
		const { startDate, endDate } = await this.store$
			.pipe(
				select(MeasurementSelectors.selectDatePickerFormValue),
				map((formValue) => ({
					startDate: dayjs(formValue.startDate, 'DD/MM/YYYY').toISOString(),
					endDate: dayjs(formValue.endDate, 'DD/MM/YYYY')
						.add(1, 'day')
						.toISOString(),
				})),
				take(1)
			)
			.toPromise();

		const duis = await this.store$
			.pipe(
				select(MeasurementSelectors.selectMeasurementComparisonFormValue),
				withLatestFrom(this.devices$),
				map(([formValue, devices]) =>
					formValue
						.map((item, index) => (item ? devices[index].DUI : item))
						.filter((i) => !!i)
				),

				take(1)
			)
			.toPromise();

		this.store$.dispatch(
			MeasurementActions.updateSelectedDUIsToCompare({ duis })
		);

		this.store$.dispatch(
			MeasurementActions.loadMultipleMeasurements({
				startDate,
				endDate,
				devices: duis,
			})
		);

		this.closeModal();
	}
}
