import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';

import { LocationActions } from 'app/devices/actions';
import { Location } from 'app/devices/models/location';

export const locationFeatureKey = 'locations';

export interface State extends EntityState<Location> {
	loading: boolean;
	loaded: boolean;
	issues: any[];
}

export const adapter: EntityAdapter<Location> = createEntityAdapter<Location>({
	selectId: (location: Location) => location.LUI,
	sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
	loading: true,
	loaded: false,
	issues: [],
});

const locationReducer = createReducer(
	initialState,
	on(LocationActions.loadLocations, () => initialState),
	on(LocationActions.loadLocationsSuccess, (state, { locations }) =>
		adapter.setAll(
			locations,
			produce(state, draft => {
				draft.loading = false;
				draft.loaded = true;
			})
		)
	),
	on(LocationActions.loadIssuesSuccess, (state, { issues }) =>
		produce(state, draft => {
			draft.issues = issues;
		})
	)
);

export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();

export function reducer(state: State | undefined, action: Action) {
	return locationReducer(state, action);
}

export const selectLoading = (state: State) => state.loading;
export const selectLoaded = (state: State) => state.loaded;
export const getIssues = (state: State) => state.issues;
