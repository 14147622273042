import {
	NgModule,
	LOCALE_ID,
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { ClarityModule } from '@clr/angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { AuthModule } from 'app/auth/auth.module';
import { MapModule } from 'app/map/map.module';
import { DevicesModule } from 'app/devices/devices.module';
import { CoreModule } from 'app/core/core.module';

import { AppComponent } from './app.component';
import { rootReducers, metaReducers } from './reducers';

import { AppRoutingModule } from './app-routing.module';
import { environment } from 'environments/environment';

import { AuthGuard } from 'app/auth/services/auth-guard.service';
import { RoleGuard } from 'app/auth/services/role-guard.service';

import { TokenInterceptor } from 'app/interceptors/token.interceptor';
import * as fromForecast from './core/reducers/forecast.reducer';
import { ForecastEffects } from './core/effects/forecast.effects';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ClarityModule,
		HttpClientModule,
		AuthModule,
		AppRoutingModule,
		MapModule,
		DevicesModule,
		StoreModule.forRoot(rootReducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
			},
		}),
		!environment.production ? StoreDevtoolsModule.instrument() : [],
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
		LeafletModule.forRoot(),
		LeafletMarkerClusterModule.forRoot(),
		CoreModule,
		StoreModule.forFeature(
			fromForecast.forecastFeatureKey,
			fromForecast.reducer
		),
		EffectsModule.forFeature([ForecastEffects]),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [
		AuthGuard,
		RoleGuard,
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
