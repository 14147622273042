<div class="qrt_notifications">
	<button class="qrt_notifications__btn" data-testid="bell-btn" (click)="toggleNotifications()">
		<span class="screen-reader-only">Notificações</span>
		<span
			*ngIf="unreadNotificationsCounter$ | async as notificationsCounter"
			class="qrt_notifications__amount"
			>{{ notificationsCounter }}</span
		>
	</button>
	<qrt-notifications-container
		[open]="open"
		[unreadNotifications]="unreadNotifications$ | async"
		data-testid="notifications-container"
	></qrt-notifications-container>
</div>
