import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromForecast from '../reducers/forecast.reducer';

export const selectForecastState = createFeatureSelector<fromForecast.State>(
	fromForecast.forecastFeatureKey
);

export const selectForecastData = createSelector(
	selectForecastState,
	fromForecast.getForecastData
);
