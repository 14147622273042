import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuth from 'app/auth/reducers';
import { PreferencesActions } from 'app/auth/actions';

import { NotificationPreferences } from 'app/auth/models/notification';

@Component({
	selector: 'qrt-notification-preferences',
	templateUrl: './notification-preferences.component.html',
	styleUrls: ['./notification-preferences.component.scss'],
})
export class NotificationPreferencesComponent implements OnInit {
	notificationForm = this.fb.group({
		updatesByEmail: [false],
		updatesBySms: [false],
	});

	notificationPreferences$: Observable<NotificationPreferences>;

	constructor(private store$: Store<fromAuth.State>, private fb: FormBuilder) {
		this.notificationPreferences$ = store$.pipe(
			select(fromAuth.selectNotificationPreferences)
		);
	}

	ngOnInit(): void {
		this.notificationPreferences$
			.pipe(take(1))
			.subscribe(notificationPreferences => {
				this.notificationForm.patchValue(notificationPreferences);
			});
	}

	updateNotificationPreferences() {
		const formValues = this.notificationForm.value;

		this.store$.dispatch(
			PreferencesActions.updateNotificationPreferences(formValues)
		);
	}
}
