import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ClrLoadingState } from '@clr/angular';

import { UsersService } from 'app/auth/services/users.service';
import { UsersActions } from 'app/auth/actions';
import * as LayoutActions from 'app/core/actions/layout.actions';
import { AlertService } from 'app/core/services/alert.service';

@Injectable()
export class UsersEffetcs {
	constructor(
		private actions$: Actions,
		private usersService: UsersService,
		private alertService: AlertService
	) {}

	loadUsers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsersActions.loadUsers),
			switchMap(() =>
				this.usersService.getAll$().pipe(
					map(users => UsersActions.loadUsersSuccess({ users })),
					catchError(error => of(UsersActions.loadUsersFailure({ error })))
				)
			)
		)
	);

	changeDisabledStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsersActions.changeDisabledStatus),
			switchMap(({ userId, disabled }) =>
				this.usersService.changeDisabledStatus$(userId, disabled).pipe(
					map(() => UsersActions.changeDisabledStatusSuccess()),
					catchError(error =>
						of(UsersActions.changeDisabledStatusFailure({ error }))
					)
				)
			)
		)
	);

	changeDisabledStatusSubmitButtonState$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsersActions.changeDisabledStatus),
			map(() =>
				LayoutActions.updateChangeDisabledStatusSubmitButtonState({
					loadingState: ClrLoadingState.LOADING,
				})
			)
		)
	);

	changeDisabledStatusSubmitButtonStateOnModalClose$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LayoutActions.closeChangeDisabledStatusModal),
			map(() =>
				LayoutActions.updateChangeDisabledStatusSubmitButtonState({
					loadingState: ClrLoadingState.DEFAULT,
				})
			)
		)
	);

	changeDisabledStatusSubmitButtonStateOnSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsersActions.changeDisabledStatusSuccess),
			map(() =>
				LayoutActions.updateChangeDisabledStatusSubmitButtonState({
					loadingState: ClrLoadingState.SUCCESS,
				})
			)
		)
	);

	closeChangeDisabledStatusModal$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				UsersActions.changeDisabledStatusSuccess,
				UsersActions.changeDisabledStatusFailure
			),
			map(() => LayoutActions.closeChangeDisabledStatusModal())
		)
	);

	openSucessAlertOnSucessChangeDisabledStatus$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UsersActions.changeDisabledStatusSuccess),
				tap(() =>
					this.alertService.success(
						'Status do utilizador actualizado com sucesso.'
					)
				)
			),
		{ dispatch: false }
	);

	openFailureAlertOnFailureChangeDisabledStatus$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UsersActions.changeDisabledStatusFailure),
				tap(() =>
					this.alertService.error(
						'Não foi possível atualizar status do utilizador'
					)
				)
			),
		{ dispatch: false }
	);

	reloadUsersOnSucessChangeDisabled$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsersActions.changeDisabledStatusSuccess),
			map(() => UsersActions.loadUsers())
		)
	);
}
