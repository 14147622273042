<clr-main-container>
  <clr-header class="qrt_header no-print">
    <a routerLink="map" class="qrt_header__branding">
      <img src="assets/img/logo.png" class="qrt_header__logo" alt="QART" />
    </a>
    <div class="qrt_sidenav header-nav" [clr-nav-level]="1">
      <div class="qrt_sidenav__userinfo">
        <h5 class="qrt_sidenav__username">{{ username$ | async }}</h5>
        <qrt-notifications></qrt-notifications>
      </div>
      <hr class="qrt_sidenav__divider" />
      <ul class="qrt_sidenav__list">
        <li class="qrt_sidenav__item">
          <form class="clr-toggle-wrapper" [formGroup]="meteoForm">
            <input
              formControlName="meteoToggle"
              type="checkbox"
              id="toggle-meteo"
              name="toggle-meteo"
              value="meteo-on"
              class="clr-toggle"
              (change)="setMeteoPreference()"
            />
            <label for="toggle-meteo">{{ 'Meteorológia' | t }}</label>
          </form>
        </li>
      </ul>
      <hr class="qrt_sidenav__divider" />
      <a class="qrt_sidenav__link nav-link nav-text" routerLink="/map">Mapa</a>
      <a class="qrt_sidenav__link nav-link nav-text" routerLink="/devices"
        >Dispositivos</a
      >
      <a class="qrt_sidenav__link nav-link nav-text" routerLink="/devices"
        >Visão geral</a
      >
      <a class="qrt_sidenav__link nav-link nav-text" routerLink="/alerts"
        >Alertas</a>
      <a class="qrt_sidenav__link nav-link nav-text" routerLink="/users"
        >Gerir utilizadores</a>
      <a class="qrt_sidenav__link nav-link nav-text" routerLink="/preferences"
        >Preferências</a
      >
      <hr class="qrt_sidenav__divider" />
      <button class="qrt_sidenav__logout" (click)="logout()">
        <clr-icon shape="power"></clr-icon><span>{{ 'Logout' | t }}</span>
      </button>
    </div>
    <div class="qrt_header__actions header-actions">
      <qrt-notifications></qrt-notifications>
      <clr-dropdown>
        <button class="nav-text" clrDropdownTrigger>
          {{ username$ | async }}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a href="" routerLink="/preferences" clrDropdownItem>{{
            'Preferências' | t
          }}</a>
          <button clrDropdownItem (click)="logout()">{{ 'Logout' | t }}</button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>
</clr-main-container>
