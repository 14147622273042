import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
	mergeMap,
	map,
	tap,
	catchError,
	withLatestFrom,
	switchMap,
	take,
} from 'rxjs/operators';
import { of } from 'rxjs';

import * as BoxActions from 'app/devices/actions/box.actions';
import { LayoutActions } from 'app/core/actions';
import { BoxService } from 'app/devices/services/box.service';
import { AlertService } from 'app/core/services/alert.service';
import { SensorsInfoService } from 'app/devices/services/sensors-info.service';
import * as fromAuth from 'app/auth/reducers';

@Injectable()
export class BoxEffects {
	loadDevices$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BoxActions.loadBoxes),
			mergeMap(action =>
				this.boxService.getAll$().pipe(
					map(boxes => BoxActions.loadBoxesSuccess({ boxes })),
					catchError(error => of(BoxActions.loadBoxesFailure({ error })))
				)
			)
		);
	});

	loadSensorsInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoxActions.loadSensorsInfo),
			switchMap(() =>
				this.sensorsInfoService.getAll$().pipe(
					map(sensorsInfo =>
						BoxActions.loadSensorsInfoSuccess({ sensorsInfo })
					),
					catchError(error => of(BoxActions.loadSensorsInfoFailure({ error })))
				)
			)
		)
	);

	requestExport$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoxActions.requestExport),
			mergeMap(action => {
				const { startDate, endDate, average, boxes, preferences } = action;

				return this.boxService
					.requestExport$(startDate, endDate, average, boxes, preferences)
					.pipe(
						map(() => BoxActions.requestExportSuccess()),
						catchError(error => of(BoxActions.requestExportFailure({ error })))
					);
			})
		)
	);

	requestExportSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(BoxActions.requestExportSuccess),
				tap(() =>
					this.alertService.success(
						'Receberá via e-mail as medidas requisitadas.'
					)
				)
			),
		{ dispatch: false }
	);

	requestDiagnosis$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoxActions.requestDiagnosis),
			mergeMap(({ dui }) =>
				this.boxService.requestDiagnosis$(dui).pipe(
					map(
						diagnosis => BoxActions.requestDiagnosisSuccess({ diagnosis }),
						catchError(error =>
							of(BoxActions.requestDiagnosisFailure({ error }))
						)
					)
				)
			)
		)
	);

	selectDuiToDiagnosis$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoxActions.selectDuiToDiagnosis),
			switchMap(({ dui }) => [LayoutActions.openDeviceDiagnosisModal()])
		)
	);

	constructor(
		private store$: Store<any>,
		private actions$: Actions,
		private boxService: BoxService,
		private alertService: AlertService,
		private sensorsInfoService: SensorsInfoService
	) {}
}
