import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS }  from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { MapRoutingModule } from './map-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { MapComponent } from './components/map/map.component';

import * as fromMap from 'app/map/reducers';
import { MapEffects } from 'app/map/effects';

import { TokenInterceptor } from 'app/interceptors/token.interceptor';
import { PopupComponent } from './components/popup/popup.component';


@NgModule({
	declarations: [MapComponent, PopupComponent],
	imports: [
		CommonModule,
		MapRoutingModule,
		SharedModule,
		StoreModule.forFeature(fromMap.mapFeatureKey, fromMap.reducers),
		EffectsModule.forFeature([MapEffects]),
		LeafletModule,
		LeafletMarkerClusterModule,
	],
	entryComponents: [PopupComponent],
	// providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },]
})
export class MapModule {}