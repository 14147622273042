import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { UserData } from 'app/auth/models/user';
import { map, tap } from 'rxjs/operators';

export function storageFactory() {
	return typeof window === undefined || typeof localStorage === undefined
		? null
		: localStorage;
}

export const LOCAL_STORAGE_TOKEN = new InjectionToken('qart-localstorage', {
	factory: storageFactory,
});

@Injectable({ providedIn: 'root' })
export class AuthStorageService {
	private userDataKey = 'userData';

	supported(): Observable<boolean> {
		return this.storage !== null
			? of(true)
			: throwError('LocalStorage not supported');
	}

	getUserData(): Observable<UserData> {
		return this.supported().pipe(
			map(() => this.storage.getItem(this.userDataKey)),
			map((value: string | null) => (value ? JSON.parse(value) : []))
		);
	}

	addUserDataToStorage(userData: UserData): Observable<boolean | UserData> {
		return this.supported().pipe(
			tap(() => this.storage.setItem(this.userDataKey, JSON.stringify(userData)))
		);
	}

	deleteUserData(): Observable<boolean> {
		return this.supported().pipe(
			tap(() => this.storage.removeItem(this.userDataKey))
		);
	}

	constructor(@Inject(LOCAL_STORAGE_TOKEN) private storage: Storage) {}
}