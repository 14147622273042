<div *ngIf="open" class="qrt_notifications-container">
	<h5>Notificações</h5>
	<p *ngIf="!unreadNotifications?.length">
		Não há notificações de momento.
	</p>
	<ul class="qrt_notifications-container__list">
		<li
			*ngFor="let notification of unreadNotifications"
			class="qrt_notifications-container__item"
			data-testid="notification"
		>
			<p>
				<clr-icon
					shape="info-circle"
					size="20"
					class="qrt_notifications-container__icon"
				></clr-icon>
				{{ notification.message }}
			</p>
			<button
				role="tooltip"
				aria-haspopup="true"
				class="tooltip tooltip-sm tooltip-top-left qrt_notifications-container__read-btn"
				type="button"
				data-testid="mark-as-read-btn"
				(click)="markAsRead(notification.id)"
			>
				<clr-icon shape="checkbox-list"></clr-icon>
				<span class="tooltip-content">Marcar como lida</span>
			</button>
		</li>
	</ul>
</div>
