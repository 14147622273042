import { createAction, props } from '@ngrx/store';

import { User } from 'app/auth/models/user';

export const loadUsers = createAction('[Users/API] Load Users');

export const loadUsersSuccess = createAction(
	'[Users/API] Load Users Success',
	props<{ users: any }>()
);

export const loadUsersFailure = createAction(
	'[Users/API] Load Users Failure',
	props<{ error: any }>()
);

export const selectUser = createAction(
	'[Users] Select user',
	props<{ user: any }>()
);

export const changeDisabledStatus = createAction(
	'[Users] Change Disabled Status',
	props<{ userId: string; disabled: boolean }>()
);

export const changeDisabledStatusSuccess = createAction(
	'[Users] Change Disabled Status Success'
);

export const changeDisabledStatusFailure = createAction(
	'[Users] Change Disabled Status Failure',
	props<{ error: any }>()
);
