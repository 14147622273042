import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Measurement } from 'app/devices/models/measurement';

export const loadLastMeasurements = createAction(
	'[Last Measurement/API] Load Last Measurements'
);

export const loadLastMeasurementsSuccess = createAction(
	'[Last Measurement/API] Load Last Measurements Success',
	props<{ lastmeasurements: Measurement[] }>()
);

export const loadLastMeasurementsFailure = createAction(
	'[Last Measurement/API] Load Last Measurements Failure',
	props<{ error: any }>()
);
