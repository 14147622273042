<header class="qrt_headeraltice">
	<div class="branding">
		<img class="qrt_headeraltice__logo" src="assets/img/altice/logo.png" />
	</div>
	<div class="qrt_header__actions header-actions">
		<qrt-notifications></qrt-notifications>
		<clr-dropdown>
			<button class="nav-text" clrDropdownTrigger>
				{{ username$ | async }}
				<clr-icon shape="caret down"></clr-icon>
			</button>
			<clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
				<a href="" routerLink="/preferences" clrDropdownItem>{{
					'Preferências' | t
				}}</a>
				<button (click)="logout()" clrDropdownItem>{{ 'Logout' | t }}</button>
			</clr-dropdown-menu>
		</clr-dropdown>
	</div>
</header>
