import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as LocationSelectors from 'app/devices/selectors/location.selectors';
import * as LocationActions from 'app/devices/actions/location.actions';

@Component({
	selector: 'qrt-issues',
	templateUrl: './issues.component.html',
	styleUrls: ['./issues.component.scss'],
})
export class IssuesComponent implements OnInit {
	issues$: Observable<Array<any>>;
	public incidentMapping = {
		malfunction: 'Mau funcionamento',
		vandalism: 'Vandalismo',
		accident: 'Acidente',
	};
	public statusMapping = {
		pending: 'Pendente',
		closed: 'Resolvido',
	};

	constructor(private store$: Store<any>) {
		this.issues$ = this.store$.pipe(select(LocationSelectors.selectAllIssues));
	}

	ngOnInit(): void {
		this.store$.dispatch(LocationActions.loadIssues());
	}
}
