import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Measurement } from 'app/devices/models/measurement';

export const loadOldestMeasurements = createAction(
	'[Oldest Measurement/API] Load Oldest Measurements'
);

export const loadOldestMeasurementsSuccess = createAction(
	'[Oldest Measurement/API] Load Oldest Measurements Success',
	props<{ oldestMeasurements: Measurement[] }>()
);

export const loadOldestMeasurementsFailure = createAction(
	'[Oldest Measurement/API] Load Oldest Measurements Failure',
	props<{ error: any }>()
);