import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, of, forkJoin } from 'rxjs';
import {
	map,
	filter,
	combineLatest,
	distinctUntilChanged,
	skip,
	merge,
	take,
	startWith,
	tap,
	withLatestFrom,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import moment from 'moment';
import { ClrLoadingState } from '@clr/angular';
import { FormGroupState, SetValueAction } from 'ngrx-forms';

import { getMaxDaysFromAvgType } from 'app/shared/helpers/average';

import * as LayoutSelectors from 'app/reducers';
import * as fromAuth from 'app/auth/reducers';
import * as fromDevices from 'app/devices/reducers';
import * as DevicesSelectors from 'app/devices/reducers';
import * as BoxActions from 'app/devices/actions/box.actions';
import * as BoxPreferencesActions from 'app/devices/actions/boxpreferences.actions';
import * as MeasurementActions from 'app/devices/actions/measurement.actions';
import * as MeasurementSelectors from 'app/devices/selectors/measurement.selectors';

import { AvgPeriod } from 'app/devices/models/box.model';
import { presentationType } from 'app/core/reducers/layout.reducer';
import {
	MeasurementsDatePickerForm,
	measurementsDatePickerFormKey,
} from 'app/devices/reducers/measurement.reducer';


@Component({
	selector: 'qrt-measurements-controller',
	templateUrl: './measurements-controller.component.html',
	styleUrls: ['./measurements-controller.component.scss'],
})
export class MeasurementsControllerComponent implements OnInit {
	airQualityActivated$: Observable<boolean>;
	meteoActivated$: Observable<boolean>;
	selectedSensor$: Observable<any>;
	selectedMeteoSensor$: Observable<any>;
	sensors$: Observable<any>;
	meteoSensors$: Observable<any>;
	selectedAvgPeriod$: Observable<any>;
	avgPeriods$: Observable<any>;
	datePickerForm$: Observable<FormGroupState<MeasurementsDatePickerForm>>;
	datePickerFormValue$: Observable<MeasurementsDatePickerForm>;
	lastMeasurementDate$: Observable<string>;
	lastMeasurementDate$ub: Subscription;
	oldestMeasurementDate$: Observable<string>;
	selectedSensorLimits$: Observable<any>;
	invalidDateRange$: Observable<boolean>;
	avgOrDateChanged$: Observable<boolean>;
	measurementsLoaded$: Observable<boolean>;
	presentationType$: Observable<presentationType>;
	sensorConfig$: Observable<any>;
	refreshBtnState$: Observable<ClrLoadingState>;
	showSelectSensor$: Observable<boolean>;
	devices$: Observable<any>;

	constructor(private store$: Store<any>) {
		this.datePickerForm$ = store$.pipe(
			select(MeasurementSelectors.selectDatePickerFormState)
		);
		this.datePickerFormValue$ = store$.pipe(
			select(MeasurementSelectors.selectDatePickerFormValue)
		);
		this.airQualityActivated$ = this.store$.pipe(
			select(fromAuth.selectAirQuality)
		);
		this.meteoActivated$ = this.store$.pipe(select(fromAuth.selectMeteorology));
		this.measurementsLoaded$ = store$.pipe(
			select(fromDevices.selectMeasurementsLoaded)
		);
		this.selectedSensor$ = store$.pipe(
			select(fromDevices.selectSelectedSensor)
		);
		this.selectedMeteoSensor$ = store$.pipe(
			select(fromDevices.selectSelectedMeteoSensor)
		);
		this.sensors$ = store$.pipe(
			select(fromDevices.selectSelectedBoxSensorsDetailsAllowed),
			filter(sensors => !!sensors)
		);
		this.meteoSensors$ = store$.pipe(
			select(fromDevices.selectSelectedBoxMeteoSensorsDetailsAllowed)
		);
		this.selectedAvgPeriod$ = store$.pipe(
			select(fromDevices.selectSelectedAvgPeriod)
		);
		this.avgPeriods$ = store$.pipe(
			select(fromDevices.selectSelectedBoxAvgPeriods)
		);
		this.lastMeasurementDate$ = this.store$.pipe(
			select(fromDevices.selectSelectedBoxLastMeasurementDate),
			filter(date => !!date),
			map(date => moment(date).format('DD/MM/YYYY'))
		);
		this.oldestMeasurementDate$ = store$.pipe(
			select(fromDevices.selectSelectedBoxOldestMeasurementDate),
			filter(date => !!date),
			map(date => moment(date).format('DD/MM/YYYY'))
		);
		this.selectedSensorLimits$ = store$.pipe(
			select(fromDevices.selectSelectedSensorLimits)
		);
		this.invalidDateRange$ = this.datePickerFormValue$.pipe(
			combineLatest(this.selectedAvgPeriod$),
			map(([selectedDates, avgPeriod]) => {
				const startDate = moment(selectedDates.startDate, 'DD/MM/YYYY');
				const endDate = moment(selectedDates.endDate, 'DD/MM/YYYY');
				const maxDays = getMaxDaysFromAvgType(avgPeriod.avgType);

				return (
					startDate.isAfter(endDate, 'day') ||
					endDate.diff(startDate, 'days') >= maxDays
				);
			})
		);
		this.avgOrDateChanged$ = this.datePickerFormValue$.pipe(
			distinctUntilChanged(),
			merge(this.selectedAvgPeriod$),
			map(val => !!val),
			skip(2)
		);
		this.presentationType$ = store$.pipe(
			select(LayoutSelectors.selectDeviceDetailsPresentationType)
		);
		this.sensorConfig$ = store$.pipe(
			select(LayoutSelectors.selectSensorConfig)
		);
		this.devices$ = store$.pipe(
			select(DevicesSelectors.selectAllBoxes),
			map(devices =>
				devices.map(device => ({
					description: `${device.currentLUI} - ${device.Description}`,
					DUI: device.DUI,
				}))
			)
		);
		this.refreshBtnState$ = store$.pipe(
			select(MeasurementSelectors.selectMeasurementRefreshBtnState)
		);
	}

	ngOnInit(): void {
		// Fill date pickers with initial date
		this.lastMeasurementDate$ub = this.lastMeasurementDate$.subscribe(date => {
			const startDate = moment(date, 'DD/MM/YYYY')
				.subtract(1, 'day')
				.format('DD/MM/YYYY');

			this.store$.dispatch(
				new SetValueAction(
					`${measurementsDatePickerFormKey}.startDate`,
					startDate
				)
			);
			this.store$.dispatch(
				new SetValueAction(`${measurementsDatePickerFormKey}.endDate`, date)
			);
		});

		this.showSelectSensor$ = forkJoin([
			this.showOnlyOnPresentationType$('chart').pipe(take(1)),
			this.showOnlyOnPresentationType$('chart-comparison').pipe(take(1)),
		]).pipe(map(selectedPType => !!selectedPType.filter(i => !!i).length));
	}

	datePickerHours(hoursToSubtract: number): void {
		const currentDate = moment().format('DD/MM/YYYY');
		const lastHours = moment().subtract(hoursToSubtract, 'hours').format('DD/MM/YYYY');

		this.store$.dispatch(
			new SetValueAction(
				`${measurementsDatePickerFormKey}.startDate`,
				lastHours
			)
		);
		this.store$.dispatch(
			new SetValueAction(`${measurementsDatePickerFormKey}.endDate`, currentDate)
		);

		this.onClickRefreshData({
			startDate: lastHours,
			endDate: currentDate
		})
	}

	changeSelectedSensor(field: string) {
		this.store$.dispatch(BoxActions.selectSensor({ sensorfield: field }));
	}

	changeSelectedMeteoSensor(field: string) {
		this.store$.dispatch(BoxActions.selectMeteoSensor({ sensorfield: field }));
	}

	changeSelectedAvgPeriodType(avgPeriod: AvgPeriod) {
		this.store$.dispatch(BoxPreferencesActions.selectAvgPeriod({ avgPeriod }));
	}

	public invalidPeriod(
		avgPeriod: AvgPeriod,
		selectedDates: MeasurementsDatePickerForm
	): boolean {
		const startDate = moment(selectedDates.startDate, 'DD/MM/YYYY');
		const endDate = moment(selectedDates.endDate, 'DD/MM/YYYY');

		return (
			endDate.diff(startDate, 'days') >=
			getMaxDaysFromAvgType(avgPeriod.avgType)
		);
	}

	async onClickRefreshData(selectedDates: MeasurementsDatePickerForm) {
		const startDate = moment(selectedDates.startDate, 'DD/MM/YYYY')
			.startOf('day')
			.toISOString();
		const endDate = moment(selectedDates.endDate, 'DD/MM/YYYY')
			.add(1, 'day')
			.toISOString();

		const duis = await this.store$
			.pipe(
				select(MeasurementSelectors.selectMeasurementComparisonFormValue),
				withLatestFrom(this.devices$),
				map(([formValue, devices]) =>
					formValue
						.map((item, index) => (item ? devices[index].DUI : item))
						.filter(i => !!i)
				),

				take(1)
			)
			.toPromise();

		this.presentationType$
			.pipe(
				tap(pType =>
					pType === 'chart-comparison'
						? this.store$.dispatch(
								MeasurementActions.loadMultipleMeasurements({
									startDate,
									endDate,
									devices: duis,
								})
						)
						: this.store$.dispatch(
								MeasurementActions.loadMeasurements({
									cfg: {
										startDate,
										endDate,
									},
								})
						)
				),
				take(1)
			)
			.subscribe();

		this.store$.dispatch(
			new SetValueAction(
				`${measurementsDatePickerFormKey}.startDate`,
				selectedDates.startDate
			)
		);
		this.store$.dispatch(
			new SetValueAction(
				`${measurementsDatePickerFormKey}.endDate`,
				selectedDates.endDate
			)
		);
	}

	/**
	 * Receives a presentation type/mode (e.g: chart or table)
	 * then return a boolean checking equality of the given type
	 * and the current selected presentation type to determine
	 * will be used with the NgIf directive in the template
	 *
	 */
	public showOnlyOnPresentationType$(type: presentationType) {
		return this.presentationType$.pipe(map(pType => pType === type));
	}

	public downloadXLSX() {
		this.store$
			.pipe(
				combineLatest(this.selectedSensor$, this.selectedAvgPeriod$),
				take(1),
				tap(([store, selectedSensor, selectedAvgPeriod]) => {
					this.store$.dispatch(
						MeasurementActions.downloadMeasurements({
							extension: 'xlsx',
							fileName: selectedSensor.name,
							sensor: selectedSensor.apiFieldName,
							avg: selectedAvgPeriod.avgType,
						})
					);
				})
			)
			.subscribe();
	}

	public async downloadAllInXLSX() {
		const selectedAvgPeriod = await this.selectedAvgPeriod$
			.pipe(take(1))
			.toPromise();

		this.store$.dispatch(
			MeasurementActions.downloadAllMeasurements({
				extension: 'xlsx',
				fileName: 'Médias',
				avg: selectedAvgPeriod.avgType,
			})
		);
	}

	// Value emitted by change on date picker
	public startDateChange(date): void {
		const startDate = moment(date).startOf('day').format('DD/MM/YYYY');

		this.store$.dispatch(
			new SetValueAction(
				`${measurementsDatePickerFormKey}.startDate`,
				startDate
			)
		);
	}

	// Value emitted by change on date picker
	public endDateChange(date): void {
		const endDate = moment(date).startOf('day').format('DD/MM/YYYY');

		this.store$.dispatch(
			new SetValueAction(`${measurementsDatePickerFormKey}.endDate`, endDate)
		);
	}

	ngOnDestroy(): void {
		this.lastMeasurementDate$ub.unsubscribe();
	}
}
