import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDetailedLastMeasurement from 'app/devices/reducers/detailed-last-measurement.reducer';
import { DetailedLastMeasurementActions } from 'app/devices/actions';

export const selectAllDeitaledLastMeasurements = createSelector(
	fromDetailedLastMeasurement.selectDetailedLastMeasurementState,
	(state) => Object.entries(state.entities).map(([k, v]) => v)
);

export const selectLoading = createSelector(
	fromDetailedLastMeasurement.selectDetailedLastMeasurementState,
	(state) => state.loading
);
