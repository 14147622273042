import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDevices from 'app/devices/reducers';
import * as fromLocation from 'app/devices/reducers/location.reducer';
import { LocationActions } from 'app/devices/actions';
import { Location } from 'app/devices/models/location';

export const selectLocationByLui = createSelector(
	fromDevices.selectAllLocationEntities,
	(locations, props) => locations[props.LUI]
);

export const selectAllLocations = createSelector(
	fromDevices.selectLocationEntitiesState,
	fromLocation.selectAll
);

export const selectAllIssues = createSelector(
	fromDevices.selectLocationEntitiesState,
	fromLocation.getIssues
);
