import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
	catchError,
	map,
	tap,
	switchMap,
	take,
	withLatestFrom,
} from 'rxjs/operators';
import { PreferencesService } from 'app/auth/services/preferences.service';
import { AlertService } from 'app/core/services/alert.service';
import { User, UserData } from 'app/auth/models/user';

import * as fromAuth from 'app/auth/reducers';
import * as fromDevices from 'app/devices/reducers';
import { PreferencesActions, StatusActions } from 'app/auth/actions';
import { BoxActions } from 'app/devices/actions';

@Injectable()
export class PreferencesEffects {
	meteoPreference$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PreferencesActions.meteoPreference),
			withLatestFrom(
				this.store$.pipe(select(fromDevices.selectBoxByLui))
			),
			map(([{ activated }, device]) =>
				activated
					? BoxActions.selectMeteoSensor({
							sensorfield:
								device?.Config?.MeteoSensors?.filter(
									sensor => sensor.visibleDetails
								)[0].apiFieldName || 'METEOWINDSPEED',
					  })
					: BoxActions.selectSensor({
							sensorfield:
								device?.Config?.Sensors?.filter(
									sensor => sensor.visibleDetails
								)[0].apiFieldName || 'CO',
					  })
			),
			catchError(([{ activated }, device]) =>
				activated
					? of(BoxActions.selectMeteoSensor({ sensorfield: 'METEOWINDSPEED' }))
					: of(BoxActions.selectSensor({ sensorfield: 'CO' }))
			)
		)
	);

	loadUserPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatusActions.loginSuccess, StatusActions.loadUserDataSuccess),
			map(({ userData }) =>
				PreferencesActions.loadUserPreferences(userData.user?.preferences)
			)
		)
	);

	updateUserPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PreferencesActions.updateUserPreferences),
			switchMap(data =>
				this.ṕreferencesService.updateUserPreferences$(data).pipe(
					map(user =>
						PreferencesActions.updateUserPreferencesSuccess({ user })
					),
					catchError(error =>
						of(PreferencesActions.updateUserPreferencesFailure({ error }))
					)
				)
			)
		)
	);

	updateUserPreferencesSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(
					PreferencesActions.updateUserPreferencesSuccess,
					PreferencesActions.updateNotificationPreferencesSuccess
				),
				tap(() =>
					this.alertService.success('Preferências atualizadas com sucesso.')
				)
			),
		{ dispatch: false }
	);

	updateNotificationPreferences$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PreferencesActions.updateNotificationPreferences),
			switchMap(notificationPreference =>
				this.ṕreferencesService
					.updateNotificationPreferences$(notificationPreference)
					.pipe(
						map((user: User) =>
							PreferencesActions.updateNotificationPreferencesSuccess({
								notificationPreferences: user.preferences.notification,
							})
						),
						catchError(error =>
							of(
								PreferencesActions.updateNotificationPreferencesFailure({
									error,
								})
							)
						)
					)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private ṕreferencesService: PreferencesService,
		private store$: Store<any>,
		private alertService: AlertService
	) {}
}
