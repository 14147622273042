<div class="qrt_personal">
	<form
		class="qrt_personal__form"
		clrForm
		clrLayout="vertical"
		[formGroup]="changePersonalDataForm"
		(ngSubmit)="changePersonalData()"
		id="personal-form"
	>
		<clr-input-container class="qrt_personal__email-container">
			<label class="qrt_personal__label">{{ 'Correio eletrónico' | t }}</label>
			<input
				class="qrt_personal__input"
				clrInput
				name="email"
				id="email"
				formControlName="email"
			/>
			<clr-control-error
				>{{ 'Campo de preenchimento obrigatório' | t }}
			</clr-control-error>
		</clr-input-container>

		<clr-select-container>
			<label>País</label>
			<select clrSelect name="country" id="country" formControlName="country">
				<option
					*ngFor="let country of countryCodes; let i = index"
					[value]="countryCodes[i].dial_code"
				>
					{{ countryCodes[i].code }} {{ countryCodes[i].dial_code }}
				</option>
			</select>
		</clr-select-container>

		<clr-input-container>
			<label>Num. telefónico</label>
			<input clrInput name="phone" id="phone" formControlName="phone" />
			<clr-control-error
				>{{ 'Campo de preenchimento obrigatório' | t }}
			</clr-control-error>
		</clr-input-container>
	</form>

	<div class="qrt_personal__btns">
		<button
			[clrLoading]="submitBtnState"
			class="btn btn-primary"
			form="personal-form"
			[disabled]="!changePersonalDataForm.valid"
		>
			{{ 'Alterar' | t }}
		</button>
		<button type="button" class="btn" routerLink="/map">
			{{ 'Cancelar' | t }}
		</button>
	</div>
</div>
