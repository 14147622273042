import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert } from 'app/core/models/alert';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	private subject = new Subject<any>();

	onAlert(): Observable<Alert> {
		return this.subject.asObservable();
	}

	success(message: string) {
		this.alert(new Alert({ type: 'success', message }));
	}

	error(message: string) {
		this.alert(new Alert({ type: 'danger', message }));
	}

	warning(message: string) {
		this.alert(new Alert({ type: 'warning', message }));
	}

	alert(alert: Alert) {
		this.subject.next(alert);
	}

	clear() {
		this.subject.next(false);
	}

	constructor() {}
}
