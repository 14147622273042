export const possibleOperators = {
	EQ: 'Igual',
	GT: 'Maior',
	GTE: 'Maior ou igual',
	LT: 'Menor',
	LTE: 'Menor ou igual',
	NE: 'Diferente',
};

export const possiblePeriods = {
	'10mn': '10 minutos',
	'1h': '1 hora',
	'8h': '8 horas',
	'1d': '1 dia',
	'1w': '1 semana',
	'1m': '1 mês',
	'1y': '1 ano',
};

export const possibleSensors = {
	HUMEXT: 'Humidade',
	HUMINT: 'Humidade Interior',
	TEMPEXT: 'Temperatura',
	TEMPINT: 'Temperatura Interior',
	PARTICULES_MASS: 'PM MASS',
	PARTICULES_PM05: 'PM 0.5',
	PARTICULES_PM07: 'PM 0.7',
	PARTICULES_PM01: 'PM 1',
	PARTICULES_PM25: 'PM 2.5',
	PARTICULES_PM10: 'PM 10',
	NOISE: 'LAeq,T',
	PRESSURE: 'Pressão',
	METEOWINDSPEED: 'Velocidade do vento',
	METEOWINDDIRECTION: 'Direção do vento',
	METEOTEMPERATURE: 'Temperatura meteorológica',
	METEOHUMIDITY: 'Humidade meteorológica',
	METEOPRESSURE: 'Pressão meteorológica',
	METEOPRECIPITATION: 'Precipitação',
};
