const filterLocations = (search: string, locations: any[], devices: any[]) => {
	const searchLC = search.toLowerCase();

	return locations.filter((location) => {
		const device = devices.find(
			({ currentLUI }) => currentLUI === location.LUI
		);
		const locationDescription = location.Description.toLowerCase();

		if (device?.DUI.includes(searchLC)) {
			return true;
		}

		if (location.LUI === Number(search)) {
			return true;
		}

		if (locationDescription.includes(searchLC)) {
			return true;
		}
	});
};

export { filterLocations };
