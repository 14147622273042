import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { DetailedLastMeasurementActions } from 'app/devices/actions';
import * as fromAuth from 'app/auth/reducers';

import { MeasurementsService } from 'app/devices/services/measurements.service';

@Injectable()
export class DetailedLastMeasurementEffects {
	loadDeitaledLastMeasurements$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DetailedLastMeasurementActions.loadDetailedLastMeasurements),
			mergeMap((action) =>
				this.measurementsService.getAllDetailedLastMeasurements$().pipe(
					map((detailedLastMeasurements) =>
						DetailedLastMeasurementActions.loadDetailedLastMeasurementsSuccess({
							detailedLastMeasurements,
						})
					),
					catchError((error) =>
						of(
							DetailedLastMeasurementActions.loadDetailedLastMeasurementsFailure(
								{
									error,
								}
							)
						)
					)
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private measurementsService: MeasurementsService
	) {}
}
