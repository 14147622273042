import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notification } from 'app/core/models/notification';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private http: HttpClient) {}

	getAllNotifications(): Observable<Notification[]> {
		return this.http.get<Notification[]>(`${environment.api}/notifications`);
	}

	getAllUnreadNotifications(): Observable<Notification[]> {
		return this.http.get<Notification[]>(
			`${environment.api}/notifications/unread`
		);
	}

	createNotification(userId: string, message: string): Observable<any> {
		return this.http.post(`${environment.api}/notifications`, {
			userId,
			message,
		});
	}

	markAsRead$(notificationId: string): Observable<any> {
		return this.http.post(
			`${environment.api}/notifications/${notificationId}/read`,
			{}
		);
	}
}
