import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { ClrLoadingState } from '@clr/angular';

import * as fromRoot from 'app/reducers';
import * as fromAuth from 'app/auth/reducers';
import { NotificationActions } from 'app/auth/actions';
import { LayoutActions } from 'app/core/actions';

@Component({
	selector: 'qrt-create-notification',
	templateUrl: './create-notification.component.html',
	styleUrls: ['./create-notification.component.scss'],
})
export class CreateNotificationComponent implements OnInit, OnDestroy {
	notificationForm: FormGroup;
	opened$: Observable<boolean>;
	selectedUser$: Observable<any>;
	selectedUser$ub: Subscription;

	sendBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	constructor(
		private store$: Store<fromAuth.State>,
		private actionsSubject: ActionsSubject,
		private fb: FormBuilder
	) {
		this.notificationForm = fb.group({
			userId: [''],
			text: ['', Validators.required],
		});

		this.opened$ = store$.pipe(
			select(fromRoot.selectShowNotificationCreatorModal)
		);

		this.selectedUser$ = store$.pipe(select(fromAuth.selectSelectedUser));
	}

	ngOnInit(): void {
		this.selectedUser$ub = this.selectedUser$.subscribe(user => {
			this.notificationForm.patchValue({ userId: user._id });
		});
	}

	sendNotification(): void {
		this.sendBtnState = ClrLoadingState.LOADING;

		const userId = this.notificationForm.get('userId').value;
		const message = this.notificationForm.get('text').value;

		this.store$.dispatch(
			NotificationActions.createNotification({ userId, message })
		);

		this.actionsSubject
			.pipe(ofType(NotificationActions.createNotificationSuccess), take(1))
			.subscribe(() => {
				this.sendBtnState = ClrLoadingState.SUCCESS;

				setTimeout(
					() =>
						this.store$.dispatch(LayoutActions.closeNotificationCreatorModal()),
					800
				);
			});
	}

	closeModal(): void {
		this.store$.dispatch(LayoutActions.closeNotificationCreatorModal());
	}

	ngOnDestroy(): void {
		this.selectedUser$ub.unsubscribe();
	}
}
