import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { ClrLoadingState } from '@clr/angular';

import * as fromRoot from 'app/reducers';
import * as fromDevices from 'app/devices/reducers';
import { BoxActions } from 'app/devices/actions';
import { LayoutActions } from 'app/core/actions';
import { BoxDiagnosis } from 'app/devices/models/box.model';

@Component({
	selector: 'qrt-device-diagnosis',
	templateUrl: './device-diagnosis.component.html',
	styleUrls: ['./device-diagnosis.component.scss'],
})
export class DeviceDiagnosisComponent implements OnInit, OnDestroy {
	showModal$: Observable<boolean>;

	diagnosisForm: FormGroup;

	requestDiagnosisBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	diagnosis$: Observable<BoxDiagnosis['Data']>;
	diagnosisLoading$: Observable<boolean>;
	requestingDiagnosis$: Observable<boolean>;
	stopRequestDiagnosis$ub: Subscription;

	selectDui$ub: Subscription;

	requestInterval;

	constructor(
		private store$: Store<fromDevices.State>,
		private actionsSubject: ActionsSubject,
		private fb: FormBuilder
	) {
		this.diagnosisForm = fb.group({
			dui: ['', Validators.required],
		});

		this.showModal$ = store$.pipe(
			select(fromRoot.selectShowDeviceDiagnosisModal)
		);

		this.diagnosis$ = store$.pipe(select(fromDevices.selectBoxDiagnosisData));
		this.requestingDiagnosis$ = store$.pipe(
			select(fromDevices.selectRequestingBoxDiagnosis)
		);
		this.diagnosisLoading$ = store$.pipe(
			select(fromDevices.selectBoxDiagnosisLoading)
		);
	}

	ngOnInit(): void {
		this.selectDui$ub = this.actionsSubject
			.pipe(ofType(BoxActions.selectDuiToDiagnosis))
			.subscribe(({ dui }) => {
				this.diagnosisForm.patchValue({ dui });
			});

		this.stopRequestDiagnosis$ub = this.actionsSubject
			.pipe(
				ofType(
					BoxActions.stopRequestingDiagnosis,
					LayoutActions.closeDeviceDiagnosisModal
				)
			)
			.subscribe(() => {
				this.requestDiagnosisBtnState = ClrLoadingState.DEFAULT;
				clearInterval(this.requestInterval);
			});
	}

	get dui() {
		return this.diagnosisForm.get('dui');
	}

	openModal(): void {
		this.diagnosisForm.reset();
		this.store$.dispatch(LayoutActions.openDeviceDiagnosisModal());
	}

	closeModal(): void {
		this.store$.dispatch(LayoutActions.closeDeviceDiagnosisModal());
	}

	requestDiagnosis(): void {
		const dui = this.diagnosisForm.controls.dui.value;

		this.requestDiagnosisBtnState = ClrLoadingState.LOADING;
		this.store$.dispatch(BoxActions.requestDiagnosis({ dui }));

		this.requestInterval = setInterval(
			() => this.store$.dispatch(BoxActions.requestDiagnosis({ dui })),
			10000
		);
	}

	stopRequestingDiagnosis(): void {
		this.store$.dispatch(BoxActions.stopRequestingDiagnosis());
	}

	ngOnDestroy(): void {
		this.selectDui$ub.unsubscribe();
		this.stopRequestDiagnosis$ub.unsubscribe();
		clearInterval(this.requestInterval);
	}
}
