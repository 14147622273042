import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { DeviceAlert } from 'app/devices/models/device-alert.model';
import { BoxActions, DeviceAlertActions } from 'app/devices/actions';
import * as DeviceAlertSelectors from 'app/devices/selectors/device-alert.selectors';
import * as BoxesSelectors from 'app/devices/selectors/box.selectors';
import * as fromDevices from 'app/devices/reducers';
import { Box } from 'app/devices/models/box.model';

import {
	possibleOperators,
	possiblePeriods,
	possibleSensors,
} from 'app/shared/helpers/mapped-values';

import { isEmpty } from 'app/shared/helpers/lang';

@Component({
	selector: 'qrt-alertlist',
	templateUrl: './alertlist.component.html',
	styleUrls: ['./alertlist.component.scss'],
})
export class AlertListComponent implements OnInit {
	deviceAlerts$: Observable<DeviceAlert[]>;
	boxes$: Observable<Box[]>;
	boxesLoaded$: Observable<boolean>;

	operators = possibleOperators;
	periods = possiblePeriods;

	constructor(private store$: Store<any>) {
		this.deviceAlerts$ = store$.pipe(
			select(DeviceAlertSelectors.selectAllDeviceAlerts)
		);
		this.boxes$ = store$.pipe(select(fromDevices.selectAllBoxes));
		this.boxesLoaded$ = store$.pipe(select(fromDevices.selectBoxesLoaded));
	}

	ngOnInit(): void {
		this.boxesLoaded$
			.pipe(
				filter(loaded => !loaded),
				take(1)
			)
			.subscribe(() => {
				this.store$.dispatch(BoxActions.loadBoxes());
			});

		this.store$
			.pipe(
				select(DeviceAlertSelectors.selectDeviceAlertsLoaded),
				filter(loaded => !loaded),
				take(1)
			)
			.subscribe(() =>
				this.store$.dispatch(DeviceAlertActions.loadDeviceAlerts())
			);
	}

	getStatusIconShape(enabled: boolean) {
		return enabled ? 'check' : 'times';
	}

	getDeviceByDui(DUI: string) {
		return this.store$.pipe(
			select(BoxesSelectors.selectDeviceByDui, { DUI }),
			filter(box => !isEmpty(box)),
			take(1)
		);
	}

	getSensorPTName(sensorSlug: string) {
		return possibleSensors[sensorSlug] || sensorSlug;
	}
}
