import { Component, OnInit } from '@angular/core';

import { environment } from 'environments/environment';

@Component({
	selector: 'qrt-report-header',
	templateUrl: './report-header.component.html',
	styleUrls: ['./report-header.component.scss'],
})
export class ReportHeaderComponent implements OnInit {
	logoPath: string = environment.logoPath;

	constructor() {}

	ngOnInit(): void {}

	print() {
		window.print();
	}
}
