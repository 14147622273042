import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, filter, take, withLatestFrom } from 'rxjs/operators';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { ClrLoadingState } from '@clr/angular';

import * as fromDevices from 'app/devices/reducers';
import * as BoxesSelectors from 'app/devices/selectors/box.selectors';
import { Box } from 'app/devices/models/box.model';
import { DeviceAlert } from 'app/devices/models/device-alert.model';
import { BoxActions, DeviceAlertActions } from 'app/devices/actions';

import {
	possibleOperators,
	possiblePeriods,
	possibleSensors,
} from 'app/shared/helpers/mapped-values';

@Component({
	selector: 'qrt-create-alert',
	templateUrl: './create-alert.component.html',
	styleUrls: ['./create-alert.component.scss'],
})
export class CreateAlertComponent implements OnInit {
	open: boolean = false;

	operators = possibleOperators;
	periods = possiblePeriods;

	createAlertForm: FormGroup = this.fb.group({
		boxinfo: this.fb.group({
			label: [''],
			dui: ['', Validators.required],
			sensor: ['', Validators.required],
		}),
		condition: this.fb.group({
			operator: ['GT', Validators.required],
			value: [0, [Validators.required, Validators.min(0)]],
			period: ['1h', Validators.required],
		}),
		action: this.fb.group({
			alertBySms: [false, Validators.required],
			alertByEmail: [false, Validators.required],
			callback: [false, Validators.required],
		}),
	});

	callbackForm: FormGroup = this.fb.group({
		url: [
			'',
			Validators.compose([
				Validators.required,
				Validators.pattern(
					new RegExp(
						/((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
					)
				),
			]),
		],
		method: ['', Validators.required],
	});

	callbackAction$: Observable<boolean>;

	createBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	selectedBoxSensors$: Observable<any>;

	boxes$: Observable<Box[]>;

	exampleReqBody = {
		device: '000001XXX',
		sensor: 'CO',
		unit: 'mg/m3',
		operator: 'GT',
		value: 30,
		period: '1h',
		timeStamp: new Date(),
	};

	constructor(
		private store$: Store<any>,
		private actionsSubject: ActionsSubject,
		private fb: FormBuilder
	) {
		this.selectedBoxSensors$ = store$.pipe(
			select(fromDevices.selectAllSensorsOfSelectedBox)
		);
		this.boxes$ = store$.pipe(select(fromDevices.selectAllBoxes));
		this.callbackAction$ = this.createAlertForm.valueChanges.pipe(
			map(formValues => formValues.action.callback)
		);
	}

	get selectedDui(): string {
		return this.createAlertForm.get('boxinfo.dui').value;
	}

	get selectedSensor(): string {
		return this.createAlertForm.get('boxinfo.sensor').value;
	}

	getSelectedSensorUnit$(DUI: string, sensor: string): Observable<string> {
		return this.store$.pipe(
			select(BoxesSelectors.selectSensorUnit, { DUI, sensor })
		);
	}

	ngOnInit(): void {}

	openModal(): void {
		this.open = true;
	}

	closeModal(): void {
		this.open = false;
		this.createAlertForm.reset();
	}

	selectBox(DUI): void {
		this.store$.dispatch(BoxActions.selectBox({ id: DUI }));
	}

	getSensorPTName(sensorSlug: string) {
		return possibleSensors[sensorSlug] || sensorSlug;
	}

	async createAlert(): Promise<any> {
		const { boxinfo, condition, action } = this.createAlertForm.value;

		const unit = await this.getSelectedSensorUnit$(boxinfo.dui, boxinfo.sensor)
			.pipe(take(1))
			.toPromise();

		const newAlert: DeviceAlert = {
			label: boxinfo.label,
			DUI: boxinfo.dui,
			sensor: boxinfo.sensor,
			unit,
			operator: condition.operator,
			value: parseFloat(condition.value),
			period: condition.period,
			action,
		};

		if (action.callback) {
			newAlert.callback = this.callbackForm.value;
		}

		this.store$.dispatch(DeviceAlertActions.createDeviceAlert(newAlert));

		this.actionsSubject
			.pipe(ofType(DeviceAlertActions.createDeviceAlertSuccess), take(1))
			.subscribe(() => {
				this.createBtnState = ClrLoadingState.SUCCESS;

				this.closeModal();
			});
	}
}
