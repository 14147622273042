<div class="btn-group qrt_presentation-controller">
	<div class="radio btn qrt_presentation-controller__btn-container">
		<input
			type="radio"
			name="show-data-type-radios"
			id="chart"
			(change)="presentationTypeChange($event)"
			checked
		/>
		<label for="chart"
			><clr-icon shape="bar-chart" class="is-solid"></clr-icon>
			<span class="qrt_presentation-controller__type">Gráfico</span></label
		>
	</div>

	<div class="radio btn qrt_presentation-controller__btn-container">
		<input
			type="radio"
			name="show-data-type-radios"
			id="chart-comparison"
			(change)="presentationTypeChange($event)"
		/>
		<label for="chart-comparison"
			><clr-icon shape="bar-chart" class="is-solid"></clr-icon>
			<span class="qrt_presentation-controller__type">Gráfico Comparativo</span></label
		>
	</div>


	<div class="radio btn qrt_presentation-controller__btn-container">
		<input
			type="radio"
			name="show-data-type-radios"
			id="table"
			(change)="presentationTypeChange($event)"
		/>
		<label for="table">
			<clr-icon shape="table"></clr-icon>
			<span class="qrt_presentation-controller__type">Tabela</span></label
		>
	</div>

	<div
		class="radio btn qrt_presentation-controller__btn-container qrt_presentation-controller__btn-container--out"
		*ngIf="(isMeteoStation$ | async) && (hasWindDirectionSensor$ | async)"
	>
		<input
			type="radio"
			name="show-data-type-radios"
			id="wind-rose"
			(change)="presentationTypeChange($event)"
		/>
		<label for="wind-rose">
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style="enable-background: new 0 0 512 512"
				xml:space="preserve"
				class="qrt_presentation-controller__wind-rose-icon"
				[ngClass]="{
					'qrt_presentation-controller__wind-rose-icon--activated':
						isTypeActivated$('wind-rose') | async
				}"
			>
				<g>
					<g>
						<path
							d="M506.052,247.868l-130.517-41.523l41.941-100.659c1.314-3.157,0.623-6.784-1.758-9.233
			c-2.381-2.449-5.99-3.217-9.182-2.014l-101.794,39.151L264.132,5.948C263.006,2.406,259.721,0,256,0
			c-3.721,0-7.006,2.406-8.132,5.948l-40.61,127.642L105.464,94.438c-3.191-1.212-6.801-0.435-9.182,2.014
			c-2.381,2.449-3.072,6.076-1.758,9.233l41.941,100.659L5.948,247.868C2.406,248.994,0,252.279,0,256
			c0,3.721,2.406,7.006,5.948,8.132l130.517,41.523L94.524,406.315c-1.314,3.157-0.623,6.784,1.758,9.233
			c1.638,1.69,3.857,2.586,6.118,2.586c1.033,0,2.065-0.188,3.063-0.563l101.794-39.151l40.61,127.642
			c1.126,3.533,4.412,5.939,8.132,5.939c3.721,0,7.006-2.406,8.132-5.948l40.61-127.642l101.794,39.151
			c0.998,0.384,2.031,0.572,3.063,0.572c2.261,0,4.48-0.896,6.118-2.586c2.381-2.449,3.072-6.076,1.758-9.233l-41.941-100.659
			l130.517-41.523c3.541-1.126,5.948-4.412,5.948-8.132C512,252.279,509.594,248.994,506.052,247.868z M202.069,149.871
			l-9.685,30.447l-55.526-55.526L202.069,149.871z M126.865,138.931l53.453,53.453l-27.537,8.764L126.865,138.931z M193.903,205.978
			l41.498,41.489H63.497L193.903,205.978z M63.497,264.533h171.904l-41.489,41.489L63.497,264.533z M152.789,310.852l27.529,8.764
			l-53.453,53.453L152.789,310.852z M136.866,387.2l55.526-55.526l9.685,30.438L136.866,387.2z M247.467,448.503l-41.489-130.406
			l41.489-41.498V448.503z M247.467,235.401l-41.489-41.489l41.489-130.415V235.401z M359.211,201.148l-27.529-8.764l53.453-53.453
			L359.211,201.148z M375.134,124.8l-55.526,55.526l-9.685-30.438L375.134,124.8z M264.533,63.497l41.489,130.406l-41.489,41.498
			V63.497z M264.533,448.503V276.599l41.489,41.489L264.533,448.503z M309.931,362.129l9.685-30.447l55.526,55.526L309.931,362.129z
			 M385.135,373.069l-53.453-53.453l27.537-8.764L385.135,373.069z M318.097,306.022l-41.498-41.489h171.904L318.097,306.022z
			 M276.599,247.467l41.489-41.489l130.415,41.489H276.599z"
						/>
					</g>
				</g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
			</svg>

			<span class="qrt_presentation-controller__type"
				>Rosa dos ventos</span
			></label
		>
	</div>
</div>
