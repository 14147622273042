import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  public collapsed: boolean = true;
  public personalView: boolean = true;
  public passwordView: boolean = false;
  public alertsView: boolean = false;

  ngOnInit() {}

  toggleView(viewName: string) {
    const possibleViews: Array<string> = [
      'personalView',
      'passwordView',
      'alertsView',
    ];

    for (let view of possibleViews) {
      view === viewName ? (this[view] = true) : (this[view] = false);
    }
  }
}