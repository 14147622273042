import { createAction, props } from '@ngrx/store';
import { Location } from 'app/devices/models/location';
import { NewIssueForm } from 'app/devices/reducers/box.reducer';

export const loadLocations = createAction('[Location/API] Load Locations');

export const loadLocationsSuccess = createAction(
	'[Location/API] Load Locations Success',
	props<{ locations: Location[] }>()
);

export const loadLocationsFailure = createAction(
	'[Location/API] Load Locations Failure',
	props<{ error: any }>()
);

export const loadIssues = createAction('[Location/API] Load Issues');

export const loadIssuesSuccess = createAction(
	'[Location/API] Load Issues Success',
	props<{ issues: any[] }>()
);

export const loadIssuesFailure = createAction(
	'[Location/API] Load Issues Failure',
	props<{ error: any[] }>()
);

export const createNewIssue = createAction(
	'[Location/API] Create New Issue',
	props<{ data: NewIssueForm }>()
);

export const createNewIssueSuccess = createAction(
	'[Location/API] Create New Issue Success'
);

export const createNewIssueFailure = createAction(
	'[Location/API] Create New Issue Failure',
	props<{ error: any }>()
);
