<clr-dropdown class="qrt_chart-options">
	<button
		type="button"
		class="qrt_chart-options__trigger-btn"
		data-testid="trigger-dropdown-btn"
		clrDropdownTrigger
	>
		<span>Opções</span>
		<clr-icon shape="caret down"></clr-icon>
	</button>
	<clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
		<h4 class="dropdown-header">Gráfico</h4>

		<button (click)="changeChartType('bar')" clrDropdownItem>
			<clr-icon shape="bar-chart"></clr-icon>
			Barras
		</button>
		<button (click)="changeChartType('line')" clrDropdownItem>
			<clr-icon shape="line-chart"></clr-icon>
			Linhas
		</button>
		<button (click)="changeChartType('area')" clrDropdownItem>
			<clr-icon shape="curve-chart"></clr-icon>
			Área
		</button>

		<div *ngIf="sensorConfig?.limits as limits">
			<div class="dropdown-divider"></div>

			<div
				role="region"
				aria-labelledby="limits-heading"
				data-testid="limits-region"
			>
				<h2 id="limits-heading" class="dropdown-header">Limites</h2>

				<div class="qrt_chart-options__limits">
					<div
						class="clr-checkbox-wrapper"
						*ngFor="let limit of limits; let i = index"
					>
						<input
							type="checkbox"
							id="limit-{{ i }}"
							clrcheckbox=""
							(change)="toggleLimitVisible(i)"
							[checked]="limit.visible"
						/>
						<label class="clr-control-label" for="limit-{{ i }}">{{
							limit.name
						}}</label>
					</div>
				</div>
			</div>
		</div>
	</clr-dropdown-menu>
</clr-dropdown>
