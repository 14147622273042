import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take, filter, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { ClrLoadingState } from '@clr/angular';

import * as fromDevices from 'app/devices/reducers';
import * as fromRoot from 'app/reducers';
import * as BoxesSelectors from 'app/devices/selectors/box.selectors';
import { BoxActions, DeviceAlertActions } from 'app/devices/actions';
import { LayoutActions } from 'app/core/actions';
import { DeviceAlert } from 'app/devices/models/device-alert.model';
import * as DeviceAlertSelectors from 'app/devices/selectors/device-alert.selectors';

import { isEmpty } from 'app/shared/helpers/lang';

import {
	possibleOperators,
	possiblePeriods,
} from 'app/shared/helpers/mapped-values';

@Component({
	selector: 'qrt-edit-alert',
	templateUrl: './edit-alert.component.html',
	styleUrls: ['./edit-alert.component.scss'],
})
export class EditAlertComponent implements OnInit {
	@Input() alertId: string;
	@Input() alertDUI: string;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	showModal$: Observable<boolean>;

	alert$: Observable<DeviceAlert>;

	editAlertForm: FormGroup = this.fb.group({
		label: [''],
		sensor: [''],
		period: [''],
		operator: [''],
		value: [0],
		action: this.fb.group({
			alertBySms: [false],
			alertByEmail: [false],
			callback: [false],
		}),
		enabled: [false],
	});

	callbackForm: FormGroup = this.fb.group({
		url: [
			'',
			Validators.compose([
				Validators.required,
				Validators.pattern(
					new RegExp(
						/((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
					)
				),
			]),
		],
		method: ['', Validators.required],
	});

	callbackAction$: Observable<boolean>;

	get selectedSensor(): string {
		return this.editAlertForm.get('sensor').value;
	}

	selectedBoxSensors$: Observable<any>;

	operators = possibleOperators;
	periods = possiblePeriods;

	constructor(private store$: Store<any>, private fb: FormBuilder) {
		this.showModal$ = store$.pipe(
			select(fromRoot.selectShowEditAlertModal),
			withLatestFrom(store$.pipe(select(fromRoot.selectAlertIdEditAlert))),
			map(([show, id]) => show && id === this.alertId)
		);
		this.selectedBoxSensors$ = store$.pipe(
			select(fromDevices.selectAllSensorsOfSelectedBox)
		);
		this.callbackAction$ = this.editAlertForm.valueChanges.pipe(
			map(formValues => formValues.action.callback)
		);
	}

	ngOnInit(): void {
		this.alert$ = this.store$.pipe(
			select(DeviceAlertSelectors.selectDeviceAlert, { id: this.alertId })
		);
	}

	openModal() {
		this.store$.dispatch(
			LayoutActions.openEditAlertModal({ id: this.alertId })
		);

		this.store$.dispatch(BoxActions.selectBox({ id: this.alertDUI }));

		this.alert$
			.pipe(
				filter(alerts => alerts && !isEmpty(alerts)),
				take(1)
			)
			.subscribe(alert => {
				const {
					label,
					sensor,
					period,
					operator,
					value,
					action,
					enabled,
					callback,
				} = alert;

				this.editAlertForm.patchValue({
					label,
					sensor,
					period,
					operator,
					value,
					action,
					enabled,
				});

				this.callbackForm.patchValue(callback);
			});
	}

	closeModal() {
		this.store$.dispatch(LayoutActions.closeEditAlertModal());
	}

	getSelectedSensorUnit$(DUI: string, sensor: string): Observable<string> {
		return this.store$.pipe(
			select(BoxesSelectors.selectSensorUnit, { DUI, sensor }),
			take(1)
		);
	}

	updateAlert() {
		const {
			label,
			sensor,
			period,
			operator,
			value,
			action,
			enabled,
		} = this.editAlertForm.value;

		const callback = this.callbackForm.value;

		this.store$.dispatch(
			DeviceAlertActions.updateDeviceAlert({
				alert: {
					id: this.alertId,
					changes: {
						label,
						sensor,
						period,
						operator,
						value: parseFloat(value),
						action,
						enabled,
						callback,
					},
				},
			})
		);

		this.submitBtnState = ClrLoadingState.SUCCESS;

		this.closeModal();
	}
}
