import {
	createFeatureSelector,
	createSelector,
	MetaReducer,
	Action,
	ActionReducer,
	ActionReducerMap,
} from '@ngrx/store';
import { InjectionToken } from '@angular/core';

import { environment } from 'environments/environment';

import * as fromLayout from 'app/core/reducers/layout.reducer';

/**
 * Our top level state interface is just a map of keys to inner state types.
 */
export interface State {
	[fromLayout.layoutFeatureKey]: fromLayout.State;
}

/**
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const rootReducers = new InjectionToken<ActionReducerMap<State, Action>>(
	'Root reducers token',
	{
		factory: () => ({
			[fromLayout.layoutFeatureKey]: fromLayout.reducer,
		}),
	}
);

/**
 * Provide an array of meta-reducers that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = [];

export const selectLayoutState = createFeatureSelector<State, fromLayout.State>(
	fromLayout.layoutFeatureKey
);

export const selectShowNotificationCreatorModal = createSelector(
	selectLayoutState,
	fromLayout.selectShowNotificationCreatorModal
);

export const selectShowEditAlertModal = createSelector(
	selectLayoutState,
	fromLayout.selectShowEditAlertModal
);

export const selectShowChangeDisabledStatusModal = createSelector(
	selectLayoutState,
	fromLayout.getShowChangeDisabledStatusModal
);

export const selectSubmitChangeDisabledStatusButtonState = createSelector(
	selectLayoutState,
	fromLayout.getSubmitChangeDisabledStatusButtonState
);

export const selectSubmitCreateNewIssueButtonState = createSelector(
	selectLayoutState,
	fromLayout.getSubmitCreateNewDeviceButtonState
);

// Id of the current alert being edited
export const selectAlertIdEditAlert = createSelector(
	selectLayoutState,
	fromLayout.selectAlertIdEditAlert
);

export const selectShowDeviceDiagnosisModal = createSelector(
	selectLayoutState,
	fromLayout.selectShowDeviceDiagnosisModal
);

export const selectShowRequestPasswordResetModal = createSelector(
	selectLayoutState,
	fromLayout.getShowRequestPasswordResetModal
);

// Device details
export const selectDeviceDetailsPresentationType = createSelector(
	selectLayoutState,
	fromLayout.selectDeviceDetailsPresentationType
);

export const selectDeviceDetailsChartType = createSelector(
	selectLayoutState,
	fromLayout.selectDeviceDetailsChartType
);

export const selectChartMaxValueMargin = createSelector(
	selectLayoutState,
	fromLayout.selectChartMaxValueMargin
);

export const selectShowComparisonModal = createSelector(
	selectLayoutState,
	fromLayout.getShowComparisonModal
);

// Sensor limits
export const selectSensorConfig = createSelector(
	selectLayoutState,
	fromLayout.selectSensorConfig
);

export const selectSensorConfigLimits = createSelector(
	selectSensorConfig,
	cfg => cfg?.limits
);

// Issues
export const selectShowNewIssueModal = createSelector(
	selectLayoutState,
	fromLayout.getShowNewIssueModal
);
