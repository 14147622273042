import { createAction, props } from '@ngrx/store';
import { ClrLoadingState } from '@clr/angular';
import { presentationType, chartType } from 'app/core/reducers/layout.reducer';

export const openNotificationCreatorModal = createAction(
	'[Layout] Open Notification Creator Modal'
);

export const closeNotificationCreatorModal = createAction(
	'[Layout] Close Notification Creator Modal'
);

export const openEditAlertModal = createAction(
	'[Layout] Open Edit Alert Modal',
	props<{ id: any }>()
);

export const closeEditAlertModal = createAction(
	'[Layout] Close Edit Alert Modal'
);

export const openDeviceDiagnosisModal = createAction(
	'[Layout] Open Device Diagnosis Modal'
);

export const closeDeviceDiagnosisModal = createAction(
	'[Layout] Close Device Diagnosis Modal'
);

export const changeDeviceDetailsPresentationType = createAction(
	'[Layout] Change Device Details Presentation Type',
	props<{ presType: presentationType }>()
);

export const changeDeviceDetailsChartType = createAction(
	'[Layout] Change Device Details Chart Type',
	props<{ chartType: chartType }>()
);

export const changeSensorConfig = createAction(
	'[Layout] Change Sensor Config',
	props<{ config: { name: string; limits: any[] } }>()
);

export const toggleLimitVisible = createAction(
	'[Layout] Toggle Limit Visible',
	props<{ index: number }>()
);

export const changeChartMaxValueMarginBasedOnLimits = createAction(
	'[Layout] Change Chart Max Value Margin Based on Limits'
);

export const changeChartMaxValueMargin = createAction(
	'[Layout] Change Chart Max Value Margin',
	props<{ value: number }>()
);

export const openChangeDisabledStatusModal = createAction(
	'[Layout] Open Change Disabled Status Modal'
);

export const closeChangeDisabledStatusModal = createAction(
	'[Layout] Close Change Disabled Status Modal'
);

export const updateChangeDisabledStatusSubmitButtonState = createAction(
	'[Layout] Update Change Disabled Status Submit Button State',
	props<{ loadingState: ClrLoadingState }>()
);

export const updateSubmitCreateNewIssueButtonState = createAction(
	'[Layout] Update Submit Create New Issue Button State',
	props<{ loadingState: ClrLoadingState }>()
);

export const openRequestPasswordResetModal = createAction(
	'[Layout] Open Request Password Reset Modal'
);

export const closeRequestPasswordResetModal = createAction(
	'[Layout] Close Request Password Reset Modal'
);

export const openComparisonModal = createAction(
	'[Layout] Open Comparison Modal'
);

export const closeComparisonModal = createAction(
	'[Layout] Close Comparison Modal'
);

export const openNewIssueModal = createAction('[Layout] Open New Issue Modal');

export const closeNewIssueModal = createAction(
	'[Layout] Close New Issue Modal'
);
