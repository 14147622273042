import { createAction, props } from '@ngrx/store';

import { AvgPeriod } from 'app/devices/models/box.model';

export const selectAvgPeriod = createAction(
	'[BoxPreference] Select avg period',
	props<{ avgPeriod: AvgPeriod }>()
);

export const selectDate = createAction(
	'[BoxPreference] Select Date',
	props<{ date: string }>()
);