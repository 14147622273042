import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { DetailedLastMeasurement } from '../models/detailed-last-measurement.model';

export const loadDetailedLastMeasurements = createAction(
	'[DetailedLastMeasurement/API] Load DetailedLastMeasurements'
);

export const loadDetailedLastMeasurementsSuccess = createAction(
	'[DetailedLastMeasurement/API] Load DetailedLastMeasurements Success',
	props<{ detailedLastMeasurements: DetailedLastMeasurement[] }>()
);

export const loadDetailedLastMeasurementsFailure = createAction(
	'[DetailedLastMeasurement/API] Load DetailedLastMeasurements Failure',
	props<{ error: any }>()
);

export const addDetailedLastMeasurement = createAction(
	'[DetailedLastMeasurement/API] Add DetailedLastMeasurement',
	props<{ detailedLastMeasurement: DetailedLastMeasurement }>()
);

export const upsertDetailedLastMeasurement = createAction(
	'[DetailedLastMeasurement/API] Upsert DetailedLastMeasurement',
	props<{ detailedLastMeasurement: DetailedLastMeasurement }>()
);

export const addDetailedLastMeasurements = createAction(
	'[DetailedLastMeasurement/API] Add DetailedLastMeasurements',
	props<{ detailedLastMeasurements: DetailedLastMeasurement[] }>()
);

export const upsertDetailedLastMeasurements = createAction(
	'[DetailedLastMeasurement/API] Upsert DetailedLastMeasurements',
	props<{ detailedLastMeasurements: DetailedLastMeasurement[] }>()
);

export const updateDetailedLastMeasurement = createAction(
	'[DetailedLastMeasurement/API] Update DetailedLastMeasurement',
	props<{ detailedLastMeasurement: Update<DetailedLastMeasurement> }>()
);

export const updateDetailedLastMeasurements = createAction(
	'[DetailedLastMeasurement/API] Update DetailedLastMeasurements',
	props<{ detailedLastMeasurements: Update<DetailedLastMeasurement>[] }>()
);

export const deleteDetailedLastMeasurement = createAction(
	'[DetailedLastMeasurement/API] Delete DetailedLastMeasurement',
	props<{ id: string }>()
);

export const deleteDetailedLastMeasurements = createAction(
	'[DetailedLastMeasurement/API] Delete DetailedLastMeasurements',
	props<{ ids: string[] }>()
);

export const clearDetailedLastMeasurements = createAction(
	'[DetailedLastMeasurement/API] Clear DetailedLastMeasurements'
);
