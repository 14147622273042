import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewIssueForm } from 'app/devices/reducers/box.reducer';

import { Observable } from 'rxjs';

import { Location } from 'app/devices/models/location';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LocationService {
	constructor(private http: HttpClient) {}

	public getAll$(): Observable<Array<Location>> {
		return this.http.get<Array<Location>>(`${environment.api}/location`);
	}

	public getIssues$(): Observable<Array<any>> {
		return this.http.get<Array<any>>(`${environment.api}/issues`);
	}

	public createIssue$(formData: NewIssueForm): Observable<any> {
		return this.http.post<NewIssueForm>(`${environment.api}/issues`, formData);
	}
}
