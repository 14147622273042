<div class="qrt_side-chart">
	<dx-chart
		id="chart"
		[dataSource]="measurements$ | async"
		(onPointClick)="onPointClick($event)"
	>
		<dxi-series
			*ngFor="let device of devices$ | async"
			[valueField]="device.field"
			[name]="device.name"
		></dxi-series>
		<dxo-common-series-settings
			argumentField="dateObserved"
			type="line"
		>
		</dxo-common-series-settings>
		<dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPositon="bottom">
		</dxo-legend>
		<dxo-argument-axis argumentType="datetime" type="discrete">
			<dxo-label format="dd-MM-yyyy HH:mm"></dxo-label>
		</dxo-argument-axis>
	</dx-chart>
</div>
