import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SettingsService } from 'app/core/services/settings.service';

@Component({
  selector: 'qrt-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
	maintenance$: Observable<any>;

  constructor(private settingsService: SettingsService ) {
  	this.maintenance$ = settingsService.getMaintenance();
  }

  ngOnInit(): void {
  }

}
