<div class="qrt_measurements-wind-rose">
	<dx-polar-chart
		#chart
		id="radarChart"
		[palette]="[
			'#52f4ce',
			'#42c5f4',
			'#55f456',
			'#adf442',
			'#f4e142',
			'#f4a141'
		]"
		[dataSource]="measurements$ | async"
		(onLegendClick)="onLegendClick($event)"
	>
		<dxi-series
			*ngFor="let item of windSources$ | async"
			[valueField]="item.valueField"
			[name]="item.name"
		>
		</dxi-series>
		<dxo-common-series-settings type="stackedbar"></dxo-common-series-settings>
		<dxo-margin [bottom]="50" [left]="100"> </dxo-margin>
		<dxo-argument-axis
			discreteAxisDivisionMode="crossLabels"
			[firstPointOnStartAngle]="true"
		>
		</dxo-argument-axis>
		<dxo-value-axis [valueMarginsEnabled]="false"></dxo-value-axis>
		<dxo-export [enabled]="true"></dxo-export>
	</dx-polar-chart>

	<clr-radio-container clrInline>
		<label>Selecionar pontos</label>

		<clr-radio-wrapper>
			<input
				type="radio"
				clrRadio
				value="option1"
				name="options"
				(input)="changeCardinalDirections(4)"
			/>
			<label>4 Pontos</label>
		</clr-radio-wrapper>

		<clr-radio-wrapper>
			<input
				type="radio"
				clrRadio
				value="option2"
				name="options"
				(input)="changeCardinalDirections(8)"
			/>
			<label>8 Pontos</label>
		</clr-radio-wrapper>

		<clr-radio-wrapper>
			<input
				type="radio"
				clrRadio
				value="option3"
				name="options"
				[checked]="isDirectionsSelected$(16) | async"
				(input)="changeCardinalDirections(16)"
			/>
			<label>16 Pontos</label>
		</clr-radio-wrapper>
	</clr-radio-container>
</div>
