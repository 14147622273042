<nav class="subnav no-print qrt_subnav">
	<ul class="nav">
		<li class="nav-item">
			<a class="nav-link" routerLink="map" routerLinkActive="active">{{
				'Mapa' | t
			}}</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="devices" routerLinkActive="active">{{
				'Dispositivos' | t
			}}</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="alerts" routerLinkActive="active">{{
				'Alertas' | t
			}}</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" routerLink="support" routerLinkActive="active">
				Assistência Técnica
			</a>
		</li>
		<li *ngIf="isAdmin$ | async" class="nav-item">
			<a class="nav-link" routerLink="users" routerLinkActive="active">{{
				'Gerir utilizadores' | t
			}}</a>
		</li>
	</ul>
	<form class="qrt_meteotoggle clr-toggle-wrapper" [formGroup]="meteoFormSubNav">
		<input
			formControlName="meteoToggleSubNav"
			type="checkbox"
			id="toggle-meteo-subnav"
			name="toggle-meteo-subnav"
			value="meteo-on-subnav"
			class="clr-toggle"
			(change)="setMeteoPreference()"
		/>
		<label for="toggle-meteo-subnav">{{ 'Meteorologia' | t }}</label>
	</form>
</nav>
