<div class="progress top loop qrt_progress" *ngIf="!(measurements$ | async)">
	<progress max="100" value="0" data-displayval="0%"></progress>
</div>
<ng-container *ngIf="measurements$ | async">
	<section *ngIf="selectedBox$ | async as selectedBox" class="qrt_report">
		<qrt-report-header></qrt-report-header>
		<div class="qrt_report__card">
			<div class="qrt_report__inner-card">
				<div>
					<ul class="qrt_report__boxinfo">
						<li class="qrt_report__boxinfo-item">
							<span>LUI</span><span>{{ selectedBox.currentLUI }}</span>
						</li>
						<li class="qrt_report__boxinfo-item">
							<span>Box</span><span>{{ selectedBox.DUI }}</span>
						</li>
						<li class="qrt_report__boxinfo-item">
							<span>Localidade</span><span>{{ selectedBox.Locality }}</span>
						</li>
						<li class="qrt_report__boxinfo-item">
							<span>Data início</span
							><span>{{ measurementsStartDate | date }}</span>
						</li>
						<li class="qrt_report__boxinfo-item">
							<span>Data fim</span><span>{{ measurementsEndDate | date }}</span>
						</li>
					</ul>
				</div>
				<div
					class="qrt_report__minimap"
					leaflet
					[leafletOptions]="mapOptions"
					[leafletLayers]="mapLayers"
				></div>
			</div>
		</div>
		<div
			class="qrt_report__card"
			*ngFor="let sensor of filteredSensors$ | async"
		>
			<div class="qrt_report__inner-card">
				<div>
					<h2>{{ sensor.name }}</h2>
					<ng-container *ngIf="sensor.limits"
						><h6 class="qrt_report__limits">Limites</h6>
						<ul class="qrt_report__limits-list">
							<li *ngFor="let limit of sensor.limits">
								{{ limit.chartLabel }}
							</li>
						</ul></ng-container
					>
					<div class="card qrt_report__average">
						<span class="qrt_report__average-title">Média mensal</span>
						<span class="qrt_report__average-value"
							>{{ measurementsData[sensor.apiFieldName]?.average }} {{
							measurementsData[sensor.apiFieldName]?.unit }}</span
						>
					</div>
				</div>
				<div>
					<ng-container *ngIf="sensorsInfo$ | async as sensorsInfo">
						<div
							*ngIf="sensorsInfo[sensor.apiFieldName] as infoDesc"
							class="card qrt_report__sensor-info"
						>
							{{ infoDesc }}
						</div>
					</ng-container>
					<div class="qrt_report__chart">
						<dx-chart
							[dataSource]="measurementsData[sensor.apiFieldName]?.values"
						>
							<dxi-series
								argumentField="dateObserved"
								valueField="value"
								type="bar"
								color="#ff7c7c"
							></dxi-series>

							<dxo-zoom-and-pan argumentAxis="both" valueAxis="both">
							</dxo-zoom-and-pan>

							<dxo-crosshair [enabled]="true">
								<dxo-label [visible]="true"></dxo-label>
							</dxo-crosshair>

							<dxo-argument-axis argumentType="datetime" type="discrete">
								<dxo-label format="dd-MM-yyyy"></dxo-label>
							</dxo-argument-axis>

							<dxi-value-axis>
								<dxo-grid [visible]="true"></dxo-grid>
							</dxi-value-axis>

							<dxo-legend [visible]="false"></dxo-legend>
						</dx-chart>
					</div>
				</div>
			</div>
			<clr-datagrid
				*ngIf="
          measurementsData[sensor.apiFieldName]?.values as measurementsValues
        "
			>
				<clr-dg-column>Data</clr-dg-column>
				<clr-dg-column>Média</clr-dg-column>

				<clr-dg-row *ngFor="let measurement of measurementsValues">
					<clr-dg-cell>{{ measurement.dateObserved | date }}</clr-dg-cell>
					<clr-dg-cell
						>{{ measurement.value }} {{ measurement.unit }}</clr-dg-cell
					>
				</clr-dg-row>

				<clr-dg-footer>{{ measurementsValues.length }} médias</clr-dg-footer>
			</clr-datagrid>
		</div>
	</section>
</ng-container>
