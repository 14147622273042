<div class="qrt_tech-support" data-testid="tech-support">
	<h1 class="qrt_tech-support__title">Assistência Técnica</h1>

	<div class="qrt_tech-support__cards">
		<a
			class="card clickable qrt_tech-support__card"
			[routerLink]="['/support/issues']"
		>
			<div class="card-block">
				<clr-icon shape="warning-standard" size="100" solid></clr-icon>
				<h2>Anomalias</h2>
				<p>Registar ou acompanhar anomalias em dispositivos</p>
			</div>
		</a>

		<a
			class="card clickable qrt_tech-support__card"
			href="https://documenter.getpostman.com/view/6370721/TVYJ5H9S"
			target="_blank"
		>
			<div class="card-block">
				<clr-icon shape="network-settings" size="100" solid></clr-icon>
				<h2>Programadores</h2>
				<p>Documentação de API para integrações</p>
			</div>
		</a>
	</div>
</div>
