import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { OldestMeasurementActions } from 'app/devices/actions';
import * as fromAuth from 'app/auth/reducers';
import { Measurement } from 'app/devices/models/measurement';

import { MeasurementsService } from 'app/devices/services/measurements.service';

@Injectable()
export class OldestMeasurementEffects {
	loadOldestMeasurements$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OldestMeasurementActions.loadOldestMeasurements),
			mergeMap(action =>
				this.measurementsService.getAllOldestMeasurements$().pipe(
					map(oldestMeasurements =>
						OldestMeasurementActions.loadOldestMeasurementsSuccess({
							oldestMeasurements,
						})
					),
					catchError(error =>
						of(OldestMeasurementActions.loadOldestMeasurementsFailure({ error }))
					)
				)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private measurementsService: MeasurementsService
	) {}
}