import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, filter, catchError, last } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromDevices from 'app/devices/reducers';
import * as MeasurementActions from 'app/devices/actions/measurement.actions';
import * as MeasurementSelectors from 'app/devices/selectors/measurement.selectors';
import { degreesToCardinal } from 'app/shared/utils/meteorology';
import { isEmpty } from 'app/shared/helpers/lang';

@Component({
	selector: 'qrt-measurements-wind-rose',
	templateUrl: './measurements-wind-rose.component.html',
	styleUrls: ['./measurements-wind-rose.component.scss'],
})
export class MeasurementsWindRoseComponent implements OnInit {
	@ViewChild('chart') chart;

	measurements$: Observable<any>;
	windSources$: Observable<any>;

	constructor(private store$: Store<any>) {
		this.measurements$ = store$.pipe(
			select(MeasurementSelectors.selectWindRoseData),
			filter(measurements => !!measurements),
			catchError(error => of([]))
		);

		this.windSources$ = store$.pipe(
			select(MeasurementSelectors.selectWindSources)
		);
	}

	ngOnInit(): void {}

	reloadMeasurements(): void {}

	changeCardinalDirections(points: number) {
		const directions =
			points === 4
				? ['N', 'E', 'S', 'W']
				: points === 8
				? ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
				: [
						'N',
						'NNE',
						'NE',
						'ENE',
						'E',
						'ESE',
						'SE',
						'SSE',
						'S',
						'SSW',
						'SW',
						'WSW',
						'W',
						'WNW',
						'NW',
						'NNW',
				  ];

		this.store$.dispatch(
			MeasurementActions.changeCardinalDirections({
				directions,
			})
		);

		this.store$.dispatch(MeasurementActions.loadWindRoseData());
	}

	isDirectionsSelected$(points: number) {
		return this.store$.pipe(
			select(MeasurementSelectors.selectCardinalDirections),
			map(directions => directions.length === points)
		);
	}

	onLegendClick(e: any) {
		const series = e.target;
		if (series.isVisible()) {
			series.hide();
		} else {
			series.show();
		}
	}
}
