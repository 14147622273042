import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Box, AvgPeriod, BoxDiagnosis } from 'app/devices/models/box.model';
import { SensorsInfo } from 'app/devices/models/sensors-info';

export const selectDuiToDiagnosis = createAction(
	'[Box] Select DUI To Diagnosis',
	props<{ dui: string }>()
);

export const requestDiagnosis = createAction(
	'[Box/API] Request Diagnosis',
	props<{ dui: string }>()
);

export const requestDiagnosisSuccess = createAction(
	'[Box/API] Request Diagnosis Success',
	props<{ diagnosis: BoxDiagnosis['Data'] }>()
);

export const requestDiagnosisFailure = createAction(
	'[Box/API] Request Diagnosis Failure',
	props<{ error: any }>()
);

export const stopRequestingDiagnosis = createAction(
	'[Box/API] Stop Requesting Diagnosis'
);

export const requestExport = createAction(
	'[Box/API] Request Measurement Export',
	props<{
		startDate: string;
		endDate: string;
		average: string;
		boxes: Array<string>;
		preferences: { sms: boolean };
	}>()
);

export const requestExportSuccess = createAction(
	'[Box/API] Request Measurement Export Success'
);

export const requestExportFailure = createAction(
	'[Box/API] Request Measurement Export Failure',
	props<{ error: any }>()
);

export const loadBoxes = createAction('[Box/API] Load Boxes');

export const loadBoxesSuccess = createAction(
	'[Box/API] Load Boxes Success',
	props<{ boxes: Box[] }>()
);

export const loadBoxesFailure = createAction(
	'[Box/API] Load Boxes Failure',
	props<{ error: any }>()
);

export const loadSensorsInfo = createAction(
	'[SensorsInfo/API] Load Sensors Info'
);

export const loadSensorsInfoSuccess = createAction(
	'[SensorsInfo/API] Load Sensors Info Success',
	props<{ sensorsInfo: SensorsInfo }>()
);

export const loadSensorsInfoFailure = createAction(
	'[SensorsInfo/API] Load SensorsInfo Failure',
	props<{ error: any }>()
);

export const selectBox = createAction(
	'[Box] Select Box',
	props<{ id: string }>()
);

export const selectBoxLui = createAction(
	'[Box] Select Box Lui',
	props<{ lui: number }>()
);

export const selectSensor = createAction(
	'[Box] Select Sensor Field',
	props<{ sensorfield: string }>()
);

export const selectMeteoSensor = createAction(
	'[Box] Select Meteo Sensor Field',
	props<{ sensorfield: string }>()
);

export const selectAvgPeriod = createAction(
	'[Box] Select Avg Period',
	props<{ avgPeriod: AvgPeriod }>()
);

export const selectChartType = createAction(
	'[Box] Select Chart Type',
	props<{ chartType: string }>()
);

export const selectYearToReport = createAction(
	'[Box] Select year to report',
	props<{ year: number }>()
);

export const selectMonthToReport = createAction(
	'[Box] Select month to report',
	props<{ month: number }>()
);

export const selectSensorsToReport = createAction(
	'[Box] Select sensors to report',
	props<{ sensors: Array<string | boolean> }>()
);

export const selectSensorsToReportLoaded = createAction(
	'[Box] Sensors to report LOADED',
	props<{ loaded: boolean }>()
);

export const addBoxesToStorage = createAction('[Box/Storage] Add Boxes');

export const addBoxesToStorageSuccess = createAction(
	'[Box/Storage] Add Boxes Success'
);

export const addBoxesToStorageFailure = createAction(
	'[Box/Storage] Add Boxes Failure',
	props<{ error: any }>()
);

export const addBox = createAction('[Box/API] Add Box', props<{ box: Box }>());

export const upsertBox = createAction(
	'[Box/API] Upsert Box',
	props<{ box: Box }>()
);

export const addBoxes = createAction(
	'[Box/API] Add Boxes',
	props<{ boxes: Box[] }>()
);

export const addBoxesSuccess = createAction(
	'[Box/API] Add Boxes Success',
	props<{ boxes: Box[] }>()
);

export const addBoxesFailure = createAction(
	'[Box/API] Add Boxes Failure',
	props<{ error: any }>()
);

export const upsertBoxes = createAction(
	'[Box/API] Upsert Boxes',
	props<{ boxes: Box[] }>()
);

export const updateBox = createAction(
	'[Box/API] Update Box',
	props<{ box: Update<Box> }>()
);

export const updateBoxes = createAction(
	'[Box/API] Update Boxes',
	props<{ boxes: Update<Box>[] }>()
);

export const deleteBox = createAction(
	'[Box/API] Delete Box',
	props<{ id: string }>()
);

export const deleteBoxes = createAction(
	'[Box/API] Delete Boxes',
	props<{ ids: string[] }>()
);

export const clearBoxes = createAction('[Box/API] Clear Boxes');
