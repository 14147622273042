<div class="progress top loop qrt_progress" *ngIf="loading$ | async">
	<progress max="100" value="0" data-displayval="0%"></progress>
</div>

<div class="qrt_overview">
	<div class="qrt_overview__top">
		<h1 class="qrt_overview__title">Visão geral</h1>
	</div>

	<div class="card qrt_overview__content_card" *ngIf="selectedDeviceContent">
		<div class="card-header">{{ selectedDeviceContent.DUI }}</div>
		<div class="card-block">
			<pre><code [highlight]="selectedDeviceContent | json"></code></pre>
		</div>
	</div>
	<div class="container">
		<clr-datagrid
		class="qart_overview__datagrid"
		[clrDgSingleSelected]="selectedDevice"
		(clrDgSingleSelectedChange)="selectionChanged($event)"
		>
			<clr-dg-column>
				<ng-container *clrDgHideableColumn="{hidden: false}">DUI</ng-container>
			</clr-dg-column>
			<clr-dg-column>
				<ng-container *clrDgHideableColumn="{hidden: false}">LUI</ng-container>
			</clr-dg-column>
			<clr-dg-column>
				<ng-container *clrDgHideableColumn="{hidden: false}">Última recepção</ng-container>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>CO</span>
					<span class="qrt_measurements-table__unit">mg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>S02</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>NO</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>NO2</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>LAeq,T</span>
					<span class="qrt_measurements-table__unit">dB(A)</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>O3</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>PM MASS</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>PM 1</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>PM 0.5</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>PM 0.7</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>PM 2.5</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>PM 10</span>
					<span class="qrt_measurements-table__unit">µg/m3</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Temperatura</span>
					<span class="qrt_measurements-table__unit">Celsius</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Temperatura Interna</span>
					<span class="qrt_measurements-table__unit">Celsius</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Humidade</span>
					<span class="qrt_measurements-table__unit">%</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Humidade Interna</span>
					<span class="qrt_measurements-table__unit">%</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Pressão</span>
					<span class="qrt_measurements-table__unit">Milibar</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Velocidade do vento</span>
					<span class="qrt_measurements-table__unit">m/s</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Direção do vento</span>
					<span class="qrt_measurements-table__unit">°</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Temperatura</span>
					<span class="qrt_measurements-table__unit">Celsius</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Humidade</span>
					<span class="qrt_measurements-table__unit">%</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Pressão</span>
					<span class="qrt_measurements-table__unit">Milibar</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>Precipitação</span>
					<span class="qrt_measurements-table__unit">%</span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>UV Index</span>
					<span class="qrt_measurements-table__unit"></span>
				</div>
			</clr-dg-column>
			<clr-dg-column>
				<div class="qrt_measurements-table__column-text">
					<span>UV Radiação Total</span>
					<span class="qrt_measurements-table__unit"></span>
				</div>
			</clr-dg-column>

			<clr-dg-row
				*clrDgItems="let data of detailedLastMeasurements$ | async"
				[clrDgItem]="data"
			>
				<clr-dg-cell>{{data.DUI}}</clr-dg-cell>
				<clr-dg-cell>{{data.LUI}}</clr-dg-cell>
				<clr-dg-cell>{{data.timeStamp | date:'short'}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'CO')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'SO2')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'NO')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'NO2')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'NOISE')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'O3')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PARTICULES_MASS')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PARTICULES_PM01')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PARTICULES_PM05')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PARTICULES_PM07')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PARTICULES_PM25')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PARTICULES_PM10')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'TEMPEXT')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'TEMPINT')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'HUMEXT')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'HUMINT')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'PRESSURE')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'METEOWINDSPEED')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'METEOWINDDIRECTION')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'METEOTEMPERATURE')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'METEOHUMIDITY')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'METEOPRESSURE')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'METEOPRECIPITATION')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'UVINDEX')}}</clr-dg-cell>
				<clr-dg-cell>{{selectSensorPerType(data, 'UVPAR')}}</clr-dg-cell>
			</clr-dg-row>
			<clr-dg-footer>
				{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of
				{{pagination.totalItems}} dispositivos
				<clr-dg-pagination
					#pagination
					[clrDgPageSize]="currentPageSize"
				></clr-dg-pagination>
			</clr-dg-footer>
		</clr-datagrid>
	</div>
</div>
