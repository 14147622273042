import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';

import { UsersActions } from 'app/auth/actions';

export const usersFeatureKey = 'users';

export interface State {
	usersList: Array<any>;
	selectedUser: any;
}

export const initialState: State = {
	usersList: [],
	selectedUser: {},
};

export const reducer = createReducer(
	initialState,
	on(UsersActions.loadUsersSuccess, (state, { users }) =>
		produce(state, draft => {
			draft.usersList = users;
		})
	),
	on(UsersActions.selectUser, (state, { user }) =>
		produce(state, draft => {
			draft.selectedUser = user;
		})
	)
);

export const getUsersList = (state: State) => state.usersList;
export const getSelectedUser = (state:State) => state.selectedUser;
