import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom, startWith } from 'rxjs/operators';

import * as fromAuth from 'app/auth/reducers';
import { StatusActions } from 'app/auth/actions';

import { ClrLoadingState } from '@clr/angular';

@Component({
	selector: 'qrt-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
	public changePasswordForm: FormGroup;
	public formIsValid$: Observable<boolean>;
	public sameNewPassword$: Observable<boolean>;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	constructor(private store$: Store<fromAuth.State>, private fb: FormBuilder) {
		this.changePasswordForm = this.fb.group({
			oldPassword: ['', [Validators.required]],
			newPassword: ['', [Validators.required]],
			newPasswordConfirmation: ['', [Validators.required]],
		});

		this.formIsValid$ = this.changePasswordForm.valueChanges.pipe(
			withLatestFrom(this.changePasswordForm.statusChanges),
			map(([formValues, formStatus]) => {
				const { newPassword, newPasswordConfirmation } = formValues;

				return (
					formStatus === 'VALID' && newPassword === newPasswordConfirmation
				);
			})
		);

		this.sameNewPassword$ = this.changePasswordForm.valueChanges.pipe(
			withLatestFrom(this.changePasswordForm.statusChanges),
			map(
				([{ newPassword, newPasswordConfirmation }, formStatus]) =>
					formStatus === 'VALID' && newPassword === newPasswordConfirmation
			),
			startWith(true)
		);
	}

	ngOnInit(): void {}

	changePassword() {
		this.submitBtnState = ClrLoadingState.LOADING;

		const oldPassword = this.changePasswordForm.controls.oldPassword.value;
		const newPassword = this.changePasswordForm.controls.newPassword.value;
		const newPasswordConfirmation = this.changePasswordForm.controls
			.newPasswordConfirmation.value;

		if (newPassword !== newPasswordConfirmation) {
			this.changePasswordForm.setErrors({ notEqualNewPw: true });

			this.submitBtnState = ClrLoadingState.DEFAULT;
		}

		this.store$.dispatch(
			StatusActions.changePassword({ oldPassword, newPassword })
		);

		setTimeout(() => (this.submitBtnState = ClrLoadingState.DEFAULT), 1000);
	}
}
