import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Component({
	selector: 'qrt-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
	@Input() device: any;
	@Input() location: any;
	@Input() measurements: any;
	@Input() meteoActivated$: Observable<boolean>;
	@Input() isAdmin$: Observable<boolean>;

	readyToShow: boolean;

	env = environment;

	constructor() {}

	ngOnInit(): void {
		this.readyToShow = this.device && this.location && this.measurements;
	}

	getIndColor(field, value) {
		const colors = {
			veryGood: '#00e16d',
			good: '#00bc70',
			mid: '#ffc44f',
			weak: '#ff9932',
			bad: '#ff5959',
		};

		const points = {
			PARTICULES_PM10: [
				{
					range: { min: 0, max: 21 },
					color: colors.veryGood,
				},
				{
					range: { min: 21, max: 36 },
					color: colors.good,
				},
				{
					range: { min: 36, max: 51 },
					color: colors.mid,
				},
				{
					range: { min: 51, max: 101 },
					color: colors.weak,
				},
				{
					range: { min: 101, max: 1200 },
					color: colors.bad,
				},
			],
			PARTICULES_PM25: [
				{
					range: { min: 0, max: 11 },
					color: colors.veryGood,
				},
				{
					range: { min: 11, max: 21 },
					color: colors.good,
				},
				{
					range: { min: 21, max: 26 },
					color: colors.mid,
				},
				{
					range: { min: 26, max: 51 },
					color: colors.weak,
				},
				{
					range: { min: 51, max: 800 },
					color: colors.bad,
				},
			],
			NO2: [
				{
					range: { min: 0, max: 41 },
					color: colors.veryGood,
				},
				{
					range: { min: 41, max: 101 },
					color: colors.good,
				},
				{
					range: { min: 101, max: 201 },
					color: colors.mid,
				},
				{
					range: { min: 201, max: 401 },
					color: colors.weak,
				},
				{
					range: { min: 401, max: 10000 },
					color: colors.bad,
				},
			],
			O3: [
				{
					range: { min: 0, max: 81 },
					color: colors.veryGood,
				},
				{
					range: { min: 81, max: 101 },
					color: colors.good,
				},
				{
					range: { min: 101, max: 181 },
					color: colors.mid,
				},
				{
					range: { min: 181, max: 241 },
					color: colors.weak,
				},
				{
					range: { min: 241, max: 600 },
					color: colors.bad,
				},
			],
			SO2: [
				{
					range: { min: 0, max: 101 },
					color: colors.veryGood,
				},
				{
					range: { min: 101, max: 201 },
					color: colors.good,
				},
				{
					range: { min: 201, max: 351 },
					color: colors.mid,
				},
				{
					range: { min: 351, max: 501 },
					color: colors.weak,
				},
				{
					range: { min: 501, max: 1250 },
					color: colors.bad,
				},
			],
		};

		const sensorPoint = points[field];

		if (sensorPoint) {
			return sensorPoint.find(
				({ range, color }) => value >= range.min && value <= range.max
			)?.color;
		}
	}
}
