import { createAction, union, props } from '@ngrx/store';
import { User } from 'app/auth/models/user';
import { NotificationPreferences } from 'app/auth/models/notification';
import { UserPreferences } from 'app/auth/models/user';

// Layout preferences
export const meteoPreference = createAction(
	'[Preferences] Meteorology change',
	props<{ activated: boolean }>()
);

// User preferences
export const loadUserPreferences = createAction(
	'[Auth/API] Load User Preferences',
	props<UserPreferences>()
);

export const loadUserPreferencesSuccess = createAction(
	'[Auth/API] Load User Preferences Success'
);

export const loadUserPreferencesFailure = createAction(
	'[Auth/API] Load User Preferences Failure',
	props<{ error: any }>()
);

export const updateUserPreferences = createAction(
	'[Auth/API] Update User Preferences',
	props<any>()
);

export const updateUserPreferencesSuccess = createAction(
	'[Auth/API] Update User Preferences Success',
	props<{ user: User }>()
);

export const updateUserPreferencesFailure = createAction(
	'[Auth/API] Update User Preferences Failure',
	props<{ error: any }>()
);

export const updateNotificationPreferences = createAction(
	'[Notification/API] Update Notification Preferences',
	props<any>()
);

export const updateNotificationPreferencesSuccess = createAction(
	'[Notification/API] Update Notification Preferences Success',
	props<{ notificationPreferences: NotificationPreferences }>()
);

export const updateNotificationPreferencesFailure = createAction(
	'[Notification/API] Update Notification Preferences Failure',
	props<{ error: any }>()
);
