import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromDevices from 'app/devices/reducers';

@Component({
	selector: 'qrt-disclaimer',
	templateUrl: './disclaimer.component.html',
	styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
	disclaimer$: Observable<string>;

	constructor(private store$: Store<any>) {
		this.disclaimer$ = store$.pipe(
			select(fromDevices.selectSensorsInfoDisclaimer)
		);
	}

	ngOnInit(): void {
	}
}
