import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { DeviceAlert } from 'app/devices/models/device-alert.model';

interface CreateDeviceAlertResponse {
	message: string;
	alert: DeviceAlert;
}

@Injectable({ providedIn: 'root' })
export class DeviceAlertService {
	constructor(private http: HttpClient) {}

	getAllDeviceAlerts(): Observable<DeviceAlert[]> {
		return this.http.get<DeviceAlert[]>(`${environment.api}/alerts`);
	}

	createDeviceAlert(alert: DeviceAlert): Observable<CreateDeviceAlertResponse> {
		return this.http.post<CreateDeviceAlertResponse>(
			`${environment.api}/alerts`,
			alert
		);
	}

	updateDeviceAlert$(alertId: string | number, changes: any): Observable<any> {
		return this.http.patch(`${environment.api}/alerts/${alertId}`, changes);
	}

	deleteDeviceAlert(alertId: string): Observable<any> {
		return this.http.delete(`${environment.api}/alerts/${alertId}`);
	}
}
