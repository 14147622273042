import { createAction, props } from '@ngrx/store';

export const loadLatestWindForecast = createAction(
	'[Forecast] Load Latest Wind Forecast'
);

export const loadLatestWindForecastSuccess = createAction(
	'[Forecast] Load Latest Wind Forecast Success',
	props<{ data: any }>()
);

export const loadLatestWindForecastFailure = createAction(
	'[Forecast] Load Latest Wind Forecast Failure',
	props<{ error: any }>()
);
