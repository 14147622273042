<div
	class="alert alert-app-level alert-warning"
	role="alert"
	*ngIf="resetPasswordHttpError$ | async"
>
	<div class="alert-items">
		<div class="alert-item static">
			<div class="alert-icon-wrapper">
				<clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
			</div>
			<div class="alert-text">
				Não foi possível redefinir a palavra passe. Tente novamente mais tarde
			</div>
		</div>
	</div>
</div>

<div class="reset-password">
	<div class="reset-password__container">
		<img class="logo" src="assets/img/big_logo.png" />

		<h1 class="reset-password__title">Redefinir palavra-passe</h1>

		<ng-container *ngIf="formState$ | async as formState">
			<form
				class="reset-password__form"
				novalidate
				ngNoForm
				[ngrxFormState]="formState"
				(submit)="submitForm()"
			>
				<div class="reset-password__form-content">
					<clr-password-container>
						<label for="password">Nova palavra-passe</label>
						<input
							class="reset-password__input"
							id="password"
							type="password"
							clrPassword
							[ngrxFormControlState]="formState.controls.password"
						/>
					</clr-password-container>

					<clr-password-container>
						<label for="password-2">Confirmar palavra-passe</label>
						<input
							class="reset-password__input"
							id="password-2"
							type="password"
							clrPassword
							[ngrxFormControlState]="formState.controls.passwordConfirmation"
						/>
					</clr-password-container>

					<div
						class="reset-password__errors"
						role="alert"
						aria-live="polite"
						*ngIf="(formErrors$ | async).length"
					>
						<clr-alert [clrAlertSizeSmall]="true" [clrAlertType]="'warning'">
							<clr-alert-item *ngFor="let error of formErrors$ | async">
								<span class="alert-text"> {{ error }} </span>
							</clr-alert-item>
						</clr-alert>
					</div>
				</div>

				<button
					class="btn btn-primary reset-password__submit-btn"
					[disabled]="submitButtonDisabled$ | async"
				>
					Enviar
				</button>
			</form>
		</ng-container>
	</div>
</div>
