import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StatusActions } from 'app/auth/actions';
import * as fromAuth from 'app/auth/reducers';

@Component({
	selector: 'qrt-headeraltice',
	templateUrl: './headeraltice.component.html',
	styleUrls: ['./headeraltice.component.scss'],
})
export class HeaderAlticeComponent implements OnInit {
	username$: Observable<string>;

	constructor(private store$: Store<any>) {
		this.username$ = store$.pipe(
			select(fromAuth.selectUserData),
			filter(userData => !!userData?.user),
			map(userData => {
				const firstName = userData.user.firstName;
				const lastName = userData.user.lastName;
				return `${firstName} ${lastName}`;
			})
		);
	}

	ngOnInit() {}

	logout() {
		this.store$.dispatch(StatusActions.logout());
	}
}
