import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Observable, Subscription, combineLatest } from 'rxjs';
import {
  filter,
  map,
  take,
  takeLast,
  switchMap,
  withLatestFrom,
  startWith
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import moment from 'moment';

import {
  BoxActions,
  LastMeasurementActions,
  OldestMeasurementActions,
  BoxPreferencesActions,
	DeviceAlertActions,
	LocationActions
} from 'app/devices/actions';
import * as fromAuth from 'app/auth/reducers';
import * as fromDevices from 'app/devices/reducers';
import * as DeviceAlertSelectors from 'app/devices/selectors/device-alert.selectors';
import { isEmpty } from 'app/shared/helpers/lang';
import { filterLocations } from 'app/shared/helpers/locations';

import { Box, Sensor } from 'app/devices/models/box.model';

@Component({
  selector: 'qrt_box-list',
  templateUrl: './boxlist.component.html',
  styleUrls: ['./boxlist.component.scss'],
})
export class BoxListComponent implements OnInit, OnDestroy {
  boxes$: Observable<any>;
  filteredLocations$: Observable<any>;
	locations$: Observable<any>;
    devices$: Observable<any>;
  sensors$: Observable<Sensor[]>;
  sensorsToReportLoaded$: Observable<boolean>;
  selectedBoxLui$: Observable<number>;
  selectedBox$: Observable<Box>;
  boxes$ub: Subscription;
  searchForm: FormGroup;
  reportForm: FormGroup;
  reportForm$ub: Subscription;

  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  measurementsLoaded$: Observable<boolean>;
  measurementsLoaded$ub: Subscription;

  lastMeasurementDate$: Observable<string>;
  oldestMeasurementDate$: Observable<string>;

  allowedMonthsReport$: Observable<Array<string>>;
  allowedYearsReport$: Observable<Array<string>>;

  datesToReport$: Observable<any>;
  yearToReport$: Observable<number>;
  monthToReport$: Observable<number>;

  isModalVisible: boolean = false;

	isAdmin$: Observable<boolean>;

  constructor(private formBuilder: FormBuilder, private store$: Store<any>) {
    this.searchForm = this.formBuilder.group({
      search: [''],
    });

    this.reportForm = this.formBuilder.group({
      month: [0],
      year: [0],
      average: ['1d'],
      sensors: new FormArray([]),
    });

	this.isAdmin$ = store$.pipe(select(fromAuth.selectIsAdmin));

	this.locations$ = store$.pipe(select(fromDevices.selectAllLocations));
    this.devices$ = store$.pipe(select(fromDevices.selectAllBoxes));

    this.selectedBoxLui$ = store$.pipe(
      select(fromDevices.selectSelectedBoxLui),
      filter(lui => !!lui)
    );

    this.selectedBox$ = store$.pipe(
      select(fromDevices.selectBoxByLui),
      filter(box => !!box)
    );

    this.sensors$ = store$.pipe(
      select(fromDevices.selectSelectedBoxSensorsDetailsAllowed),
      filter(sensors => !isEmpty(sensors))
    );

    this.sensorsToReportLoaded$ = store$.pipe(
      select(fromDevices.selectSensorsToReportLoaded)
    );

    this.loading$ = store$.pipe(select(fromDevices.selectLocationLoading));

    this.loaded$ = store$.pipe(select(fromDevices.selectLocationLoaded));

    this.measurementsLoaded$ = store$.pipe(
      select(fromDevices.isLastAndOldestMeasurementsLoaded)
    );

    this.lastMeasurementDate$ = store$.pipe(
      select(fromDevices.selectSelectedBoxLastMeasurementDate)
    );
    this.oldestMeasurementDate$ = store$.pipe(
      select(fromDevices.selectSelectedBoxOldestMeasurementDate)
    );

    this.datesToReport$ = store$.pipe(select(fromDevices.selectDatesToReport));
    this.yearToReport$ = store$.pipe(select(fromDevices.selectYearToReport));
    this.monthToReport$ = store$.pipe(select(fromDevices.selectMonthToReport));

    this.allowedYearsReport$ = this.lastMeasurementDate$.pipe(
      withLatestFrom(this.oldestMeasurementDate$),
      map(([lastDate, oldestDate]) => {
        const years = [];
        const startDate = moment(lastDate);
        const endDate = moment(oldestDate);

        while (moment(startDate).diff(endDate, 'years', true) >= 0) {
          years.push(startDate.format('YYYY'));
          startDate.subtract(1, 'year');
        }

        this.reportForm.patchValue({ year: Math.max(...years) });

        return years;
      })
    );

    this.allowedMonthsReport$ = this.yearToReport$.pipe(
      withLatestFrom(this.lastMeasurementDate$, this.oldestMeasurementDate$),
      map(([yearToReport, lastDate, oldestDate]) => {
        const months = [];
        const startDate = moment(lastDate).add(1, 'month');
        const endDate = moment(oldestDate);
        const specDate = moment()
          .year(yearToReport)
          .month(12);

        for (let month = 0; month < moment.monthsShort().length; month++) {
          specDate.subtract(1, 'month');

          if (specDate.isBetween(endDate, startDate)) {
            months.push(specDate.format('M'));
          }
        }

        this.reportForm.patchValue({ month: Math.max(...months) });

        return months.sort((a, b) => a - b);
      })
    );
  }

  ngOnInit() {
	this.store$.dispatch(LocationActions.loadLocations());
	this.store$.dispatch(DeviceAlertActions.loadDeviceAlerts());
	this.store$.dispatch(BoxActions.loadBoxes());

    this.measurementsLoaded$ub = this.measurementsLoaded$.subscribe(loaded => {
      if (!loaded) {
        this.store$.dispatch(LastMeasurementActions.loadLastMeasurements());
        this.store$.dispatch(OldestMeasurementActions.loadOldestMeasurements());
      }
    });

    this.filteredLocations$ = combineLatest(
        this.searchForm.valueChanges.pipe(startWith({ search: '' })),
        this.locations$,
        this.devices$
    ).pipe(
        map(([searchForm, locations, devices]) =>
            filterLocations(searchForm.search, locations, devices)
        )
    );

    this.reportForm$ub = this.reportForm.valueChanges
      .pipe(
        withLatestFrom(this.sensorsToReportLoaded$),
        filter(([reportForm, loaded]) => !!loaded)
      )
      .subscribe(([reportForm, loaded]) => {
        const sensors = reportForm.sensors.filter(sensor => sensor);

        this.store$.dispatch(BoxActions.selectSensorsToReport({ sensors }));
        this.store$.dispatch(
          BoxActions.selectYearToReport({ year: Number(reportForm.year) })
        );
        this.store$.dispatch(
          BoxActions.selectMonthToReport({ month: Number(reportForm.month) })
        );

        const getAvgPeriod = avgType => {
          switch (avgType) {
            case '8h':
              return {
                name: '8 Horas',
                avgType: '8h',
                chartType: 'bar',
              };
            case '1d':
              return {
                name: 'Diário',
                avgType: '1d',
                chartType: 'bar',
              };
            case '1w':
              return {
                name: 'Semanal',
                avgType: '1w',
                chartType: 'bar',
              };

            default:
              return {
                name: 'Diário',
                avgType: '1d',
                chartType: 'bar',
              };
          }
        };

        this.store$.dispatch(
          BoxPreferencesActions.selectAvgPeriod({
            avgPeriod: getAvgPeriod(reportForm.average),
          })
        );
      });
  }

	getDeviceAlerts(DUI: string) {
		return this.store$.pipe(select(DeviceAlertSelectors.selectDeviceAlerts, { DUI }))
	}

  selectBoxLui(lui: number) {
    this.isModalVisible = true;

    this.store$.dispatch(
      BoxActions.selectSensorsToReportLoaded({ loaded: false })
    );

    this.store$.dispatch(BoxActions.selectBoxLui({ lui }));

    this.selectedBox$.pipe(take(1)).subscribe(box => {
      this.store$.dispatch(BoxActions.selectBox({ id: box.DUI }));
    });

    this.sensors$.pipe(take(1)).subscribe(sensors => {
      const sensorsApiFields = sensors.map(sensor => sensor.apiFieldName);

      this.store$.dispatch(
        BoxActions.selectSensorsToReport({ sensors: sensorsApiFields })
      );

      (this.reportForm.controls.sensors as FormArray).clear();

      sensors.forEach(sensor => {
        const control = new FormControl(sensor.apiFieldName);
        (this.reportForm.controls.sensors as FormArray).push(control);
      });

      this.store$.dispatch(
        BoxActions.selectSensorsToReportLoaded({ loaded: true })
      );
    });
  }

	clearSearch() {
		this.searchForm.patchValue({ search: '' });
	}

	selectDuiToDiagnosis(dui: string): void {
		this.store$.dispatch(BoxActions.selectDuiToDiagnosis({ dui }));
	}

	getDUI$(LUI: number): Observable<string> {
		return this.store$.pipe(select(fromDevices.selectDUIByLUI, { LUI }));
	}

  ngOnDestroy() {
    this.reportForm$ub.unsubscribe();
    this.measurementsLoaded$ub.unsubscribe();
  }
}
