<button
	type="button"
	class="btn btn-icon"
	aria-label="Alterar alerta"
	(click)="openModal()"
>
	<clr-icon shape="pencil"></clr-icon>
</button>

<clr-modal [clrModalOpen]="showModal$ | async" [clrModalClosable]="false">
	<h3 class="modal-title">Alterar alerta</h3>
	<div class="modal-body">
		<form
			id="editAlertForm"
			[formGroup]="editAlertForm"
			(ngSubmit)="updateAlert()"
			class="qrt_edit-alert"
		>
			<clr-toggle-container>
				<clr-toggle-wrapper>
					<label>Activo</label>
					<input type="checkbox" clrToggle formControlName="enabled" />
				</clr-toggle-wrapper>
			</clr-toggle-container>
			<clr-input-container>
				<label>Etiqueta</label>
				<input
					clrInput
					placeholder="Alerta para o sensor..."
					formControlName="label"
				/>
			</clr-input-container>
			<clr-select-container *ngIf="selectedBoxSensors$ | async as sensors">
				<label for="select-sensor" class="clr-control-label">Sensor</label>
				<select
					formControlName="sensor"
					clrSelect
					id="select-dui"
					class="clr-select"
					data-testid="selectSensor"
				>
					<option *ngFor="let sensor of sensors" [value]="sensor.name">
						{{ sensor.name }} - {{ sensor.fullName }}
					</option>
				</select>
				<clr-control-error>É preciso informar um sensor.</clr-control-error>
			</clr-select-container>
			<div class="qrt_edit-alert__condition">
				<div class="qrt_edit-alert__operator">
					<clr-select-container *ngIf="operators">
						<label
							for="select-operator"
							class="clr-control-label screen-reader-only"
							>Operação</label
						>
						<select
							formControlName="operator"
							clrSelect
							id="select-operator"
							class="clr-select"
							data-testid="selectOperator"
						>
							<option
								*ngFor="let operator of operators | keyvalue"
								[value]="operator.key"
							>
								{{ operator.value }}
							</option>
						</select>
						<clr-control-error>É preciso informar operação.</clr-control-error>
					</clr-select-container>
				</div>

				<span>a</span>

				<div class="qrt_edit-alert__value">
					<clr-input-container>
						<label class="screen-reader-only">Valor</label>
						<input clrInput type="text" formControlName="value" />
					</clr-input-container>
					<strong
						>{{ getSelectedSensorUnit$(alertDUI, selectedSensor) | async
						}}</strong
					>
				</div>

				<span>no período de</span>

				<div class="qrt_edit-alert__periods">
					<clr-select-container *ngIf="periods">
						<label
							for="select-period"
							class="clr-control-label screen-reader-only"
							>Período</label
						>
						<select
							formControlName="period"
							clrSelect
							id="select-period"
							class="clr-select"
							data-testid="selectPeriod"
						>
							<option
								*ngFor="let period of periods | keyvalue"
								[value]="period.key"
							>
								{{ period.value }}
							</option>
						</select>
						<clr-control-error>É preciso informar período.</clr-control-error>
					</clr-select-container>
				</div>
			</div>
			<fieldset formGroupName="action">
				<clr-toggle-container>
					<clr-toggle-wrapper>
						<label>Receber alerta por SMS</label>
						<input type="checkbox" clrToggle formControlName="alertBySms" />
					</clr-toggle-wrapper>
				</clr-toggle-container>

				<clr-toggle-container>
					<clr-toggle-wrapper>
						<label>Receber alerta por E-mail</label>
						<input type="checkbox" clrToggle formControlName="alertByEmail" />
					</clr-toggle-wrapper>
				</clr-toggle-container>

				<clr-toggle-container>
					<clr-toggle-wrapper>
						<label>Webhook URL</label>
						<input type="checkbox" clrToggle formControlName="callback" />
					</clr-toggle-wrapper>
				</clr-toggle-container>

				<ng-container *ngIf="callbackAction$ | async">
					<form [formGroup]="callbackForm">
						<clr-input-container>
							<label>URL</label>
							<input
								clrInput
								placeholder="https://api.example.com"
								name="url"
								formControlName="url"
							/>
						</clr-input-container>

						<clr-select-container>
							<label>Método</label>
							<select clrSelect name="method" formControlName="method">
								<option value="post">POST</option>
								<option value="get">GET</option>
								<option value="patch">PATCH</option>
							</select></clr-select-container
						>
					</form>
				</ng-container>
			</fieldset>
		</form>
	</div>
	<div class="modal-footer">
		<button
			[clrLoading]="submitBtnState"
			form="editAlertForm"
			class="btn btn-primary"
			[disabled]="editAlertForm.pristine && callbackForm.pristine"
		>
			Actualizar
		</button>
		<button type="button" class="btn" (click)="closeModal()">Cancelar</button>
	</div>
</clr-modal>
