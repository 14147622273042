import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StatusActions } from 'app/auth/actions';
import { PreferencesActions } from 'app/auth/actions';
import * as fromAuth from 'app/auth/reducers';

@Component({
	selector: 'qrt-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	meteoForm: FormGroup;
	username$: Observable<string>;

	constructor(private store$: Store<any>, private formBuilder: FormBuilder) {
		this.username$ = store$.pipe(
			select(fromAuth.selectUserData),
			filter(userData => !!userData?.user),
			map(userData => {
				const firstName = userData.user.firstName;
				const lastName = userData.user.lastName;
				return `${firstName} ${lastName}`;
			})
		);
	}

	ngOnInit() {
		this.meteoForm = this.formBuilder.group({
			meteoToggle: [''],
		});
	}

	setMeteoPreference() {
		const activated = this.meteoForm.controls.meteoToggle.value;

		this.store$.dispatch(PreferencesActions.meteoPreference({ activated }));
	}

	logout() {
		this.store$.dispatch(StatusActions.logout());
	}
}
