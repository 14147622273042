import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { produce } from 'immer';

import { DxChartComponent } from 'devextreme-angular';

import { ClrLoadingState } from '@clr/angular';

import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';

import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';

import moment from 'moment';
import 'moment-timezone';

import { Observable, Subscription, interval } from 'rxjs';
import {
  tap,
  withLatestFrom,
  map,
  switchMap,
  filter,
  concatMap,
  take,
  distinctUntilChanged,
  combineLatest,
  merge,
  skip,
} from 'rxjs/operators';

import { isEmpty } from 'app/shared/helpers/lang';

import { BoxService } from 'app/devices/services/box.service';
import {
  Box,
  AvgPeriod,
  Sensor,
  MeteoSensor,
} from 'app/devices/models/box.model';
import { Measurement } from 'app/devices/models/measurement';
import { PreferencesActions } from 'app/auth/actions';
import {
  BoxActions,
  MeasurementActions,
  LastMeasurementActions,
  BoxPreferencesActions,
} from 'app/devices/actions';
import * as fromRoot from 'app/reducers';
import * as fromAuth from 'app/auth/reducers';
import * as fromDevices from 'app/devices/reducers';
import * as LocationSelectors from 'app/devices/selectors/location.selectors';
import { LayoutActions } from 'app/core/actions';
import { presentationType } from 'app/core/reducers/layout.reducer';

@Component({
  selector: 'box-details',
  templateUrl: './boxdetails.component.html',
  styleUrls: ['./boxdetails.component.scss'],
})
export class BoxDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('Chart') Chart: DxChartComponent;

  private subscription: Subscription = new Subscription();
	chartType$: Observable<string>;
  chartData: Array<any> = [];

  box$: Observable<any>;

  boxesLoaded$: Observable<any>;
  boxes$: Observable<any>;
  boxesCombo$: Observable<any>;
  selectedBox$: Observable<any>;
  meteoStation$: Observable<boolean>;

  sensors$: Observable<Array<any>>;
  meteoSensors$: Observable<Array<any>>;
  selectedMeteoSensor$: Observable<MeteoSensor>;
  selectedSensor$: Observable<Sensor>;
  selectedSensor: Sensor;
  selectedSensorLimits: Array<any> = [];
  selectedSensorLimits$: Observable<any>;
  selectedSensorInfo$: Observable<string>;
  selectedMeteoSensorInfo$: Observable<string>;
  sensorsDisclaimer$: Observable<string>;

  avgPeriods$: Observable<Array<any>>;
  filteredAvgPeriods$: Observable<Array<any>>;
  selectedAvgPeriod$: Observable<any>;
  selectedAvgPeriod;

  showRangeSelector$: Observable<boolean>;

  measurementsLoaded$: Observable<any>;
  measurements$: Observable<any>;
  public measurementsToExport$: Observable<any>;

  lastMeasurements$: Observable<any>;
  lastMeasurementsLoaded$: Observable<any>;

  lastMeasurementDate$: Observable<string>;
  oldestMeasurementDate$: Observable<string>;

  invalidDateRange$: Observable<boolean>;
  invalidPeriods: Array<string>;

  chartMaxValueMargin: number = 45;
	chartMaxValueMargin$: Observable<number>;

  selectedStartDate: string;

  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  boxesAndLastMeasurementsLoaded$: Observable<any>;

  meteoActivated$: Observable<boolean>;
  airQualityActivated$: Observable<boolean>;

  showChartOptions$: Observable<boolean>;

  avgOrDateChanged$: Observable<any>;
	presentationType$: Observable<presentationType>;
	visibleSensorLimits$: Observable<any[]>;

  showDataBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  comboboxForm = this.fb.group({
	  deviceName: ['']
  })

  ngOnInit() {
	this.selectedBox$
		.pipe(
			filter(box => !!box),
			take(1)
		)
		.subscribe(box => {
			this.comboboxForm.patchValue({
				deviceName: box.Description,
			});
		});


    this.store$.dispatch(
      BoxPreferencesActions.selectAvgPeriod({
        avgPeriod: {
          name: 'Horária',
          avgType: '1h',
          chartType: 'bar',
          allowedWindowPeriods: [
            { value: 1, unit: 'day', order: 0 },
            { value: 1, unit: 'week', order: 1 },
            { value: 1, unit: 'month', order: 2 },
          ],
        },
      })
    );


    this.subscription.add(
      this.sensors$.pipe(filter(sensors => !!sensors && !isEmpty(sensors))).subscribe(sensors => {

        const smallestOrderSensor = sensors?.reduce(
          (previousSensor, currentSensor) =>
            currentSensor.order > previousSensor.order
              ? previousSensor
              : currentSensor
        );

        this.store$.dispatch(
          BoxActions.selectSensor({
            sensorfield: smallestOrderSensor.apiFieldName,
          })
        );
      })
    );

    this.subscription.add(
      this.selectedAvgPeriod$.subscribe(avg => {
        this.selectedAvgPeriod = avg;
      })
    );

    this.measurements$ = this.store$.pipe(
      select(fromDevices.selectAllMeasurements),
      withLatestFrom(
        this.selectedBox$,
        this.selectedSensor$,
        this.meteoActivated$,
        this.selectedMeteoSensor$
      ),
      filter(([measurements]) => !isEmpty(measurements)),
      map(
        ([
          measurements,
          box,
          selectedSensor,
          meteoActivated,
          selectedMeteoSensor,
        ]: [Measurement[], Box, Sensor, boolean, MeteoSensor]) => {
          const selectedSensorMeasurements =  measurements.map(measurement => {
            /**
             * format date based on selected box's timezone
             */
            const dateObserved = moment(measurement.dateObserved)
              .tz(box.timezone)
              .format();

            if (meteoActivated) {
              const meteoSensorMeasurement = produce(
                {
                  ...measurement[selectedMeteoSensor.apiFieldName],
                },
                draft => {
                  switch (selectedMeteoSensor.apiFieldName) {
                    case 'METEOWINDDIRECTION':
                      draft.unit = 'Graus';
                      break;
                    case 'METEOTEMPERATURE':
                      draft.unit = 'Graus Celsius';
                      break;
                    default:
                      break;
                  }
                }
              );

              return { ...meteoSensorMeasurement, dateObserved };
            }

            const sensorMeasurement = produce(
              {
                ...measurement[selectedSensor.apiFieldName],
              },
              draft => {
                if (selectedSensor.conversionFactor) {
                  const conversionFactor = selectedSensor.conversionFactor[0];
                  draft.unit = conversionFactor.unit;
                  draft.value =
                    Math.round(
                      draft.value * conversionFactor.unitFactor * 100
                    ) / 100;
                }
              }
            );

            return { ...sensorMeasurement, dateObserved };
          });

		const maxMeasurementValue =
			Math.max(
				...selectedSensorMeasurements.map((measurement: any) => measurement.value)
			) || 0;

		this.store$.dispatch(
			MeasurementActions.changeMaxMeasurementsValue({ value: maxMeasurementValue })
		);

		return selectedSensorMeasurements;
        }
      ),
      take(1)
    );

	this.measurementsToExport$ = this.measurements$.pipe(
		map(measurements =>
			measurements
				.map(measurement => ({
					DATA: measurement.dateObserved,
					[`${measurement.name} (${measurement.unit})`]: measurement.value,
				}))
				.sort((a, b) => {
					const dateA = new Date(a.DATA).getTime();
					const dateB = new Date(b.DATA).getTime();
					return dateA - dateB;
				})
		)
	);

    this.lastMeasurementDate$.pipe(take(1)).subscribe(date => {
      this.store$.dispatch(BoxPreferencesActions.selectDate({ date }));

      const startDate = moment(date).subtract(1, 'day');
      const endDate = moment(date);

      this.updateChartData(startDate.toISOString(), endDate.toISOString());
    });
  }

  customizePoint(arg: any) {
	const styles = {
		veryGood: { color: '#00e16d', hoverStyle: { color: '#00e16d' } },
		good: { color: '#00bc70', hoverStyle: { color: '#00bc70' } },
		mid: { color: '#ffc44f', hoverStyle: { color: '#ffc44f' } },
		weak: { color: '#ff9932', hoverStyle: { color: '#ff9932' } },
		bad: { color: '#ff5959', hoverStyle: { color: '#ff5959' } },
	};

	const points = {
		PARTICULES_PM10: [
			{
				range: { min: 0, max: 21 },
				style: styles.veryGood,
			},
			{
				range: { min: 21, max: 36 },
				style: styles.good,
			},
			{
				range: { min: 36, max: 51 },
				style: styles.mid,
			},
			{
				range: { min: 51, max: 101 },
				style: styles.weak,
			},
			{
				range: { min: 101, max: Infinity },
				style: styles.bad,
			},
		],
		PARTICULES_PM25: [
			{
				range: { min: 0, max: 11 },
				style: styles.veryGood,
			},
			{
				range: { min: 11, max: 21 },
				style: styles.good,
			},
			{
				range: { min: 21, max: 26 },
				style: styles.mid,
			},
			{
				range: { min: 26, max: 51 },
				style: styles.weak,
			},
			{
				range: { min: 51, max: Infinity },
				style: styles.bad,
			},
		],
		NO2: [
			{
				range: { min: 0, max: 41 },
				style: styles.veryGood,
			},
			{
				range: { min: 41, max: 101 },
				style: styles.good,
			},
			{
				range: { min: 101, max: 201 },
				style: styles.mid,
			},
			{
				range: { min: 201, max: 401 },
				style: styles.weak,
			},
			{
				range: { min: 401, max: Infinity },
				style: styles.bad,
			},
		],
		O3: [
			{
				range: { min: 0, max: 81 },
				style: styles.veryGood,
			},
			{
				range: { min: 81, max: 101 },
				style: styles.good,
			},
			{
				range: { min: 101, max: 181 },
				style: styles.mid,
			},
			{
				range: { min: 181, max: 241 },
				style: styles.weak,
			},
			{
				range: { min: 241, max: Infinity },
				style: styles.bad,
			},
		],
		SO2: [
			{
				range: { min: 0, max: 101 },
				style: styles.veryGood,
			},
			{
				range: { min: 101, max: 201 },
				style: styles.good,
			},
			{
				range: { min: 201, max: 351 },
				style: styles.mid,
			},
			{
				range: { min: 351, max: 501 },
				style: styles.weak,
			},
			{
				range: { min: 501, max: Infinity },
				style: styles.bad,
			},
		],
		
	};

	const sensorPoint = points[arg.data.apiFieldName];

	if (sensorPoint) {
		return sensorPoint.find(
			({ range, style }) => arg.value >= range.min && arg.value <= range.max
		)?.style;
	}

	return { color: '#6497b1', border: '#000', hoverStyle: { color: '#6497b1' } };
  }

  comboboxOpen(open) {
	  if (open) {
		  this.comboboxForm.patchValue({ deviceName: '' });
	  } else {
		  this.selectedBox$.pipe(filter(box => !!box), take(2)).subscribe(box => {
			  this.comboboxForm.patchValue({ deviceName: box?.Description });
		  })
	  }
  }

	getLocationByLui$(LUI: number): Observable<any> {
		return this.store$.pipe(select(LocationSelectors.selectLocationByLui, { LUI}))	
	}


  selectBox(id: string, lui: number) {
    /**
     * changes the url to the current box LUI
     */
    this.location.replaceState(`devices/details/${lui}`);

    /**
     * updates the initial date of the date picker
     * since each box has different last measurement date
     */

    this.store$.dispatch(
      BoxPreferencesActions.selectAvgPeriod({
        avgPeriod: {
          name: 'Horária',
          avgType: '1h',
          chartType: 'bar',
          allowedWindowPeriods: [
            { value: 1, unit: 'day', order: 0 },
            { value: 1, unit: 'week', order: 1 },
            { value: 1, unit: 'month', order: 2 },
          ],
        },
      })
    );

    this.lastMeasurementDate$
      .pipe(distinctUntilChanged(), take(2))
      .subscribe(date => {
        const startDate = moment(date).subtract(1, 'day');
        const endDate = moment(date);

        this.updateChartData(startDate.toISOString(), endDate.toISOString());
      });

    this.store$.dispatch(BoxActions.selectBox({ id }));
  }

  changeChartMaxValueMargin(index: number) {
    this.subscription.add(
      this.measurements$.pipe(take(1)).subscribe(measurements => {
        if (measurements) {
          const measurementValues = measurements.map(
            measurement => measurement.value
          );

          const maxMeasurementValue = Math.max(...measurementValues);

          /**
           * Creates an Array with only the values of the sensorLimits
           */
          const sensorLimitsValues = this.selectedSensorLimits.map(
            limit => limit.value
          );

          /**
           * Gets the highest value in the array of sensorLimits valyes
           */
          const maxLimitValue = Math.max(...sensorLimitsValues);

          this.chartMaxValueMargin =
            (maxLimitValue * 1.5) / maxMeasurementValue;
        }
      })
    );
  }



  updateChartData(startDate: string, endDate: string) {
    /**
     * resets the chart chartMaxValueMargin each time the data is updated
     * @type {Number}
     */
    this.chartMaxValueMargin = 0;

    const finalDate =
      this.selectedAvgPeriod.avgType === '1mn'
        ? endDate
        : this.selectedAvgPeriod.avgType === '10mn'
        ? moment(endDate)
            .subtract(1, 'hour')
            .toISOString()
        : moment(endDate)
            .set({ minutes: 0, seconds: 0, milliseconds: 0 })
            .toISOString();

    this.subscription.add(
      this.boxesAndLastMeasurementsLoaded$.subscribe(loaded => {
        if (loaded) {
          this.store$.dispatch(
            MeasurementActions.loadMeasurements({
              cfg: {
                startDate: startDate,
                endDate: finalDate,
              },
            })
          );
        }
      })
    );
  }

  onRangeValueChanged(event: any) {
    /**
     * @param {Date}
     * @param {Date}
     */
    this.Chart.instance.zoomArgument(event.value[0], event.value[1]);
  }

  scroll(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth' });
  }



	public showOnlyOnPresentationType$(type: presentationType) {
		return this.presentationType$.pipe(map(pType => pType === type));
	}


  ngOnDestroy() {
    this.subscription.unsubscribe();

    this.store$.dispatch(MeasurementActions.clearMeasurements());
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private store$: Store<any>,
    private actionsSubject: ActionsSubject,
    private fb: FormBuilder,
    private boxService: BoxService
  ) {
    this.loaded$ = store$.pipe(select(fromDevices.isAllDeviceDataLoaded));
    this.boxesAndLastMeasurementsLoaded$ = store$.pipe(
      select(fromDevices.isBoxesAndLastMeasurementsDataLoaded)
    );

    this.loading$ = store$.pipe(select(fromDevices.selectMeasurementsLoading));


    this.lastMeasurementsLoaded$ = store$.pipe(
      select(fromDevices.selectLastMeasurementsLoaded)
    );

    this.lastMeasurements$ = this.store$.pipe(
      select(fromDevices.selectAllLastMeasurements)
    );

    this.lastMeasurementDate$ = this.store$.pipe(
      select(fromDevices.selectSelectedBoxLastMeasurementDate),
      filter(date => !!date)
    );

    this.oldestMeasurementDate$ = store$.pipe(
      select(fromDevices.selectSelectedBoxOldestMeasurementDate),
      filter(date => !!date)
    );

    this.measurementsLoaded$ = store$.pipe(
      select(fromDevices.selectMeasurementsLoaded)
    );

    this.boxesLoaded$ = store$.pipe(select(fromDevices.selectBoxesLoaded));

    this.box$ = store$.pipe(
      select(fromDevices.selectSelectedBoxById),
      filter(box => !!box)
    );
    this.boxes$ = store$.pipe(select(fromDevices.selectAllBoxesEntities));

	this.boxesCombo$ = store$.pipe(select(fromDevices.selectAllBoxes))

    this.selectedBox$ = store$.pipe(select(fromDevices.selectSelectedBoxById));

    this.meteoStation$ = store$.pipe(
      select(fromDevices.selectSelectedBoxById),
      map(selectedBox => !!selectedBox?.MeteoStation)
    );

    this.sensors$ = store$.pipe(
      select(fromDevices.selectSelectedBoxSensorsDetailsAllowed),
      filter(sensors => !!sensors)
    );

    this.meteoSensors$ = store$.pipe(
      select(fromDevices.selectSelectedBoxMeteoSensorsDetailsAllowed)
    );

    this.selectedMeteoSensor$ = store$.pipe(
      select(fromDevices.selectSelectedMeteoSensor)
    );

    this.selectedSensor$ = store$.pipe(
      select(fromDevices.selectSelectedSensor)
    );

    this.selectedSensorLimits$ = store$.pipe(
      select(fromDevices.selectSelectedSensorLimits)
    );

    this.selectedSensorInfo$ = store$.pipe(
      select(fromDevices.selectSelectedSensorInfo)
    );

    this.selectedMeteoSensorInfo$ = store$.pipe(
      select(fromDevices.selectSelectedMeteoSensorInfo)
    );

    this.sensorsDisclaimer$ = store$.pipe(select(fromDevices.selectSensorsInfoDisclaimer));

    this.selectedAvgPeriod$ = store$.pipe(
      select(fromDevices.selectSelectedAvgPeriod)
    );

    this.avgPeriods$ = store$.pipe(
      select(fromDevices.selectSelectedBoxAvgPeriods)
    );

    this.showRangeSelector$ = this.selectedAvgPeriod$.pipe(
      map(({ avgType }) => 
          !['1mn', '10mn', '15mn']
            .find(nonAllowedAvgType => avgType === nonAllowedAvgType))
    )

    this.meteoActivated$ = this.store$.pipe(select(fromAuth.selectMeteorology));
    this.airQualityActivated$ = this.store$.pipe(
      select(fromAuth.selectAirQuality)
    );

    this.showChartOptions$ = store$.pipe(
      select(fromDevices.isAllDeviceDataLoaded),
      filter(loaded => !!loaded),
      take(1)
    );


	this.presentationType$ = store$.pipe(
		select(fromRoot.selectDeviceDetailsPresentationType)
	);
	this.chartType$ = store$.pipe(select(fromRoot.selectDeviceDetailsChartType));
	this.visibleSensorLimits$ = store$.pipe(
		select(fromRoot.selectSensorConfigLimits),
		filter(limits => !!limits),
		map(limits => limits.filter(limit => limit.visible))
	);
	this.chartMaxValueMargin$ = store$.pipe(select(fromRoot.selectChartMaxValueMargin));

    /**
     * Listen to each meteoPreference action dispatched
     * and trigger the chart to update
     */
    this.subscription.add(
      actionsSubject
        .pipe(ofType(PreferencesActions.meteoPreference))
        .subscribe(() => {
          this.lastMeasurementDate$.pipe(take(1)).subscribe(date => {
            const startDate = moment(date)
              .startOf('day')
              .toISOString();
            const endDate = moment(date).toISOString();

            this.updateChartData(startDate, endDate);
          });
        })
    );
  }
}
