import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDevices from 'app/devices/reducers';
import * as fromBoxes from 'app/devices/reducers/box.reducer';

import { isEmpty } from 'app/shared/helpers/lang';

export const selectDevicesState = createFeatureSelector<
	fromDevices.State,
	fromDevices.DevicesState
>(fromDevices.devicesFeatureKey);

export const selectBoxEntitiesState = createSelector(
	selectDevicesState,
	state => state.boxes
);

export const selectAllBoxesEntities = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectEntities
);

export const selectAllDevices = createSelector(
	selectBoxEntitiesState,
	fromBoxes.selectAll
);

export const selectDeviceByDui = createSelector(
	selectAllBoxesEntities,
	(boxesEntities, props) => boxesEntities[props.DUI]
);

export const selectSensorUnit = createSelector(
	selectAllBoxesEntities,
	(boxesEntities, props) => {
		const device = boxesEntities[props.DUI];

		if (!device || isEmpty(device)) {
			return;
		}

		const sensors = device.Config.Sensors.concat(
			device.Config.MeteoSensors || []
		);

		return (
			sensors.find(sensor => sensor.apiFieldName === props.sensor)?.unit || ''
		);
	}
);

// New Issue
export const selectNewIssueForm = createSelector(
	selectBoxEntitiesState,
	fromBoxes.getNewIssueForm
);
