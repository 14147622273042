export function degreesToCardinal(degree: number) {
	const cardinalDirections = [
		'N',
		'NNE',
		'NE',
		'ENE',
		'E',
		'ESE',
		'SE',
		'SSE',
		'S',
		'SSW',
		'SW',
		'WSW',
		'W',
		'WNW',
		'NW',
		'NNW',
	];

	// Divide the angle by directions
	const directionChange = 360 / cardinalDirections.length;

	// Find the index of the cardinal direction by dividing the given degree
	// to the direction change (+ 0.5 so Math.floor can tie between the change)
	// and use the modulo of the division
	const index =
		Math.floor(degree / directionChange + 0.5) % cardinalDirections.length;

	return cardinalDirections[index];
}

