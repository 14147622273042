import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

import {
	LastMeasurement,
	Measurement,
	MeasurementsByTimeStamp,
} from 'app/devices/models/measurement';
import { DetailedLastMeasurement } from 'app/devices/models/detailed-last-measurement.model';

@Injectable({
	providedIn: 'root',
})
export class MeasurementsService {
	constructor(private http: HttpClient) {}

	public getLastMeasurement$(
		dui: string,
		lui: string
	): Observable<Measurement> {
		return this.http.get<Measurement>(
			`${environment.api}/measurements/last/${dui}/${lui}`
		);
	}

	public getAllLastMeasurements$(): Observable<Measurement[]> {
		return this.http.get<Measurement[]>(
			`${environment.api}/measurements/last/all`
		);
	}

	public getAllDetailedLastMeasurements$(): Observable<
		DetailedLastMeasurement[]
	> {
		return this.http.get<DetailedLastMeasurement[]>(
			`${environment.portalApi}/measurements/last/all/detail`
		);
	}

	getAllOldestMeasurements$(): Observable<Measurement[]> {
		return this.http.get<Measurement[]>(
			`${environment.api}/measurements/oldest/all`
		);
	}

	getLastHourMeasurement$(dui: string): Observable<any> {
		return this.http.get(
			`${environment.portalApi}/measurements/last/hour/${dui}`
		);
	}

	public getMeasurementByTimeStamp(
		{ startDate, endDate }: MeasurementsByTimeStamp,
		dui: string,
		span: string
	): Observable<Array<any>> {
		return this.http.get<Array<any>>(
			`${environment.api}/measurements/${dui}?startDate=${startDate}&endDate=${endDate}&span=${span}&page=0&rows=10000000`
		);
	}
}
