<div class="qrt_dust" data-testid="dust" style="margin-left: 10px;">
   
<div style="text-align: center;">
       
    <div style="margin-left: 10%;margin-right: 10%;">
        <div class="card">        
            <h2 class="card-header">Previsão de Particulas</h2>                        
            <div class="card-block">                            
                            
                <div class="card-text">
                    <img src="/assets/dust/forecast.png" alt="Imagery" style="align-items: center;">
                </div>
            
            </div>
                
            <div class="card-footer" style="text-align: left;">
                Dados: SKIRON model
                <div class="card-text">
                    <p>O transporte de longa distância de partículas com origem natural, em zonas áridas do Norte de África, como é o caso dos desertos do Sahara e Sahel pode causar elevados níveis de PM10. 
                    Em Portugal e nos países Mediterrânicos estes eventos são mais frequentes nos períodos de primavera e verão.</p>
                </div>
            
            </div>
        
        </div>
    </div>
</div>



</div>
