import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';
import { DeviceAlert } from 'app/devices/models/device-alert.model';
import { DeviceAlertActions } from 'app/devices/actions';

export const deviceAlertsFeatureKey = 'deviceAlerts';

export interface State extends EntityState<DeviceAlert> {
	loaded: boolean;
}

export const adapter: EntityAdapter<DeviceAlert> = createEntityAdapter<
	DeviceAlert
>({
	selectId: (alert: DeviceAlert) => alert._id,
});

export const initialState: State = adapter.getInitialState({
	loaded: false,
});

export const reducer = createReducer(
	initialState,
	on(DeviceAlertActions.addDeviceAlert, (state, action) =>
		adapter.addOne(action.alert, state)
	),
	on(DeviceAlertActions.upsertDeviceAlert, (state, action) =>
		adapter.upsertOne(action.alert, state)
	),
	on(DeviceAlertActions.addDeviceAlerts, (state, action) =>
		adapter.addMany(action.alerts, state)
	),
	on(DeviceAlertActions.upsertDeviceAlerts, (state, action) =>
		adapter.upsertMany(action.alerts, state)
	),
	on(DeviceAlertActions.updateDeviceAlert, (state, action) =>
		adapter.updateOne(action.alert, state)
	),
	on(DeviceAlertActions.updateDeviceAlerts, (state, action) =>
		adapter.updateMany(action.alerts, state)
	),
	on(DeviceAlertActions.deleteDeviceAlert, (state, action) =>
		adapter.removeOne(action.id, state)
	),
	on(DeviceAlertActions.deleteDeviceAlerts, (state, action) =>
		adapter.removeMany(action.ids, state)
	),
	on(DeviceAlertActions.loadDeviceAlertsSuccess, (state, action) =>
		adapter.setAll(
			action.alerts,
			produce(state, draft => {
				draft.loaded = true;
			})
		)
	),
	on(DeviceAlertActions.clearDeviceAlerts, state => adapter.removeAll(state))
);

export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();
