import { Action, createReducer, on } from '@ngrx/store';
import { ClrLoadingState } from '@clr/angular';
import { produce } from 'immer';
import {
	FormGroupState,
	FormArrayState,
	createFormGroupState,
	createFormArrayState,
	formGroupReducer,
	onNgrxForms,
} from 'ngrx-forms';
import { Measurement } from 'app/devices/models/measurement';
import * as MeasurementActions from 'app/devices/actions/measurement.actions';

export const measurementsFeatureKey = 'measurements';
export const measurementsDatePickerFormKey = 'measurementsDatePicker';
export const comparisonFormKey = 'comparisonForm';

export interface MeasurementsDatePickerForm {
	startDate: string;
	endDate: string;
}

const initialComparisonFormState = createFormArrayState<string>(
	comparisonFormKey,
	[]
);

const initialMeasurementsDatePickerFormState = createFormGroupState<MeasurementsDatePickerForm>(
	measurementsDatePickerFormKey,
	{
		startDate: '',
		endDate: '',
	}
);

export interface State {
	loading: boolean;
	loaded: boolean;
	/**
	 * Save the max value of the selected
	 * sensor measurements
	 */
	maxMeasurementsValue: number;
	data: any[];
	windRose: {
		cardinalDirections: string[];
		windSources: {
			valueField: string;
			name: string;
			min: number;
			max: number;
		}[];
		data: any[];
	};
	datePickerForm: FormGroupState<MeasurementsDatePickerForm>;
	comparison: {
		selectedDUIs: Array<any>;
		sensor: string;
		devices: Array<any>;
		data: Array<any>;
	};
	comparisonForm: FormGroupState<string[]>;
	refreshBtnState: ClrLoadingState;
}

export const initialState: State = {
	loading: true,
	loaded: false,
	maxMeasurementsValue: 0,
	data: null,
	windRose: {
		cardinalDirections: [
			'N',
			'NNE',
			'NE',
			'ENE',
			'E',
			'ESE',
			'SE',
			'SSE',
			'S',
			'SSW',
			'SW',
			'WSW',
			'W',
			'WNW',
			'NW',
			'NNW',
		],
		windSources: [
			{ valueField: 'val1', name: '0,2 - 1 m/s', min: 0.2, max: 1 },
			{ valueField: 'val2', name: '1 - 2 m/s', min: 1, max: 2 },
			{ valueField: 'val3', name: '2 - 4 m/s', min: 2, max: 4 },
			{ valueField: 'val4', name: '4 - 8 m/s', min: 4, max: 8 },
			{ valueField: 'val5', name: '8 - 12 m/s', min: 8, max: 12 },
			{ valueField: 'val6', name: '12+ m/s', min: 12, max: Infinity },
		],
		data: null,
	},
	datePickerForm: initialMeasurementsDatePickerFormState,
	comparison: {
		selectedDUIs: [],
		sensor: '',
		devices: [],
		data: [],
	},
	comparisonForm: initialComparisonFormState,
	refreshBtnState: ClrLoadingState.DEFAULT
};

const measurementReducer = createReducer(
	initialState,
	onNgrxForms(),
	on(MeasurementActions.loadMeasurements, state =>
		produce(state, draft => {
			draft.loading = initialState.loading;
			draft.loaded = initialState.loaded;
			draft.data = initialState.data;
		})
	),
	on(MeasurementActions.loadMeasurementsSuccess, (state, { measurements }) =>
		produce(state, draft => {
			draft.data = measurements;
			draft.loading = false;
			draft.loaded = true;
		})
	),
	on(MeasurementActions.clearMeasurements, state =>
		produce(state, draft => {
			draft.data = null;
		})
	),
	on(MeasurementActions.loadWindRoseDataSuccess, (state, { data }) =>
		produce(state, draft => {
			draft.windRose.data = data;
		})
	),
	on(MeasurementActions.changeCardinalDirections, (state, { directions }) =>
		produce(state, draft => {
			draft.windRose.cardinalDirections = directions;
		})
	),
	on(MeasurementActions.changeMaxMeasurementsValue, (state, { value }) =>
		produce(state, draft => {
			draft.maxMeasurementsValue = value;
		})
	),
	on(MeasurementActions.loadMultipleMeasurements, state => produce(state, draft => {
			draft.loading = initialState.loading;
			draft.loaded = initialState.loaded;
			draft.refreshBtnState = ClrLoadingState.LOADING;
	})),
	on(MeasurementActions.loadMultipleMeasurementsSuccess, (state, { data }) =>
		produce(state, (draft) => {
			draft.comparison = { ...draft.comparison, ...data };
			draft.loading = false;
			draft.loaded = true;
			draft.refreshBtnState = ClrLoadingState.SUCCESS;
		})
	),
	on(MeasurementActions.updateSelectedDUIsToCompare, (state, { duis }) =>
		produce(state, (draft) => {
			draft.comparison.selectedDUIs = duis;
		})
	)
);

export function reducer(state: State | undefined, action: Action) {
	return measurementReducer(state, action);
}

export const getLoaded = (state: State) => state.loaded;
export const getMaxMeasurementsValue = (state: State) =>
	state.maxMeasurementsValue;
export const getWindRose = (state: State) => state.windRose;
export const getWindRoseData = (state: State) => state.windRose.data;
export const getDatePickerFormState = (state: State) => state.datePickerForm;
export const getMeasurementsComparison = (state: State) => state.comparison;
export const getMeasurementComparisonFormState = (state: State) =>
	state.comparisonForm;
export const getMeasurementRefreshBtnState = (state: State) => state.refreshBtnState;
