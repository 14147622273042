import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { NotificationsService } from 'app/core/services/notifications.service';
import { NotificationActions } from 'app/auth/actions';

@Injectable()
export class NotificationEffects {
	constructor(
		private actions$: Actions,
		private notificationsService: NotificationsService
	) {}

	createNotification$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.createNotification),
			switchMap(({ userId, message }) =>
				this.notificationsService.createNotification(userId, message).pipe(
					map(() => NotificationActions.createNotificationSuccess()),
					catchError(error =>
						of(NotificationActions.createNotificationFailure({ error }))
					)
				)
			)
		)
	);

	markAsRead$ = createEffect(() =>
		this.actions$.pipe(
			ofType(NotificationActions.markAsRead),
			switchMap(({ notificationId }) =>
				this.notificationsService.markAsRead$(notificationId).pipe(
					map(unreadNotifications =>
						NotificationActions.markAsReadSuccess({ unreadNotifications })
					),
					catchError(error =>
						of(NotificationActions.markAsReadFailure({ error }))
					)
				)
			)
		)
	);
}
