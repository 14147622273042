<button type="button" class="qrt_btn-link" (click)="openModal()">
	Recuperar palavra-passe
</button>

<clr-modal [clrModalOpen]="showModal$ | async" [clrModalClosable]="false">
	<h3 class="modal-title">Esqueceu-se da palavra-passe?</h3>
	<div class="modal-body">
		<p>
			Não se preocupe! Insira o seu endereço de e-mail e enviaremos um link para
			que recupere.
		</p>
		<ng-container *ngIf="formState$ | async as formState">
			<form
				class="qrt_form"
				id="request-password-reset-form"
				novalidate
				ngNoForm
				[ngrxFormState]="formState"
				(submit)="submitForm()"
			>
				<clr-input-container>
					<label for="username">E-mail</label>
					<input
						id="username"
						type="text"
						clrInput
						[ngrxFormControlState]="formState.controls.username"
					/>
				</clr-input-container>

				<clr-alert
					class="qrt_success-alert"
					[clrAlertType]="'success'"
					*ngIf="httpSuccess$ | async"
				>
					<clr-alert-item>
						<span class="alert-text">
							Enviamos o link de recuperação da palavra-passe para o seu email
						</span>
					</clr-alert-item>
				</clr-alert>
			</form></ng-container
		>

		<div
			class="alert alert-app-level alert-warning"
			role="alert"
			*ngIf="httpFailure$ | async"
		>
			<div class="alert-items">
				<div class="alert-item static">
					<div class="alert-icon-wrapper">
						<clr-icon
							class="alert-icon"
							shape="exclamation-triangle"
						></clr-icon>
					</div>
					<div class="alert-text">
						Serviço temporariamente indisponível. Tente novamente mais tarde.
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="qrt_action-btns">
			<button type="button" class="btn btn-outline" (click)="closeModal()">
				Cancelar
			</button>
			<button form="request-password-reset-form" class="btn btn-primary">
				Enviar
			</button>
		</div>
	</div>
</clr-modal>
