import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as fromAuth from 'app/auth/reducers';

@Injectable()
export class RoleGuard implements CanActivate {
	constructor(private router: Router, private store$: Store<fromAuth.State>) {}

	canActivate(): Observable<boolean> {
		return this.store$.pipe(
			select(fromAuth.selectIsAdmin),
			map(isAdmin => {
				if (!isAdmin) {
					this.router.navigate(['/map']);
				}

				return isAdmin;
			}),
			take(1)
		);
	}
}
