<ng-container *ngIf="loaded$ | async">
	<clr-datagrid *ngIf="measurements$ | async as measurements">
		<clr-dg-column>Data</clr-dg-column>
		<clr-dg-column *ngFor="let sensor of measurements.sensors">
			<div class="qrt_measurements-table__column-text">
				<span>{{ getSensorPortugueseName(sensor) }} </span>
				<span class="qrt_measurements-table__unit">{{
					getSensorUnit$(sensor, 'PT') | async
				}}</span>
			</div>
		</clr-dg-column>

		<clr-dg-row *clrDgItems="let timestamp of measurements.data | keyvalue">
			<clr-dg-cell
				class="qrt_measurements-table__cell qrt_measurements-table__cell--date"
				>{{ timestamp.key | date: 'dd/MM/yyyy - HH:mm':'UTC' }}</clr-dg-cell
			>
			<clr-dg-cell *ngFor="let sensorMeasurement of timestamp.value | keyvalue"
				>{{
					applyConversionFactor$(
						sensorMeasurement.value,
						sensorMeasurement.value.value,
						'PT'
					) | async
				}}
			</clr-dg-cell>
		</clr-dg-row>
		<clr-dg-footer>
			<clr-dg-pagination #pagination [clrDgPageSize]="100">
				<clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100, 200, 500]"
					>Registos por página</clr-dg-page-size
				>

				{{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} de
				{{ pagination.totalItems }} registos
			</clr-dg-pagination>
		</clr-dg-footer>
	</clr-datagrid>
</ng-container>
