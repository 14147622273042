// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LoginComponent } from 'app/auth/components/login/login.component';

export const environment = {
	production: true,
	aqiVisible: true,
	client: 'QART',
	api: 'https://clientapi.qart.pt',
	windForecastApi: 'https://portalapi.qart.pt/forecast',
	portalApi: 'https://portalapi.qart.pt',
	translationsFiles: {
		defaultLang: 'pt',
		pt: '/assets/translations/pt.json',
		en: '/assets/translations/en.json',
	},
	versionApp: 'b8bed5900baf2927add6e7058d1e56c256543f22',
	loginComponent: LoginComponent,
	iconMarkersPath: 'assets/icons/levels',
	logoPath: 'assets/img/logo.png'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
