import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ClrLoadingState } from '@clr/angular';

import * as fromLayout from 'app/reducers';
import * as fromAuth from 'app/auth/reducers';
import * as LayoutActions from 'app/core/actions/layout.actions';
import * as UsersActions from 'app/auth/actions/users.actions';

@Component({
	selector: 'qrt-change-disabled-status',
	templateUrl: './change-disabled-status.component.html',
	styleUrls: ['./change-disabled-status.component.scss'],
})
export class ChangeDisabledStatusComponent implements OnInit {
	showModal$: Observable<boolean>;
	selectedUser$: Observable<any>;
	submitButtonState$: Observable<ClrLoadingState>;

	constructor(private store$: Store<any>) {
		this.showModal$ = store$.pipe(
			select(fromLayout.selectShowChangeDisabledStatusModal)
		);
		this.selectedUser$ = store$.pipe(select(fromAuth.selectSelectedUser));
		this.submitButtonState$ = store$.pipe(
			select(fromLayout.selectSubmitChangeDisabledStatusButtonState)
		);
	}

	ngOnInit(): void {}

	closeModal(): void {
		this.store$.dispatch(LayoutActions.closeChangeDisabledStatusModal());
	}

	submitAction(user: any): void {
		this.store$.dispatch(
			UsersActions.changeDisabledStatus({
				userId: user._id,
				disabled: !user.disabled,
			})
		);
	}
}
