import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import * as measurementSelectors from 'app/devices/selectors/measurement.selectors';
import * as DeviceSelectors from 'app/devices/reducers';

@Component({
	selector: 'qrt-side-by-side-bar-chart',
	templateUrl: './side-by-side-bar-chart.component.html',
	styleUrls: ['./side-by-side-bar-chart.component.scss'],
})
export class SideBySideBarChartComponent implements OnInit {
	measurements$: Observable<Array<any>>;
	devices$: Observable<Array<any>>;

	constructor(private store$: Store<any>) {
		this.measurements$ = store$.pipe(
			select(measurementSelectors.selectMeasurementsComparison),
			map((comparison) => comparison.data)
		);

		this.devices$ = store$.pipe(
			select(measurementSelectors.selectMeasurementsComparison),
			withLatestFrom(this.store$.pipe(select(DeviceSelectors.selectAllBoxes))),
			map(([comparison, devices]) => {
				return devices
					.filter((device) => {
						const comparisonDUIs = comparison.devices.map((c) => c.name);

						return comparisonDUIs.find((dui) => dui === device.DUI);
					})
					.map((device, index) => ({
						...comparison.devices[index],
						name: device.Description,
					}));
			})
		);
	}

	ngOnInit(): void {}

	onPointClick(e) {
		e.target.select();
	}
}
