import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { combineLatest, of } from 'rxjs';
import { map, filter, withLatestFrom, catchError } from 'rxjs/operators';

import { BoxActions } from 'app/devices/actions';
import * as LayoutSelectors from 'app/reducers';
import * as BoxSelectors from 'app/devices/reducers';
import * as LayoutActions from 'app/core/actions/layout.actions';
import * as SensorSelectors from 'app/devices/selectors/sensor.selectors';
import * as MeasurementSelectors from 'app/devices/selectors/measurement.selectors';

@Injectable()
export class LayoutEffects {
	selectedSensorConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BoxActions.selectSensor, BoxActions.selectMeteoSensor),
			withLatestFrom(
				this.store$.pipe(select(SensorSelectors.selectSensorsEntities))
			),
			map(([{ sensorfield }, sensors]) => sensors[sensorfield]),
			withLatestFrom(
				this.store$.pipe(select(BoxSelectors.selectSelectedBoxLui))
			),
			map(([sensor, lui]) => ({ sensor, lui })),
			withLatestFrom(
				this.store$.pipe(select(BoxSelectors.selectAllLocationEntities))
			),
			map(([{ sensor, lui }, locations]) => {
				const country = locations[lui]?.Country || 'PT';

				const configurations = sensor?.configurations;
				const configuration = configurations ? configurations[country] : null;

				return LayoutActions.changeSensorConfig({
					config: configuration
						? {
								name: sensor.name,
								limits: configuration.limits
									? configuration.limits.map(limit => ({
											...limit,
											visible: false,
											color:
												'#' + Math.floor(Math.random() * 16777215).toString(16),
									  }))
									: [],
						  }
						: null,
				});
			}),
			// Dispatch an action to reset sensor config if any error occur
			catchError(error =>
				of(LayoutActions.changeSensorConfig({ config: null }))
			)
		)
	);

	changeChartMaxValueMarginBasedOnLimits$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LayoutActions.changeChartMaxValueMarginBasedOnLimits),
			withLatestFrom(
				this.store$.pipe(
					select(LayoutSelectors.selectSensorConfigLimits),
					filter(limits => !!limits),
					map(limits => limits.filter(limit => limit.visible))
				)
			),
			withLatestFrom(
				this.store$.pipe(
					select(MeasurementSelectors.selectMaxMeasurementsValue)
				)
			),
			map(([[action, visibleLimits], maxMeasurementsValue]) => {
				const maxLimitValue = Math.max(
					...visibleLimits.map(limit => limit.value)
				);

				return LayoutActions.changeChartMaxValueMargin({
					value: (maxLimitValue * 1.5) / (maxMeasurementsValue || 1),
				});
			}),
			catchError(() =>
				of(LayoutActions.changeChartMaxValueMargin({ value: 0 }))
			)
		)
	);

	openComparisonModal = createEffect(() =>
		this.actions$.pipe(
			ofType(LayoutActions.changeDeviceDetailsPresentationType),
			map(() => LayoutActions.openComparisonModal())
		)
	);

	constructor(private actions$: Actions, private store$: Store<any>) {}
}
