import { Action, createReducer, createFeatureSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';

import { DetailedLastMeasurement } from '../models/detailed-last-measurement.model';
import * as DetailedLastMeasurementActions from '../actions/detailed-last-measurement.actions';

export const detailedLastMeasurementsFeatureKey = 'detailedLastMeasurements';

export interface State extends EntityState<DetailedLastMeasurement> {
	loading: boolean;
	loaded: boolean;
}

export const adapter: EntityAdapter<DetailedLastMeasurement> =
	createEntityAdapter<DetailedLastMeasurement>({
		selectId: (m: DetailedLastMeasurement) => m.DUI,
		sortComparer: false,
	});

export const initialState: State = adapter.getInitialState({
	loading: true,
	loaded: false,
});

export const reducer = createReducer(
	initialState,
	on(
		DetailedLastMeasurementActions.addDetailedLastMeasurement,
		(state, action) => adapter.addOne(action.detailedLastMeasurement, state)
	),
	on(
		DetailedLastMeasurementActions.upsertDetailedLastMeasurement,
		(state, action) => adapter.upsertOne(action.detailedLastMeasurement, state)
	),
	on(
		DetailedLastMeasurementActions.addDetailedLastMeasurements,
		(state, action) => adapter.addMany(action.detailedLastMeasurements, state)
	),
	on(
		DetailedLastMeasurementActions.upsertDetailedLastMeasurements,
		(state, action) =>
			adapter.upsertMany(action.detailedLastMeasurements, state)
	),
	on(
		DetailedLastMeasurementActions.updateDetailedLastMeasurement,
		(state, action) => adapter.updateOne(action.detailedLastMeasurement, state)
	),
	on(
		DetailedLastMeasurementActions.updateDetailedLastMeasurements,
		(state, action) =>
			adapter.updateMany(action.detailedLastMeasurements, state)
	),
	on(
		DetailedLastMeasurementActions.deleteDetailedLastMeasurement,
		(state, action) => adapter.removeOne(action.id, state)
	),
	on(
		DetailedLastMeasurementActions.deleteDetailedLastMeasurements,
		(state, action) => adapter.removeMany(action.ids, state)
	),
	on(
		DetailedLastMeasurementActions.loadDetailedLastMeasurements,
		(state, action) =>
			produce(state, (draft) => {
				draft.loading = true;
				draft.loaded = false;
			})
	),
	on(
		DetailedLastMeasurementActions.loadDetailedLastMeasurementsSuccess,
		(state, action) =>
			adapter.setAll(
				action.detailedLastMeasurements,
				produce(state, (draft) => {
					draft.loading = false;
					draft.loaded = true;
				})
			)
	),
	on(DetailedLastMeasurementActions.clearDetailedLastMeasurements, (state) =>
		adapter.removeAll(state)
	)
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
	adapter.getSelectors();

export const selectDetailedLastMeasurementState = createFeatureSelector<State>(
	detailedLastMeasurementsFeatureKey
);
