import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeviceAlertActions } from 'app/devices/actions';

@Component({
	selector: 'qrt-delete-alert',
	templateUrl: './delete-alert.component.html',
	styleUrls: ['./delete-alert.component.scss'],
})
export class DeleteAlertComponent implements OnInit {
	@Input() alertId: string;
	open: boolean = false;

	constructor(private store$: Store<any>) {}

	ngOnInit(): void {}

	toggleModal() {
		this.open = !this.open;
	}

	closeModal() {
		this.open = false;
	}

	deleteAlert() {
		this.store$.dispatch(
			DeviceAlertActions.deleteDeviceAlert({ id: this.alertId })
		);
	}
}
