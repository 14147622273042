import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';
import { Sensor } from '../models/sensor.model';
import * as SensorActions from '../actions/sensor.actions';

export const sensorsFeatureKey = 'sensors';

export interface State extends EntityState<Sensor> {
	loading: boolean;
	loaded: boolean;
}

export const adapter: EntityAdapter<Sensor> = createEntityAdapter<Sensor>({
	selectId: (sensor: Sensor) => sensor.name,
});

export const initialState: State = adapter.getInitialState({
	loading: false,
	loaded: false,
});

export const reducer = createReducer(
	initialState,
	on(SensorActions.addSensor, (state, action) =>
		adapter.addOne(action.sensor, state)
	),
	on(SensorActions.upsertSensor, (state, action) =>
		adapter.upsertOne(action.sensor, state)
	),
	on(SensorActions.addSensors, (state, action) =>
		adapter.addMany(action.sensors, state)
	),
	on(SensorActions.upsertSensors, (state, action) =>
		adapter.upsertMany(action.sensors, state)
	),
	on(SensorActions.updateSensor, (state, action) =>
		adapter.updateOne(action.sensor, state)
	),
	on(SensorActions.updateSensors, (state, action) =>
		adapter.updateMany(action.sensors, state)
	),
	on(SensorActions.deleteSensor, (state, action) =>
		adapter.removeOne(action.id, state)
	),
	on(SensorActions.deleteSensors, (state, action) =>
		adapter.removeMany(action.ids, state)
	),
	on(SensorActions.loadSensors, state =>
		produce(state, draft => {
			draft.loading = true;
			draft.loaded = false;
		})
	),
	on(SensorActions.loadSensorsSuccess, (state, action) =>
		adapter.setAll(
			action.sensors,
			produce(state, draft => {
				draft.loading = false;
				draft.loaded = true;
			})
		)
	),
	on(SensorActions.clearSensors, state => adapter.removeAll(state))
);

export const {
	selectIds,
	selectEntities,
	selectAll,
	selectTotal,
} = adapter.getSelectors();

export const selectSensorsLoading = (state: State) => state.loading;
export const selectSensorsLoaded = (state: State) => state.loaded;
