import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'qrt-callout',
	templateUrl: './callout.component.html',
	styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent implements OnInit {
	@Input() icon: string;
	@Input() heading: string;
	@Input() content: string;

	constructor() {}

	ngOnInit(): void {}
}
