import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';
import { Measurement } from 'app/devices/models/measurement';
import { LastMeasurementActions } from 'app/devices/actions';

export const lastMeasurementsFeatureKey = 'lastmeasurements';

export interface State extends EntityState<Measurement> {
	loading: boolean;
	loaded: boolean;
}

export const adapter: EntityAdapter<Measurement> =
	createEntityAdapter<Measurement>({
		selectId: (m: Measurement) => m.DUI,
		sortComparer: false,
	});

export const initialState: State = adapter.getInitialState({
	loading: true,
	loaded: false,
});

const measurementReducer = createReducer(
	initialState,
	on(LastMeasurementActions.loadLastMeasurementsSuccess, (state, action) =>
		adapter.setAll(
			action.lastmeasurements,
			produce(state, (draft) => {
				draft.loading = false;
				draft.loaded = true;
			})
		)
	)
);

export function reducer(state: State | undefined, action: Action) {
	return measurementReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
	adapter.getSelectors();

export const getLoaded = (state: State) => state.loaded;
