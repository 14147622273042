import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Sensor } from '../models/sensor.model';

export const loadSensors = createAction('[Sensor/API] Load Sensors');

export const loadSensorsSuccess = createAction(
	'[Sensor/API] Load Sensors Success',
	props<{ sensors: Sensor[] }>()
);

export const loadSensorsFailure = createAction(
	'[Sensor/API] Load Sensors Failure',
	props<{ error: any }>()
);

export const addSensor = createAction(
	'[Sensor/API] Add Sensor',
	props<{ sensor: Sensor }>()
);

export const upsertSensor = createAction(
	'[Sensor/API] Upsert Sensor',
	props<{ sensor: Sensor }>()
);

export const addSensors = createAction(
	'[Sensor/API] Add Sensors',
	props<{ sensors: Sensor[] }>()
);

export const upsertSensors = createAction(
	'[Sensor/API] Upsert Sensors',
	props<{ sensors: Sensor[] }>()
);

export const updateSensor = createAction(
	'[Sensor/API] Update Sensor',
	props<{ sensor: Update<Sensor> }>()
);

export const updateSensors = createAction(
	'[Sensor/API] Update Sensors',
	props<{ sensors: Update<Sensor>[] }>()
);

export const deleteSensor = createAction(
	'[Sensor/API] Delete Sensor',
	props<{ id: string }>()
);

export const deleteSensors = createAction(
	'[Sensor/API] Delete Sensors',
	props<{ ids: string[] }>()
);

export const clearSensors = createAction('[Sensor/API] Clear Sensors');
