import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	map,
	mergeMap,
	catchError,
	exhaustMap,
	withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromAuth from 'app/auth/reducers';
import { MapActions } from 'app/map/actions';

@Injectable()
export class MapEffects {

	constructor(
		private store: Store<any>,
		private actions$: Actions,
	) {}
}
