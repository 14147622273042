<clr-modal
	[clrModalOpen]="opened$ | async"
	[clrModalClosable]="false"
	[clrModalSize]="'sm'"
>
	<h3 data-testid="notification-modal-title" class="modal-title">
		Criar Notificação
	</h3>
	<div *ngIf="selectedUser$ | async as selectedUser" class="modal-body">
		<h6>{{ selectedUser.firstName }} {{ selectedUser.lastName }}</h6>
		<h6 class="qrt_notification-modal__email">{{ selectedUser.email }}</h6>

		<form
			[formGroup]="notificationForm"
			id="notification-form"
			(ngSubmit)="sendNotification()"
		>
			<clr-input-container>
				<label>ID</label>
				<input clrInput formControlName="userId" [readonly]="true" />
			</clr-input-container>

			<clr-textarea-container>
				<label>Texto</label>
				<textarea
					clrTextarea
					formControlName="text"
					cols="30"
					rows="4"
				></textarea>
			</clr-textarea-container>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn" (click)="closeModal()">Cancelar</button>
		<button
			[clrLoading]="sendBtnState"
			form="notification-form"
			class="btn btn-primary"
			[disabled]="!notificationForm.valid"
		>
			Enviar
		</button>
	</div>
</clr-modal>
