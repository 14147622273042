<clr-modal
	[clrModalOpen]="showModal$ | async"
	[clrModalClosable]="false"
	*ngIf="selectedUser$ | async as user"
>
	<h3 class="modal-title">
		Você tem certeza que deseja {{ user.disabled ? 'activar' : 'desactivar' }}
		este utilizador?
	</h3>
	<div class="modal-body">
		<p>ID: {{ user._id }}</p>
		<p>Email: {{ user.email }}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="closeModal()">
			Cancelar
		</button>
		<button
			type="button"
			class="btn btn-primary"
			[clrLoading]="submitButtonState$ | async"
			(click)="submitAction(user)"
		>
			Confirmar
		</button>
	</div>
</clr-modal>
