import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Box } from 'app/devices/models/box.model';
import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BoxService {
	constructor(private http: HttpClient) {}

	public getAll$(): Observable<Array<Box>> {
		return this.http.get<Array<Box>>(`${environment.api}/entities`);
	}

	public getByLui(lui: number): Observable<Box> {
		return this.http.get<Box>(`${environment.api}/entities/bylui/${lui}`);
	}

	requestExport$(
		startDate: string,
		endDate: string,
		average: string,
		boxes: Array<string>,
		preferences: any
	): Observable<any> {
		return this.http.post(`${environment.api}/exportmeasurements`, {
			startDate,
			endDate,
			average,
			boxes,
			preferences
		});
	}

	requestDiagnosis$(dui: string): Observable<any> {
		return this.http.get(`${environment.api}/devicediagnosis?dui=${dui}`);
	}
}
