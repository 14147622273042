import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter, withLatestFrom, take } from 'rxjs/operators';
import { FormGroupState } from 'ngrx-forms';

import { ResetPasswordForm } from 'app/auth/reducers/status.reducer';
import * as fromAuth from 'app/auth/reducers';
import { StatusActions } from 'app/auth/actions';

@Component({
	selector: 'qrt-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	formState$: Observable<FormGroupState<ResetPasswordForm>>;
	formErrors$: Observable<string[]>;
	submitButtonDisabled$: Observable<boolean>;
	resetPasswordHttpError$: Observable<boolean>;

	constructor(
		private store$: Store<fromAuth.State>,
		private route: ActivatedRoute
	) {
		this.formState$ = store$.pipe(select(fromAuth.selectResetPasswordForm));
		this.formErrors$ = this.formState$.pipe(
			map(form => {
				const errors = [];
				const passwordControl = form.controls.password;
				const passwordErrors = form.errors._password;
				const passwordConfirmationControl = form.controls.passwordConfirmation;
				const passwordConfirmationErrors = form.errors._passwordConfirmation;

				if (
					(passwordErrors?.minLength || passwordErrors?.maxLength) &&
					(passwordConfirmationErrors?.minLength ||
						passwordConfirmationErrors?.maxLength)
				) {
					errors.push('É preciso ter entre 6 a 65 caracteres.');
				} else if (
					passwordControl.value !== passwordConfirmationControl.value
				) {
					errors.push('As palavras passes não coincidem.');
				}

				return errors;
			})
		);
		this.submitButtonDisabled$ = this.formState$.pipe(
			withLatestFrom(this.formErrors$),
			map(([form, errors]) => form.isInvalid || !!errors.length)
		);
		this.resetPasswordHttpError$ = store$.pipe(
			select(fromAuth.selectResetPasswordStatus),
			filter(s => s === 'failure'),
			map(s => !!s)
		);
	}

	ngOnInit(): void {}

	async submitForm(): Promise<void> {
		const token = await this.route.queryParams
			.pipe(
				map(params => params.u),
				take(1)
			)
			.toPromise();

		const newPassword = await this.formState$
			.pipe(
				map(form => form.controls.password.value),
				take(1)
			)
			.toPromise();

		this.store$.dispatch(
			StatusActions.submitResetPassword({ token, newPassword })
		);
	}
}
