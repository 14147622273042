<button
	class="btn"
	type="button"
	(click)="openModal()"
	data-testid="new-issue-btn"
>
	<clr-icon shape="plus"></clr-icon>
	Registar anomalia
</button>

<clr-modal [clrModalOpen]="showModal$ | async" [clrModalClosable]="false">
	<h3 class="modal-title">Registar anomalia em dispositivo</h3>

	<div class="modal-body" *ngIf="form$ | async as formState">
		<form
			id="new-issue-form"
			novalidate
			ngNoForm
			clrForm
			[ngrxFormState]="formState"
			(submit)="createIssue(formState.value)"
		>
			<clr-select-container>
				<label>Dispositivo</label>

				<select
					clrSelect
					name="location"
					[ngrxFormControlState]="formState.controls.location"
				>
					<option
						*ngFor="let location of locations$ | async"
						[value]="location.LUI"
					>
						{{ location.LUI }} - {{ location.Description }}
					</option>
				</select>
			</clr-select-container>

			<div>
				<clr-radio-container>
					<label>Incidente</label>

					<clr-radio-wrapper>
						<label>Mau funcionamento</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.incident"
							clrRadio
							value="malfunction"
						/>
					</clr-radio-wrapper>

					<clr-radio-wrapper>
						<label>Vandalismo/Roubo</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.incident"
							clrRadio
							value="vandalism"
						/>
					</clr-radio-wrapper>

					<clr-radio-wrapper>
						<label>Acidente</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.incident"
							clrRadio
							value="accident"
						/>
					</clr-radio-wrapper>

					<clr-radio-wrapper>
						<label>Outro</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.incident"
							clrRadio
							value="other"
						/>
					</clr-radio-wrapper>
				</clr-radio-container>

				<clr-radio-container>
					<label>Prioridade</label>

					<clr-radio-wrapper>
						<label>Baixa</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.priority"
							clrRadio
							value="0"
						/>
					</clr-radio-wrapper>

					<clr-radio-wrapper>
						<label>Normal</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.priority"
							clrRadio
							value="1"
						/>
					</clr-radio-wrapper>

					<clr-radio-wrapper>
						<label>Alta</label>
						<input
							type="radio"
							[ngrxFormControlState]="formState.controls.priority"
							clrRadio
							value="2"
						/>
					</clr-radio-wrapper>
				</clr-radio-container>
			</div>

			<clr-textarea-container>
				<label>Descrição</label>
				<textarea
					clrTextarea
					name=""
					cols="30"
					rows="2"
					[ngrxFormControlState]="formState.controls.description"
				></textarea>
			</clr-textarea-container>
		</form>
	</div>

	<div class="modal-footer">
		<button class="btn" type="button" (click)="closeModal()">Cancelar</button>
		<button
	  		type="submit"
			form="new-issue-form"
			class="btn btn-primary"
			[clrLoading]="submitBtnState$ | async"
		>
			Registar
		</button>
	</div>
</clr-modal>
