import {
	Component,
	OnDestroy,
	OnInit,
	ComponentFactoryResolver,
	Injector,
	ComponentRef,
	DoCheck,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, filter, tap, take, withLatestFrom } from 'rxjs/operators';
import * as L from 'leaflet';

import 'leaflet.markercluster';
import 'leaflet-velocity';

import * as LG from '@qartlabs/leaflet-geotiff';
import '@qartlabs/leaflet-geotiff/leaflet-geotiff-plotty';

import { MapActions } from 'app/map/actions';
import {
	BoxActions,
	LastMeasurementActions,
	OldestMeasurementActions,
	LocationActions,
} from 'app/devices/actions';
import * as ForecastActions from 'app/core/actions/forecast.actions';
import * as ForecastSelectors from 'app/core/selectors/forecast.selectors';
import * as LayoutSelectors from 'app/reducers';
import * as fromDevices from 'app/devices/reducers';
import * as fromAuth from 'app/auth/reducers';
import { MeasurementsService } from 'app/devices/services/measurements.service';

import { PopupComponent } from 'app/map/components/popup/popup.component';
import { formatMeasurement } from 'app/shared/helpers/measurement';
import { environment } from 'environments/environment';

interface MarkerMetaData {
	name: string;
	markerInstance: L.Marker;
	componentInstance: ComponentRef<PopupComponent>;
}

@Component({
	selector: 'qrt-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
	map;
	timer$: any;
	devicesSubscription: Subscription;
	updateInterval;

	avgIndexLevel$: Observable<object>;
	meteoActivated$: Observable<boolean>;

	latestWindForecast$: Observable<any>;

	env = environment;

	user$: Observable<any>;
	isAdmin$: Observable<boolean>;
	leafletOptions$: Observable<any>;

	// VERSION CONTROL
	attribution = 'Powered by QART, LDA | v2024.01.11.1 | © OpenStreetMap contributors';

	public markers: MarkerMetaData[] = [];

	public markerClusterGroup: L.MarkerClusterGroup;
	public markerClusterData: L.Marker[] = [];
	public markerClusterOptions: L.MarkerClusterGroupOptions = {
		spiderfyOnMaxZoom: true,
		showCoverageOnHover: false,
		zoomToBoundsOnClick: true,
	};

	layersControl = {
		baseLayers: {
			PM10: L.tileLayer.wms('http://geoserver.qart.pt/wms', {
				layers: 'QART:PM10',
				format: 'image/png',
				transparent: true,
				opacity: 0.4,
				version: '1.3.0',
				crs: L.CRS.EPSG3857,
			}),
			Claro: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				maxZoom: 18,
				attribution: this.attribution,
			}),
			Escuro: L.tileLayer(
				'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
				{
					maxZoom: 18,
					attribution: this.attribution,
				}
			),
		},
		overlays: {},
	};

	constructor(
		private store$: Store<any>,
		private measurementsService: MeasurementsService,
		private resolver: ComponentFactoryResolver,
		private injector: Injector
	) {
		this.avgIndexLevel$ = store$.pipe(select(fromDevices.selectAvgIndexLevel));
		this.meteoActivated$ = store$.select(fromAuth.selectMeteorology);
		this.latestWindForecast$ = store$.pipe(
			select(ForecastSelectors.selectForecastData)
		);
		this.user$ = store$.pipe(
			select(fromAuth.selectUserData),
			filter(userData => !!userData),
			map(userData => userData.user)
		);
		this.isAdmin$ = store$.select(fromAuth.selectIsAdmin);

		this.leafletOptions$ = this.user$.pipe(
			map(user => {
				const zoom = user.geo?.zoom || 7;
				const lat = user.geo?.lat || 39.399872;
				const lng = user.geo?.lng || -8.224454;

				return {
					layers: [
						L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
							maxZoom: 18,
							attribution: this.attribution,
						}),
					],
					zoom,
					center: L.latLng(lat, lng),
				};
			})
		);
	}

	ngOnInit() {
		this.store$.dispatch(MapActions.loadMap());
		this.store$.dispatch(LocationActions.loadLocations());
		this.store$.dispatch(BoxActions.loadBoxes());
		this.store$.dispatch(LastMeasurementActions.loadLastMeasurements());
		this.store$.dispatch(OldestMeasurementActions.loadOldestMeasurements());

		this.updateInterval = setInterval(
			() => this.store$.dispatch(LastMeasurementActions.loadLastMeasurements()),
			60000
		);
		this.store$.dispatch(ForecastActions.loadLatestWindForecast());
		this.latestWindForecast$
			.pipe(
				filter(data => !!data),
				tap(data => {
					this.layersControl.overlays['Vento'] = L.velocityLayer({
						displayValues: true,
						colorScale: ['#666'],
						displayOptions: {
							velocityType: 'Vento',
							directionString: 'Direção:',
							speedString: 'Velocidade:',
							displayPosition: 'bottomleft',
							displayEmptyString: 'Não dispõe de dados',
						},
						data,
						maxVelocity: 15,
					});
				}),
				take(1)
			)
			.subscribe();
	}

	onMapReady(mapInstance: L.Map) {
		this.map = mapInstance;

		this.loadDevicesAndMarkers();
	}

	loadDevicesAndMarkers() {
		this.devicesSubscription = combineLatest(
			this.store$.pipe(select(fromDevices.selectAllBoxes)),
			this.store$.pipe(select(fromDevices.selectAllLastMeasurementsEntities)),
			this.store$.pipe(select(fromDevices.selectAllLocations))
		).subscribe(([devices, lastmeasurements, locations]) => {
			// That local array will receive the L.marker and popupContent
			// to construct our map
			const mapData = [];

			// since boxes is an Object we need to check if the object is empty
			// because the first stream from the observable is empty
			if (
				Object.keys(devices).length > 0 &&
				Object.keys(lastmeasurements).length > 0 &&
				Object.keys(locations).length > 0
			) {
				for (const location of locations) {
					const device = devices.find(
						deviceData => deviceData.currentLUI === location.LUI
					);
					const boxHasMeasurements = !!lastmeasurements[device?.DUI];

					if (!boxHasMeasurements) {
						continue;
					}

					const measurements = formatMeasurement(
						lastmeasurements[device.DUI],
						[]
					);

					// dynamically instantiate a PopupComponent
					const factory = this.resolver.resolveComponentFactory(PopupComponent);
					// we need to pass in the dependency injector
					const component = factory.create(this.injector);

					// wire up the @Input()
					component.instance.device = device;
					component.instance.location = location;
					component.instance.measurements = measurements;
					component.instance.meteoActivated$ = this.meteoActivated$;
					component.instance.isAdmin$ = this.isAdmin$;

					// manually trigger change detection
					component.changeDetectorRef.detectChanges();

					// pass in the HTML from our dynamic component
					const popupContent = component.location.nativeElement;

					// create a new Leaflet marker at the given position
					// and add popup functionality
					const marker = L.marker(
						[location.LatLong.lat, location.LatLong.lng],
						{
							icon: measurements.icon,
						}
					)
						.on('mouseover', () => {
							this.measurementsService
								.getLastHourMeasurement$(device.DUI)
								.pipe(take(1))
								.subscribe(result => {
									const hourMeasurement = result.values.map(
										({ sensor, value }) => {
											const conversionFactor = device.Config.Sensors.find(
												aqSensor => aqSensor.apiFieldName === sensor
											)?.conversionFactor;

											return conversionFactor
												? {
														sensor,
														value,
														conversionFactor: conversionFactor[0],
												  }
												: { sensor, value };
										}
									);

									component.instance.measurements = formatMeasurement(
										lastmeasurements[device.DUI],
										hourMeasurement
									);
								});
						})
						.bindPopup(popupContent);

					// metadata object into which helps us keep track of the instantiated
					// markers for removing/disposing them later
					this.markers.push({
						name: location.Address,
						markerInstance: marker,
						componentInstance: component,
					});

					mapData.push(marker);
				}

				this.markerClusterData = mapData;
			}
		});
	}

	removeMarker(marker) {
		const idx = this.markers.indexOf(marker);
		this.markers.splice(idx, 1);

		marker.markerInstance.removeFrom(this.map);

		marker.componentInstance.destroy();
	}

	ngDoCheck() {
		this.markers.forEach(marker => {
			marker.componentInstance.changeDetectorRef.detectChanges();
		});
	}

	ngOnDestroy() {
		clearInterval(this.updateInterval);
		this.devicesSubscription.unsubscribe();
		this.markers.forEach(marker => this.removeMarker(marker));
	}
}
