import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { produce } from 'immer';
import { Measurement } from 'app/devices/models/measurement';
import { OldestMeasurementActions } from 'app/devices/actions';

export const oldestMeasurementsFeatureKey = 'oldestmeasurements';

export interface State extends EntityState<Measurement> {
  loading: boolean;
  loaded: boolean;
}

export const adapter: EntityAdapter<Measurement> = createEntityAdapter<
  Measurement
>({
  selectId: (measurement: Measurement) => measurement.DUI,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: true,
  loaded: false,
});

const measurementReducer = createReducer(
  initialState,
  on(OldestMeasurementActions.loadOldestMeasurementsSuccess, (state, action) =>
    adapter.setAll(
      action.oldestMeasurements,
      produce(state, draft => {
        draft.loading = false;
        draft.loaded = true;
      })
    )
  )
);

export function reducer(state: State | undefined, action: Action) {
  return measurementReducer(state, action);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const getLoaded = (state: State) => state.loaded;