import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import * as DeviceSelectors from 'app/devices/reducers';
import * as LayoutSelectors from 'app/reducers';
import * as LayoutActions from 'app/core/actions/layout.actions';

@Component({
	selector: 'qrt-presentation-mode-controller',
	templateUrl: './presentation-mode-controller.component.html',
	styleUrls: ['./presentation-mode-controller.component.scss'],
})
export class PresentationModeControllerComponent implements OnInit {
	selectedDevice$: Observable<any>;
	isMeteoStation$: Observable<boolean>;
	hasWindDirectionSensor$: Observable<boolean>;

	constructor(private store$: Store<any>) {
		this.selectedDevice$ = store$.pipe(
			select(DeviceSelectors.selectSelectedBoxById)
		);

		this.isMeteoStation$ = this.selectedDevice$.pipe(
			filter(device => !!device),
			map(device => !!device.MeteoStation)
		);

		this.hasWindDirectionSensor$ = this.selectedDevice$.pipe(
			filter(device => !!device),
			map(device => {
				const meteoSensors = device?.Config?.MeteoSensors || [];

				return meteoSensors.find(
					sensor => sensor.apiFieldName === 'METEOWINDDIRECTION'
				);
			})
		);
	}

	ngOnInit(): void {}

	isTypeActivated$(type: string): Observable<boolean> {
		return this.store$.pipe(
			select(LayoutSelectors.selectDeviceDetailsPresentationType),
			map(selectedType => selectedType === type)
		);
	}

	presentationTypeChange(event) {
		const type = event.target.id;

		this.store$.dispatch(
			LayoutActions.changeDeviceDetailsPresentationType({ presType: type })
		);
	}

	ngOnDestroy(): void {
		this.store$.dispatch(
			LayoutActions.changeDeviceDetailsPresentationType({ presType: 'chart' })
		);
	}
}
