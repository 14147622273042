import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/auth/services/auth-guard.service';
import { TechSupportComponent } from 'app/core/components/tech-support/tech-support.component';
import { IssuesComponent } from 'app/devices/components/issues/issues.component';
import { DustComponent } from './core/components/dust/dust.component';

const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{
		path: 'map',
		loadChildren: () => import('app/map/map.module').then((m) => m.MapModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'support',
		component: TechSupportComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'dust',
		component: DustComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'support/issues',
		component: IssuesComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
