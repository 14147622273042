import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormBuilder,
	FormArray,
	AbstractControl,
	Validators,
	ValidatorFn,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store, ActionsSubject, select } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import moment from 'moment';
import { ClrLoadingState } from '@clr/angular';

import * as fromAuth from 'app/auth/reducers';
import * as fromDevices from 'app/devices/reducers';
import { BoxActions } from 'app/devices/actions';
import { Box } from 'app/devices/models/box.model';
import { getMaxDaysFromAvgType } from 'app/shared/helpers/average';

function invalidAverage(
	startDateControl: AbstractControl,
	endDateControl: AbstractControl,
	avg: string
) {
	const startDate = moment(startDateControl.value, 'DD/MM/YYYY');
	const endDate = moment(endDateControl.value, 'DD/MM/YYYY');

	return endDate.diff(startDate, 'days') >= getMaxDaysFromAvgType(avg);
}

function compareDates(
	startDateControlName: string,
	endDateControlName: string,
	averageControlName: string
) {
	return (formGroup: FormGroup) => {
		const startDateControl = formGroup.controls[startDateControlName];
		const endDateControl = formGroup.controls[endDateControlName];
		const averageControl = formGroup.controls[averageControlName];

		const startDate = moment(startDateControl.value, 'DD/MM/YYYY');
		const endDate = moment(endDateControl.value, 'DD/MM/YYYY');

		if (startDate.isAfter(endDate)) {
			startDateControl.setErrors({ dateError: true });
		}

		if (
			invalidAverage(startDateControl, endDateControl, averageControl.value)
		) {
			averageControl.setErrors({ dateError: true });
		}
	};
}

function validateBoxes(): ValidatorFn {
	return (formArray: FormArray): { [key: string]: boolean } | null => {
		const boxes = formArray.controls.filter(box => !!box.value);

		if (!Object.entries(boxes || {}).length) {
			return { error: true };
		}
	};
}

@Component({
	selector: 'qrt-export-measurements',
	templateUrl: './export-measurements.component.html',
	styleUrls: ['./export-measurements.component.scss'],
})
export class ExportMeasurementsComponent implements OnInit {
	isAdmin$: Observable<boolean>;
	isModalVisible: boolean = false;
	exportForm: FormGroup;

	boxes$: Observable<Box[]>;

	exportBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	maxDate: string = moment().format('YYYY-MM-DD');

	constructor(
		private store$: Store<any>,
		private actionsSubject: ActionsSubject,
		private fb: FormBuilder
	) {
		this.isAdmin$ = store$.pipe(select(fromAuth.selectIsAdmin));
		this.exportForm = this.fb.group(
			{
				notifyBySms: [false],
				startDate: ['', Validators.required],
				endDate: ['', Validators.required],
				average: ['', Validators.required],
				boxes: new FormArray([], validateBoxes()),
			},
			{ validator: compareDates('startDate', 'endDate', 'average') }
		);
		this.boxes$ = store$.pipe(select(fromDevices.selectAllBoxes));
	}

	ngOnInit(): void {
		this.boxes$
			.pipe(
				filter(boxes => !!boxes),
				take(1)
			)
			.subscribe(boxes => {
				boxes.forEach(box => {
					const control = new FormControl(false);
					(this.exportForm.controls.boxes as FormArray).push(control);
				});
			});
	}

	get startDateControl() {
		return this.exportForm.controls['startDate'];
	}

	get endDateControl() {
		return this.exportForm.controls['endDate'];
	}

	get selectAllControl() {
		return this.exportForm.controls['selectAllBoxes'];
	}

	openExportModal() {
		this.isModalVisible = true;
	}

	invalidAvg(avg: string) {
		return invalidAverage(this.startDateControl, this.endDateControl, avg);
	}

	selectAll(selectAllBoxes: boolean) {
		const formValues = this.exportForm.value;

		const boxes = Array.from(
			Array(formValues.boxes.length),
			box => selectAllBoxes
		);

		this.exportForm.controls['boxes'].patchValue(boxes, {
			emitEvent: false,
		});
	}

	requestExport() {
		const {
			notifyBySms,
			startDate,
			endDate,
			average,
			boxes,
		} = this.exportForm.controls;
		this.exportBtnState = ClrLoadingState.LOADING;

		this.boxes$
			.pipe(
				filter(box => !!box),
				take(1)
			)
			.subscribe(boxesList => {
				this.store$.dispatch(
					BoxActions.requestExport({
						startDate: moment(startDate.value, 'DD/MM/YYYY').toISOString(),
						endDate: moment(endDate.value, 'DD/MM/YYYY').toISOString(),
						average: average.value,
						boxes: boxes.value
							.map((val, i) => (val ? boxesList[i].DUI : null))
							.filter(val => !!val),
						preferences: { sms: notifyBySms.value },
					})
				);
			});

		this.actionsSubject
			.pipe(ofType(BoxActions.requestExportSuccess), take(1))
			.subscribe(() => {
				this.exportBtnState = ClrLoadingState.SUCCESS;
				this.isModalVisible = false;
			});
	}
}
