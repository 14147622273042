import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StatusActions } from 'app/auth/actions';
import * as fromAuth from 'app/auth/reducers';

@Component({
	selector: 'qrt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	loggedIn$: Observable<boolean>;
	loggedIn$ub: Subscription;

	loginForm: FormGroup;

	hasError$: Observable<boolean>;
	changePwSuccess$: Observable<boolean>;

	constructor(
		private store$: Store<any>,
		private formBuilder: FormBuilder,
		private router: Router
	) {
		this.loggedIn$ = this.store$.select(fromAuth.selectLoggedIn);
		this.hasError$ = this.store$.select(fromAuth.selectHasError);
		this.changePwSuccess$ = store$.pipe(
			select(fromAuth.selectResetPasswordStatus),
			filter(s => s === 'success'),
			map(s => !!s)
		);
	}

	ngOnInit() {
		/**
		 * Redirects to map if the user is already loggedIn
		 * and tries to access the /login page
		 */
		this.loggedIn$ub = this.loggedIn$.subscribe(loggedIn => {
			if (loggedIn) {
				this.router.navigateByUrl('/map');
			}
		});

		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
		});
	}

	onSubmit() {
		const username = this.loginForm.controls.username.value;
		const password = this.loginForm.controls.password.value;

		this.store$.dispatch(
			StatusActions.login({ credentials: { username, password } })
		);
	}

	ngOnDestroy() {
		this.loggedIn$ub.unsubscribe();
	}
}
