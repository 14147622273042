import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { DetailedLastMeasurementActions } from 'app/devices/actions';
import * as DetailedLastmeasurementSelectors from 'app/devices/selectors/detailed-last-measurement.selectors';

@Component({
	selector: 'qrt-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
	loading$: Observable<boolean>;
	detailedLastMeasurements$: Observable<any>;
	currentPageSize = 12;
	selectedDevice = '000000000001';
	selectedDeviceContent = undefined;

	constructor(private store$: Store<any>) {
		this.loading$ = this.store$.pipe(
			select(DetailedLastmeasurementSelectors.selectLoading)
		);
		this.detailedLastMeasurements$ = this.store$.pipe(
			select(DetailedLastmeasurementSelectors.selectAllDeitaledLastMeasurements)
		);
	}

	ngOnInit() {
		this.store$.dispatch(
			DetailedLastMeasurementActions.loadDetailedLastMeasurements()
		);
	}

	selectionChanged(event) {
		if (event) {
			const { _id, ...content } = event;
			this.selectedDeviceContent = content;
			console.log(this.selectedDeviceContent);
		}
	}

	selectSensorPerType(data: any, type: any) {
		const sensor = data.measurement.values.find(v => v.field === type);
		if (!sensor) { return '-'; }
		return !isNaN(sensor.value) && sensor.value !== null ? parseFloat(sensor.value).toFixed(2) : '-';
	}

}
