import { Injectable, Injector } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import {
	first,
	flatMap,
	map,
	mergeMap,
	take,
	catchError,
} from 'rxjs/operators';

import * as fromAuth from 'app/auth/reducers';
import { StatusActions } from 'app/auth/actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private store$: Store<fromAuth.State>) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return this.store$.select(fromAuth.selectUserData).pipe(
			flatMap(userData => {
				const authReq = !!userData
					? req.clone({
							setHeaders: { Authorization: userData.id },
					  })
					: req;
				return next.handle(authReq).pipe(
					map((event: HttpEvent<any>) => event),
					catchError((error: HttpErrorResponse) => {
						if (error.status === 401) {
							this.store$.dispatch(StatusActions.logout());
						}

						return throwError(error);
					})
				);
			})
		);
	}
}