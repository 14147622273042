import { Component, OnInit, OnDestroy } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	FormControl,
	FormArray,
	Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { ClrLoadingState } from '@clr/angular';

import { UserData } from 'app/auth/models/user';
import * as fromAuth from 'app/auth/reducers';
import { StatusActions } from 'app/auth/actions';

import countryCodesList from 'app/shared/helpers/country-codes.json';

@Component({
	selector: 'qrt-personal-data',
	templateUrl: './personal-data.component.html',
	styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit, OnDestroy {
	public changePersonalDataForm: FormGroup = this.fb.group({
		email: ['', [Validators.required]],
		country: [''],
		phone: [
			'',
			[Validators.required, Validators.minLength(9), Validators.maxLength(13)],
		],
	});

	countryCodes = countryCodesList;

	submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

	user$: Observable<UserData>;
	user$ub: Subscription;

	constructor(private fb: FormBuilder, private store$: Store<fromAuth.State>) {
		this.user$ = this.store$.pipe(select(fromAuth.selectUserData));
	}

	ngOnInit() {
		this.user$ub = this.user$.subscribe(userData => {
			this.changePersonalDataForm.patchValue({
				email: userData.user.email || '',
				phone: userData.user.phone || '',
				country: userData.user.countryCode || '',
			});
		});
	}

	changePersonalData() {
		this.submitBtnState = ClrLoadingState.LOADING;

		const phone = this.changePersonalDataForm.controls.phone.value;
		const countryCode = this.changePersonalDataForm.controls.country.value;

		this.store$.dispatch(
			StatusActions.updateUser({ data: { phone, countryCode } })
		);

		setTimeout(() => (this.submitBtnState = ClrLoadingState.DEFAULT), 1000);
	}

	ngOnDestroy() {
		this.user$ub.unsubscribe();
	}
}
