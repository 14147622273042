import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { LastMeasurementActions } from 'app/devices/actions';
import * as fromAuth from 'app/auth/reducers';

import { MeasurementsService } from 'app/devices/services/measurements.service';

@Injectable()
export class LastMeasurementEffects {
	loadLastMeasurements$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(LastMeasurementActions.loadLastMeasurements),
			mergeMap(action =>
				this.measurementsService.getAllLastMeasurements$().pipe(
					map(lastmeasurements =>
						LastMeasurementActions.loadLastMeasurementsSuccess({
							lastmeasurements,
						})
					),
					catchError(error =>
						of(LastMeasurementActions.loadLastMeasurementsFailure({ error }))
					)
				)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private store$: Store<any>,
		private measurementsService: MeasurementsService
	) {}
}
