import { environment } from 'environments/environment';
import { icon } from 'leaflet';

export const yellowIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/img/marker_yellow.png',
	shadowUrl: 'assets/img/shadow.png',
});
export const blackIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/img/marker_black.png',
	shadowUrl: 'assets/img/shadow.png',
});
export const blueIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/img/marker_blue.png',
	shadowUrl: 'assets/img/shadow.png',
});
export const greenIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/img/marker_green.png',
	shadowUrl: 'assets/img/shadow.png',
});
export const bordeauxIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/img/marker_bordeaux.png',
	shadowUrl: 'assets/img/shadow.png',
});
export const errorIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/icons/Imagens_Erro.png',
});
export const normalIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/icons/Normal.png',
});
export const goodIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: `${environment.iconMarkersPath}/aqi_4.png`,
});
export const weakIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: `${environment.iconMarkersPath}/aqi_2.png`,
});
export const badIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: `${environment.iconMarkersPath}/aqi_1.png`,
});
export const averageIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: `${environment.iconMarkersPath}/aqi_3.png`,
});
export const greatIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: `${environment.iconMarkersPath}/aqi_5.png`,
});
export const offIcon = icon({
	iconSize: [32, 32],
	iconAnchor: [16, 32],
	iconUrl: 'assets/icons/Imagens_Off.png',
});