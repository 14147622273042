<div class="qrt_app-container">
	<ng-container *ngIf="loggedIn$ | async">
		<qrt-maintenance></qrt-maintenance>

		<div class="qrt_app-container__top">
			<ng-container *ngIf="client === 'QART'">
				<qrt-header> </qrt-header>
			</ng-container>

			<ng-container *ngIf="client === 'CASCAIS'">
				<qrt-headercascais></qrt-headercascais>
			</ng-container>

			<ng-container *ngIf="client === 'ALTICE'">
				<qrt-headeraltice></qrt-headeraltice>
			</ng-container>

			<ng-container *ngIf="client === 'LISBOA'">
				<qrt-headerlisboa></qrt-headerlisboa>
			</ng-container>

			<ng-container *ngIf="client === 'LISBOA'; else dSubnav">
				<qrt-subnav-lisboa></qrt-subnav-lisboa>
			</ng-container>

			<ng-template #dSubnav> <qrt-subnav></qrt-subnav> </ng-template>
		</div>

		<qrt-alert></qrt-alert>
	</ng-container>
	<router-outlet></router-outlet>
</div>
