import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
	Measurement,
	MeasurementsByTimeStamp,
} from 'app/devices/models/measurement';

export const loadMeasurements = createAction(
	'[Measurement/API] Load Measurements',
	props<{ cfg: MeasurementsByTimeStamp }>()
);

export const loadMeasurementsSuccess = createAction(
	'[Measurement/API] Load Measurements Success',
	props<{ measurements: Measurement[] }>()
);

export const loadMeasurementsFailure = createAction(
	'[Measurement/API] Load Measurements Failure',
	props<{ error: any }>()
);

export const clearMeasurements = createAction(
	'[Measurement] Clear Measurements'
);

export const downloadMeasurements = createAction(
	'[Measurement] Download Measurements',
	props<{ extension: string; fileName: string; sensor: string; avg: string }>()
);

export const downloadAllMeasurements = createAction(
	'[Measurement] Download All Measurements',
	props<{ extension: string; fileName: string; avg: string }>()
);

export const downloadAllMeasurementsFailure = createAction(
	'[Measurement] Download All Measurements Failure',
	props<{ error: any }>()
);

export const changeMaxMeasurementsValue = createAction(
	'[Meausurements] Change Max Measurements Value',
	props<{ value: number }>()
);

export const updateSelectedDUIsToCompare = createAction(
	'[Measurements] Update Selected DUIs To Compare',
	props<{ duis: Array<any> }>()
);

export const loadMultipleMeasurements = createAction(
	'[Measurements] Load Multiple Measurements',
	props<{
		startDate: string;
		endDate: string;
		devices: Array<string>;
	}>()
);

export const loadMultipleMeasurementsSuccess = createAction(
	'[Measurements] Load Multiple Measurements Success',
	props<{ data: any }>()
);

export const loadMultipleMeasurementsFailure = createAction(
	'[Measurements] Load Multiple Measurements Failure',
	props<{ error: any }>()
);

// Wind Rose
export const loadWindRoseData = createAction(
	'[Measurement/API] Load Wind Rose Data'
);

export const loadWindRoseDataSuccess = createAction(
	'[Measurement/API] Load Wind Rose Data Success',
	props<{ data: any[] }>()
);

export const loadWindRoseDataFailure = createAction(
	'[Measurement/API] Load Wind Rose Data Failure',
	props<{ error: any }>()
);

export const changeCardinalDirections = createAction(
	'[Measurements] Change Cardinal Directions',
	props<{ directions: string[] }>()
);
